import React, { useState, useEffect } from "react";
import { isEqual } from "underscore";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Snackbar, IconButton, Slide, Drawer } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase/app";
import "firebase/database";
import "./App.css";
import firebaseConfig from "./firebase.config";
import StartTimes from "./StartTimes";
import Header from "./Header";
import LoginModal from "./LoginModal";
import HoursDaily from "./HoursDaily";
import HoursWeekly from "./HoursWeekly";
import Settings from "./Settings";
import NotFound from "./NotFound";
import Totals from "./Totals";
import User from "./User";
import UserList from "./UserList";
import Holidays from "./Holidays";
import PrivacyPolicy from "./PrivacyPolicy";
import CalendarPicker from "./CalendarPicker";
import Location from "./Location";
import MenuList from "./MenuList";
import RecentEdits from "./RecentEdits";
/* 
TODO: 
#user page styling
users permission
!! integrate users page fully into site & admin permissions
?hours weekly: use dropdown show details pane for totals and hide every days in/out times
?live updates on pages: update new Date() every 1 minute
* backlog data entry from excel
?? optional feature: hours daily- clock out and break In & Out MUST be after clockIN
  ?? hours daily/weekly hour calculations (isHoliday, Double time, or is6thDay, 1.5x Pay)
  ?? total hours overview page (dl all hours from firebase and do many calculations) * import all my hours before styling this page
  -hours worked this week (st/ot totals, avg daily)
  -hours worked this month (st/ot totals, avg weekly)
  -hours worked this year (st/ot totals, avg daily, avg weekly, avg monthly, compare with last year)
  -graphs with recharts https://recharts.org/en-US/
?? - maybe even suggested start times for addWeek based on last week or previous year
* - fonts, pick better font
* - react transitions
*/
function SlideTransition(props) {
  return <Slide {...props} direction="right" />;
}
firebase.initializeApp(firebaseConfig);
export const AuthContext = React.createContext(null);
const App = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("users")) || {});
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarColor, setSnackBarColor] = useState("warning");
  const [snackBarDuration, setSnackBarDuration] = useState(6000);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const size = useWindowSize();

  const activateSnackBar = (message = "", color = "warning", duration = 6000) => {
    /* colors: [default,alert,warning,info,success] */
    setSnackBarColor(color);
    setSnackBarMessage(message);
    setSnackBarDuration(duration);
    setSnackBarOpen(true);
    return true;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarMessage("");
    setSnackBarColor("warning");
    setSnackBarOpen(false);
  };
  useEffect(() => {
    // if localStorage userInfo doesn't match database userInfo then update localStorage to match database info
    firebase
      .database()
      .ref(`users/${user.uid}`)
      .on("value", function (snapshot) {
        var userInfo = (snapshot.val() && snapshot.val()) || [];
        if (userInfo && (!isEqual(userInfo.permissions, user.permissions) || userInfo.admin !== user.admin)) {
          console.log("Permissions changed");
          setUser(userInfo);
        } else if (userInfo && !isEqual(userInfo.appSettings, user?.appSettings)) {
          console.log("appSettings changed");
          setUser(userInfo);
        }
      });
    localStorage.setItem("users", JSON.stringify(user));
    console.log(`LocalStorage Updated`);
    return () => {
      firebase.database().ref(`users/${user.uid}`).off();
    };
  }, [user]);
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }
  function logInClicked() {
    // trigged when login button is clicked from header component => open modal for login methods to complete login process
    console.log("triggered");
    setIsLoggingIn(true);
  }
  function handleLogOut(e) {
    e.preventDefault();
    setUser({});
    firebase
      .auth()
      .signOut()
      .then(() => {
        /* TODO: react router stuff ::: history.push("/"); */
      })
      .catch((e) => {
        console.log(e.message);
      });
  }
  function toggleDrawer() {
    console.log("drawer toggled");
    setIsDrawerOpen(!isDrawerOpen);
  }
  return (
    <Router>
      <AuthContext.Provider value={{ user, setUser, activateSnackBar, size }}>
        <div className="App">
          <div className={isLoggingIn ? "content blur-active" : "content"}>
            <Header triggerLoginModal={logInClicked} isLoggingIn={isLoggingIn} toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
            <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
              <MenuList toggleDrawer={toggleDrawer} handleLogOut={handleLogOut} />
            </Drawer>
            <Switch>
              {/* AUTH.user.isNewUser => show account settings page to edit/enter info like payRate, start date, shift */}
              <Route path={["/twilight", "/t"]}>
                <StartTimes shift="t" />
              </Route>
              <Route path={["/midnight", "night", "/n"]}>
                <StartTimes shift="n" />
              </Route>
              <Route path={["/day", "/d"]}>
                <StartTimes shift="d" />
              </Route>
              <Route path={["/preload", "/p"]}>
                <StartTimes shift="p" />
              </Route>
              <Route path={"/picker"}>
                <CalendarPicker />
              </Route>
              <Route path={"/location"}>
                <Location />
              </Route>
              <Route path={["/hours/totals"]}>{user.uid ? <Totals /> : <NotFound message={`You must be logged in to use the Hour Tracker`} />}</Route>
              <Route path={["/hours/week"]}>{user.uid ? <HoursWeekly /> : <NotFound message={`You must be logged in to use the Hour Tracker`} />}</Route>
              <Route path={["/hours/day"]}>{user.uid ? <HoursDaily /> : <NotFound message={`You must be logged in to use the Hour Tracker`} />}</Route>
              <Route path={["/hours"]}>{user.uid ? <CalendarPicker /> : () => <NotFound message={`You must be logged in to use the Hour Tracker`} color="warning" route="/" />}</Route>
              <Route path={["/settings"]}>{user.uid ? <Settings handleLogOut={handleLogOut} /> : <NotFound message={`You must be logged in to access account settings`} />}</Route>
              <Route path={"/all"}>
                <StartTimes shift="all" />
              </Route>
              <Route path="/holidays">
                <Holidays />
              </Route>
              <Route path="/users">
                <UserList />
              </Route>
              <Route path="/user/:id">
                <User />
              </Route>
              <Route path="/edits">
                <RecentEdits />
              </Route>
              <Route path="/privacy-policy">{<PrivacyPolicy />}</Route>
              <Route path="/">
                {user?.appSettings?.shift ? <StartTimes shift={user?.appSettings?.shift} /> : size.width < size.height ? <StartTimes shift="t" /> : <StartTimes shift="all" />}
              </Route>
            </Switch>
          </div>
          <LoginModal isLoggingIn={isLoggingIn} loginComplete={() => setIsLoggingIn(false)} />
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            className={snackBarColor}
            open={snackBarOpen}
            autoHideDuration={snackBarDuration || 6000}
            onClose={handleClose}
            message={snackBarMessage}
            TransitionComponent={SlideTransition}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </div>
      </AuthContext.Provider>
    </Router>
  );
};

export default App;
