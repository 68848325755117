import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/database";
import { AuthContext } from "./App.js";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams, useLocation, useHistory } from "react-router-dom";
const MyButton = styled(Button)({
  background: "var(--ups-brown)",
  borderRadius: ".4rem",
  boxShadow: "/* inset 0 5px 10px var(--ups-gold), */ 0 2px 1px var(--ups-dark-brown)",
  color: "var(--ups-gold)",
  padding: ".5rem",
  transition: "all 0.4s ease-in-out",
  margin: ".5rem",
  "&:hover": {
    background: "var(--ups-dark-brown)",
    color: "var(--warning)",
    transform: "scale(1.2)",
  },
});
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  card: {
    backgroundColor: "var(--ups-gold)",
    borderRadius: ".35rem",
    color: "var(--ups-dark-brown)",
    margin: ".5rem",
    padding: ".5rem",
  },
  short: {
    marginTop: 0,
    marginBottom: ".5rem",
  },
  flexLine: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: ".5rem",
  },
  upDown: {
    background: "var(--ups-brown)",
    borderRadius: ".4rem",
    boxShadow: "/* inset 0 5px 10px var(--ups-gold), */ 0 2px 1px var(--ups-dark-brown)",
    color: "var(--ups-gold)",
    cursor: "pointer",
    transition: "all 0.5s ease-in-out",
    "&:active": {
      background: "var(--ups-dark-brown)",
      color: "var(--warning)",
      transform: "scale(1.2)",
    },
  },
}));
const MySpinner = styled(CircularProgress)({
  color: "#febe10",
});
const EmployeeInfo = (props) => {
  const { id } = useParams();

  const { pathname } = useLocation();
  const history = useHistory();
  const Auth = useContext(AuthContext);
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [fullTimeSupervisorInfo, setFullTimeSupervisorInfo] = useState({});
  const [mySupervisors, setMySupervisors] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [employeePhone, setEmployeePhone] = useState("");
  const [employeeArea, setEmployeeArea] = useState("");
  const [employeeShift, setEmployeeShift] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSup, setIsSup] = useState(false);
  const [userList, setUserList] = useState([]);
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const db = firebase.database();

  useEffect(() => {
    verifyUser();
    fetchEmployeeInfo();
    findMatchingUser(id);
    checkPath();
    return () => {
      if (id) {
        db.ref(`employees/${id}`).off();
        db.ref(`supervisors/${id}`).off();
      } else {
        db.ref(`employees/${props.eid}`).off();
        db.ref(`supervisors/${props.eid}`).off();
      }
      db.ref(`users/`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  function verifyUser() {
    if (!pathname.includes(Auth.user.eid) && !Auth.user.admin) {
      // if current user is not and admin or their employee id doesn't match the one they are trying to view
      // set error message
      Auth.setToastMessage({
        hidden: false,
        message: `You don't have the credentials to access that.`,
        color: "red",
      });
      // reroute user
      if (Auth.user.admin) {
        // user is admin, list all employees to select from
        history.replace("/employees");
      } else if (Auth.user.uid && Auth.user.eid) {
        // user is logged in and has linked an employee account
        history.replace(`/employee/${Auth.user.eid}`);
      } else {
        history.replace("/");
      }
    }
  }
  const spinner = (
    <div className="loading-spinner-container" aria-busy={true} aria-describedby="loading data">
      <MySpinner size="300px" />
    </div>
  );
  function checkPath() {
    if (pathname.includes("supervisor")) {
      setIsSup(true);
      return true;
    } else {
      setIsSup(false);
      return false;
    }
  }
  async function fetchEmployeeInfo() {
    if (!props.eid && checkPath()) {
      db.ref(`supervisors/${id}`)
        .once("value")
        .then(function (snapshot) {
          if (snapshot.val()) {
            const info = snapshot.val() && snapshot.val();
            setEmployeeInfo(info);
            setEmployeeName(info.name);
            setEmployeeArea(info.area);
            setEmployeeShift(info.shift);
            setIsLoading(false);
            info.phone && setEmployeePhone(info.phone);
            console.log(`fetched supervisor ${info.name}info from firebase`);
          } else {
            console.log(`no supervisor with id:${id} found`);
            history.replace("/404/no_access");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (!props.eid && pathname.includes("employee")) {
      db.ref(`employees/${id}`)
        .once("value")
        .then(function (snapshot) {
          if (snapshot.val()) {
            const info = snapshot.val() && snapshot.val();
            setEmployeeInfo(info);
            setEmployeeName(info.name);
            setEmployeeArea(info.area);
            setEmployeeShift(info.shift);
            getEmployeesSupervisorInfo(info.area);
            setIsLoading(false);
            info.phone && setEmployeePhone(info.phone);
            console.log(`fetched employee ${info.name}info from firebase`);
          } else {
            console.log(`no employee with id:${id}found`);
            history.replace("/404/no_access");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (props.eid) {
      db.ref(`employees/${props.eid}`)
        .once("value")
        .then(function (snapshot) {
          if (snapshot.val()) {
            const info = snapshot.val() && snapshot.val();
            setEmployeeInfo(info);
            setEmployeeName(info.name);
            setEmployeeArea(info.area);
            setEmployeeShift(info.shift);
            getEmployeesSupervisorInfo(info.area);
            setIsLoading(false);
            info.phone && setEmployeePhone(info.phone);
            console.log(`fetched employee ${info.name}info from firebase`);
          } else {
            console.log(`no employee with id:${props.eid}found`);
            history.replace("/404/no_access");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      history.push("/employees");
    }
  }
  async function findMatchingUser() {
    db.ref(`users/`)
      .once("value")
      .then(function (snapshot) {
        const names = Object.values(snapshot.val()).filter((user) => user.eid === id);
        setUserList(names);
        names.forEach((element) => {
          element.phoneNumber && !employeePhone && setEmployeePhone(element.phoneNumber);
        });
      })
      .catch((e) => {
        console.log(e);
      });
    console.log(`fetched all users info from firebase`);
  }
  function getEmployeesSupervisorInfo(myArea) {
    db.ref("supervisors/")
      .once("value")
      .then(function (snap) {
        const mySups = Object.values(snap.val()).filter((sup) => sup.area === myArea);
        setMySupervisors(mySups);
        const myFtSup = Object.values(snap.val()).filter((sup) => sup.area === "North");
        setFullTimeSupervisorInfo(myFtSup);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function validateName(name) {
    //TODO: reused from addemployee.js
    // lower/upper case letters & whitespace only
    if (/^[A-Za-z\s]+$/.test(name)) {
      const toTitleCase = (phrase) => {
        // capitalize first letter of every word in the inputted string
        return phrase
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };
      const removeExtraSpaces = (phrase) => {
        return phrase.replace(/\s+/g, " ");
      };
      setEmployeeName(toTitleCase(removeExtraSpaces(name)));
    } else {
      console.log("failed");
    }
  }
  function validatePhone(phone) {
    //TODO: better validation remove extra "-" & make sure length is correct
    setEmployeePhone(phone.replace(/\s+/g, " ").trim());
  }
  function changeEmployeeName() {
    if (isSup) {
      db.ref(`supervisors/${id}/name`).set(employeeName, function (e) {
        e && console.log(e);
      });
    } else {
      db.ref(`employees/${id}/name`).set(employeeName, function (e) {
        e && console.log(e);
      });
    }
  }
  function changeEmployeePhone() {
    if (isSup) {
      db.ref(`supervisors/${id}/phone`).set(employeePhone, function (e) {
        e && console.log(e);
      });
    } else {
      db.ref(`employees/${id}/phone`).set(employeePhone, function (e) {
        e && console.log(e);
      });
    }
  }
  function changeEmployeeArea(value) {
    setEmployeeArea(value);
    if (isSup) {
      db.ref(`supervisors/${id}/area`).set(value, function (e) {
        e && console.log(e);
      });
    } else {
      db.ref(`employees/${id}/area`).set(value, function (e) {
        e && console.log(e);
      });
    }
  }
  function changeEmployeeShift(value) {
    setEmployeeShift(value);
    if (isSup) {
      db.ref(`supervisors/${id}/shift`).set(value, function (e) {
        e && console.log(e);
      });
    } else {
      db.ref(`employees/${id}/shift`).set(value, function (e) {
        e && console.log(e);
      });
    }
  }

  function updateOffset(amount) {
    const newOffset = parseInt(amount) + parseInt(employeeInfo.offset);
    const info = { ...employeeInfo };
    info["offset"] = newOffset;
    setEmployeeInfo(info);
    db.ref(`employees/${id}/offset`).set(newOffset, function (e) {
      e && console.log(e);
    });
  }
  function unlinkAccount(uid) {
    db.ref(`users/${uid}/eid`).set("", function (e) {
      e && console.log(e);
    });
  }
  function gotoUsersPage(uid) {
    if (Auth.user.admin && Auth.user.permissions.editPermissions) {
      history.push(`/user/${uid}`);
    }
    //TODO: modal you don't have access to modify this employees online account
  }
  function phoneReadable(tel) {
    return `${tel.slice(0, 3)}-${tel.slice(3, 6)}-${tel.slice(6, 10)}`;
  }
  return (
    <section className={classes.card}>
      {isLoading ? (
        spinner
      ) : (
        <>
          {Auth.user.eid === id ? <h2 className={classes.short}>My Info:</h2> : <h2 className={classes.short}>{isSup ? "Supervisor Info" : "Employee Info"}</h2>}
          <div className="flex-vertical-container">
            <div className="flex-vert-sub">
              <div>
                {Auth.user.admin && Auth.user.permissions.editEmployees[employeeInfo.area] ? (
                  <div className={classes.flexLine} style={{ alignItems: "center" }}>
                    <TextField
                      style={{ width: "8rem" }}
                      type="text"
                      name="name"
                      label="Name:"
                      placeholder="First Last Name"
                      value={employeeName}
                      onChange={(e) => validateName(e.target.value)}
                    />
                    <MyButton style={{ padding: ".25rem" }} onClick={changeEmployeeName}>
                      Apply
                    </MyButton>
                  </div>
                ) : (
                  <span>
                    <strong>Name:</strong> {employeeInfo.name}
                  </span>
                )}
              </div>
              <div>
                {!employeeInfo.phone || (Auth.user.admin && Auth.user.permissions.editEmployees[employeeInfo.area]) ? (
                  <div className={classes.flexLine} style={{ alignItems: "center" }}>
                    <TextField
                      style={{ width: "8rem" }}
                      type="text"
                      name="phone"
                      label="Phone:"
                      placeholder="720-123-5555"
                      value={employeePhone}
                      onChange={(e) => validatePhone(e.target.value)}
                    />
                    <MyButton style={{ padding: ".25rem" }} onClick={changeEmployeePhone}>
                      Apply
                    </MyButton>
                  </div>
                ) : (
                  <span>
                    <strong>Phone:</strong> {employeePhone || "Not Provided"}
                  </span>
                )}
              </div>

              <div>
                {!employeeInfo.area.includes("North") && Auth.user.admin && Auth.user.permissions.editEmployees[employeeInfo.area] ? (
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="area-select-label">
                      Area:
                    </InputLabel>
                    <Select id="area-select" value={employeeArea} onChange={(e) => changeEmployeeArea(e.target.value)}>
                      <MenuItem value={"PD5"}>PD5</MenuItem>
                      <MenuItem value={"PD6"}>PD6</MenuItem>
                      <MenuItem value={"PD7"}>PD7</MenuItem>
                      <MenuItem value={"PD8"}>PD8</MenuItem>
                      <MenuItem value={"PD9"}>PD9</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <span>
                    <strong>Area:</strong> {employeeInfo.area}
                  </span>
                )}
                {Auth.user.admin &&
                  (Auth.user.permissions.editEmployees[employeeInfo.area] ? (
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel ref={inputLabel} id="shift-select-label">
                        Shift:
                      </InputLabel>
                      <Select id="shift-select" value={employeeShift} onChange={(e) => changeEmployeeShift(e.target.value)}>
                        <MenuItem value={"Day"}>Day</MenuItem>
                        <MenuItem value={"Twilight"}>Twilight</MenuItem>
                        <MenuItem value={"Midnight"}>Midnight</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <span>
                      <strong>Shift:</strong> {employeeInfo.shift}
                    </span>
                  ))}
              </div>
              {(pathname.includes("employee") || props.eid) && (
                <>
                  <div className={classes.flexLine}>
                    <span>
                      <strong>Start Time:</strong> {employeeInfo.offset} min
                    </span>
                    {Auth.user.admin && Auth.user.permissions.editEmployees[employeeInfo.area] && (
                      <>
                        <ArrowDropUpIcon
                          className={classes.upDown}
                          onClick={() => {
                            updateOffset(5);
                          }}
                        />
                        <ArrowDropDownIcon
                          className={classes.upDown}
                          onClick={() => {
                            updateOffset(-5);
                          }}
                        />
                      </>
                    )}
                  </div>
                  {mySupervisors.length > 0 && (
                    <div className="sup-info" /* onClick={() => history.push(`/supervisor/${mySupervisors[0].uid}`)} */>
                      <strong>Part-time Supervisor ({mySupervisors[0].area}):</strong>
                      <div className={classes.flexLine}>
                        <span>{mySupervisors[0].name}</span>
                        {mySupervisors[0].phone && (
                          <a href={`tel:${phoneReadable(mySupervisors[0].phone)}`}>
                            ({mySupervisors[0].phone.slice(0, 3)}-{mySupervisors[0].phone.slice(3, 6)}-{mySupervisors[0].phone.slice(6, 10)})
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                  {fullTimeSupervisorInfo.length > 0 && (
                    <div className="sup-info">
                      <strong>Full-time Supervisor ({fullTimeSupervisorInfo[0].area}):</strong>
                      <div className={classes.flexLine}>
                        <span>{fullTimeSupervisorInfo[0].name}</span>
                        {fullTimeSupervisorInfo[0].phone && (
                          <a href={`tel:${phoneReadable(fullTimeSupervisorInfo[0].phone)}`}>
                            ({fullTimeSupervisorInfo[0].phone.slice(0, 3)}-{fullTimeSupervisorInfo[0].phone.slice(3, 6)}-{fullTimeSupervisorInfo[0].phone.slice(6, 10)})
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {!props.minimalInterface && Auth.user.admin && userList.length > 0 && (
            <>
              <h3>Online Accounts Linked:</h3>
              {userList.map((user) => (
                <div key={user.uid} className="employee-info-user pointer">
                  {user.photoURL && <img src={user.photoURL} alt="profile pic" onClick={() => gotoUsersPage(user.uid)} />}
                  <div className="pointer" onClick={() => gotoUsersPage(user.uid)}>
                    {user.displayName}
                  </div>
                  <MyButton onClick={() => unlinkAccount(user.uid)}>unlink</MyButton>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </section>
  );
};
EmployeeInfo.propTypes = {
  minimalInterface: PropTypes.bool,
};
export default EmployeeInfo;
