import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase/app";
import "firebase/database";
import { AuthContext } from "./App.js";
import EmployeeInfo from "./EmployeeInfo";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Spinner from "./Spinner.js";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  userPage: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    backgroundColor: "var(--ups-gold)",
    borderRadius: ".35rem",
    color: "var(--ups-dark-brown)",
    margin: ".5rem",
    padding: ".5rem",
  },
  logo: {
    width: "1.5rem",
    height: "1.5rem",
  },
  FaceBookLogo: {
    width: "3rem",
    height: "3rem",
    margin: "-.75rem",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
  },
  shortP: {
    margin: 0,
  },
}));
const MyButton = styled(Button)({
  background: "var(--ups-brown)",
  borderRadius: ".4rem",
  boxShadow: "/* inset 0 5px 10px var(--ups-gold), */ 0 2px 1px var(--ups-dark-brown)",
  color: "var(--ups-gold)",
  padding: ".5rem",
  transition: "all 0.4s ease-in-out",
  margin: ".5rem",
  "&:hover": {
    background: "var(--ups-dark-brown)",
    color: "var(--warning)",
    transform: "scale(1.2)",
  },
});
const User = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [userInfo, setUserInfo] = useState({});
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [EidIsSet, setEidIsSet] = useState(false);
  const db = firebase.database();
  const Auth = useContext(AuthContext);
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    getUserInfo();
    verifyUser();
    return () => {
      db.ref(`users/${id}`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  function verifyUser() {
    if (!pathname.includes(Auth.user.uid) && !Auth.user.admin) {
      // if current user is not and admin or their user id doesn't match the one they are trying to view
      // set error message
      Auth.setToastMessage({
        hidden: false,
        message: `You don't have the credentials to access that.`,
        color: "red",
      });
      // reroute user
      if (Auth.user.uid && Auth.user.eid) {
        // user is logged in and has linked an employee account, then show their employee info page
        history.replace(`/employee/${Auth.user.eid}`);
      } else {
        history.replace("/");
      }
    }
  }
  function providerIcon(id) {
    if (id === "google.com") {
      return (
        <svg key={id} className={classes.logo} viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
            id="Shape1"
            fill="#EA4335"
          />
          <path
            d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
            id="Shape2"
            fill="#FBBC05"
          />
          <path
            d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
            id="Shape3"
            fill="#4285F4"
          />
          <path
            d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
            fill="#34A853"
          />
          <image src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" width="96" height="96" />
        </svg>
      );
    } else if (id === "facebook.com") {
      return (
        <svg key={id} x="0px" y="0px" viewBox="0 -7 16 30" className={classes.FaceBookLogo}>
          <path
            style={{ fill: "#3b5998" }}
            d="M12 3.303h-2.285c-0.27 0-0.572 0.355-0.572 0.831v1.65h2.857v2.352h-2.857v7.064h-2.698v-7.063h-2.446v-2.353h2.446v-1.384c0-1.985 1.378-3.6 3.269-3.6h2.286v2.503z"
          />
        </svg>
      );
    } else {
      return (
        <svg style={{ width: "30px" }} key={id} x="0px" y="0px" viewBox="0 0 512 512">
          <path style={{ fill: "#4CAF50" }} d="M272,240h240V16c0-8.832-7.168-16-16-16H272V240z" />
          <path style={{ fill: "#F44336" }} d="M240,240V0H16C7.168,0,0,7.168,0,16v224H240z" />
          <path style={{ fill: "#2196F3" }} d="M240,272H0v224c0,8.832,7.168,16,16,16h224V272z" />
          <path style={{ fill: "#FFC107" }} d="M272,272v240h224c8.832,0,16-7.168,16-16V272H272z" />
        </svg>
      );
    }
  }
  function dateReadable(date) {
    const myDate = new Date(date);
    // console.log(`${myDate.toDateString()} ${myDate.toLocaleTimeString()}`);
    return `${myDate.toDateString()} ${myDate.toLocaleTimeString()}`;
  }
  async function getUserInfo() {
    db.ref(`users/${id}`).on("value", function (snapshot) {
      console.log(`fetched user: ${id} from firebase`);
      const user = (snapshot.val() && snapshot.val()) || [];
      setUserInfo(user);
      if (user && user.eid) {
        db.ref(`employees/${user.eid}`)
          .once("value")
          .then(function (snapshot3) {
            console.log(`fetched info on employee ID# ${user.eid} from firebase`);
            if (snapshot3.val()) {
              setEmployeeInfo(snapshot3.val());
              setEidIsSet(true);
            } else {
              db.ref(`supervisors/${user.eid}`)
                .once("value")
                .then(function (snapshot2) {
                  console.log(`fetched info on supervisor ID# ${user.eid} from firebase`);
                  if (snapshot2.val()) {
                    setEmployeeInfo(snapshot2.val());
                    setIsSupervisor(true);
                    setEidIsSet(true);
                  } else {
                    closeMatch(user.displayName);
                  }
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        closeMatch(user.displayName);
      }
    });
  }
  async function closeMatch(name = userInfo.displayName || "") {
    db.ref(`employees/`)
      .once("value")
      .then(function (snapshot) {
        console.log(`fetched info matching employee name ${name} from firebase`);
        const result = snapshot.val() && snapshot.val();
        const filtered = Object.values(result).filter((employee) => employee.name.toLowerCase() === name.toLowerCase());
        if (filtered.length === 1) {
          setEmployeeInfo(filtered[0]);
        } else if (filtered.length > 1) {
          setEmployeeInfo(filtered);
        } else {
          db.ref(`supervisors/`)
            .once("value")
            .then(function (snapshot2) {
              console.log(`fetched info matching supervisor name ${name} from firebase`);
              setIsSupervisor(true);
              const result2 = snapshot2.val() && snapshot.val();
              const filtered2 = Object.values(result2).filter((employee) => employee.name.toLowerCase() === name.toLowerCase());
              if (filtered2.length === 1) {
                setEmployeeInfo(filtered2[0]);
              } else if (filtered2.length > 1) {
                setEmployeeInfo(filtered2);
              } else {
                setEmployeeInfo("");
              }
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function linkEID(employee) {
    if (Array.isArray(employeeInfo)) {
      setEmployeeInfo(employee);
    }
    db.ref(`users/${userInfo.uid}/eid/`).set(employee.uid, function (e) {
      e && console.log(e);
    });
    console.log(`employee id set for user ${userInfo.displayName}`);
  }
  return (
    <div className={classes.userPage}>
      {Object.keys(userInfo).length > 0 ? (
        <div>
          <section className={classes.card} style={{ marginBottom: ".5rem" }}>
            <h2 style={{ margin: ".5rem" }}>{userInfo.displayName}</h2>
            <div>Email: {userInfo.email || "N/A"}</div>
            <div>{userInfo.phoneNumber}</div>
            <div>Account Created: {new Date(userInfo.creationTime).toLocaleDateString()}</div>
            <div>Last: {dateReadable(userInfo.lastSignInTime)}</div>
            <div className={classes.center}>Supervisor: {userInfo.admin ? <CheckIcon style={{ color: "var(--success)" }} /> : <BlockIcon style={{ color: "var(--alert)" }} />}</div>
            <div className={classes.center}>
              Sign-in Methods:
              {userInfo.providers && userInfo.providers.map((x) => providerIcon(x.providerId))}
            </div>
          </section>
          {Array.isArray(employeeInfo) && (
            <section className={classes.card}>
              <div>No Employee is set to this account. Select an employee from below to link it</div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} id="shift-select-label">
                  Choose:
                </InputLabel>
                <Select id="shift-select" value={employeeInfo} onChange={(e) => linkEID(e.target.value)}>
                  {employeeInfo.map((employee) => (
                    <MenuItem key={employee.uid} value={employee}>
                      {`${employee.name} (${employee.area})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </section>
          )}
          {employeeInfo.uid && EidIsSet && <EmployeeInfo eid={employeeInfo.uid} sup={isSupervisor} minimalInterface={true} />}
          {Array.isArray(employeeInfo) && employeeInfo.uid && (
            <section className={classes.card}>
              <p className={classes.shortP}>This user account is not linked to a employee account!</p>
              <p className={classes.shortP}>The following {isSupervisor ? "supervisor" : "employee"} account matches this user.</p>
              <div>Name: {employeeInfo.name}</div>
              <div>Phone: {employeeInfo.phone}</div>
              <div>Area: {employeeInfo.area}</div>
              <div>Shift: {employeeInfo.shift}</div>
              {employeeInfo.phone && <div>phone: {employeeInfo.phone}</div>}
              <MyButton onClick={() => linkEID(employeeInfo)}>Link this {isSupervisor ? "supervisor" : "employee"}</MyButton>
            </section>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
export default User;
