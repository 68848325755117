import React, { useState, useContext } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { AuthContext } from "./App";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    borderRadius: ".6rem",
    boxShadow: theme.shadows[5],
    padding: "0 1rem .75rem",
    "background-color": "var(--ups-dark-brown)",
    "overflow-y": "auto",
    color: "var(--ups-gold)",
    display: "grid",
    "grid-template-rows": "4rem auto",
  },
  closeIcon: {
    color: "var(--alert)",
    paddingTop: "3px",
  },
  button: {
    background: "linear-gradient(45deg, #ffb500 30%, #644117 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    padding: "10px 10px",
    "margin-top": "20px",
  },
}));
//TODO: test login failure, popup, bad credentials, etc. => does the modal disappear
const LoginModal = (props) => {
  const Auth = useContext(AuthContext);
  const db = firebase.database();
  const [buttonsHidden, setButtonsHidden] = useState([false, false, false]);
  const [isLoading, setIsLoading] = useState([false, false, false]);
  const handleLogIn = (e, provider, param) => {
    e.preventDefault();
    let authProvider = null;
    if (provider === "O") {
      setIsLoading([false, false, true]);
      setButtonsHidden([true, true, false]);
      authProvider = new firebase.auth[`${provider}AuthProvider`](param);
    } else {
      authProvider = new firebase.auth[`${provider}AuthProvider`]();
      if (provider === "Google") {
        setIsLoading([true, false, false]);
        setButtonsHidden([false, true, true]);
      } else {
        setIsLoading([false, true, false]);
        setButtonsHidden([true, false, true]);
      }
    }
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(function () {
        return firebase
          .auth()
          .signInWithPopup(authProvider)
          .then((res) => {
            // check if user exists & get existing admin value from db / Otherwise use default admin value=false for new db entry
            db.ref(`users/${res.user.uid}`)
              .once("value")
              .then(function (snapshot) {
                const admin = (snapshot.val() && snapshot.val().admin) || false;
                const permissions = (snapshot.val() && snapshot.val().permissions) || {
                  editPermissions: false,
                  editEmployees: {
                    PD5: false,
                    PD6: false,
                    PD7: false,
                    PD8: false,
                    PD9: false,
                  },
                  editStartTimes: {
                    Day: false,
                    Twilight: false,
                    Midnight: false,
                    Preload: false,
                  },
                };
                const appSettings = (snapshot.val() && snapshot.val().appSettings) || {
                  ft: false,
                  payRate: 15.0,
                  shift: "t",
                  offset: 0,
                };
                const eid = (snapshot.val() && snapshot.val().eid) || "";
                if (eid !== "") {
                  // if existing user has already selected their employee name then get that info to load into localStorage
                  db.ref(`employees/${eid}`)
                    .once("value")
                    .then(function (snapshot2) {
                      setButtonsHidden([false, false, false]);
                      setIsLoading([false, false, false]);
                      const userSimple = {
                        displayName: res.user.displayName,
                        photoURL: res.user.photoURL,
                        uid: res.user.uid,
                        admin: admin,
                        permissions: permissions,
                        appSettings: appSettings,
                        eid: eid,
                        employeeName: (snapshot2.val() && snapshot2.val().name) || "",
                        employeeOffset: (snapshot2.val() && snapshot2.val().offset) || "",
                      };
                      Auth.setUser(userSimple);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                } else {
                  setButtonsHidden([false, false, false]);
                  setIsLoading([false, false, false]);
                  const userSimple = {
                    displayName: res.user.displayName,
                    photoURL: res.user.photoURL,
                    uid: res.user.uid,
                    admin: admin,
                    isNewUser: res.additionalUserInfo.isNewUser,
                    permissions: permissions,
                    appSettings: appSettings,
                    eid: eid,
                    employeeName: "",
                    employeeOffset: "",
                  };
                  Auth.setUser(userSimple);
                  // TODO: if new user -> welcome message/page
                }
                const user = {
                  displayName: res.user.displayName,
                  email: res.user.email,
                  phoneNumber: res.user.phoneNumber,
                  photoURL: res.user.photoURL,
                  uid: res.user.uid,
                  creationTime: res.user.metadata.creationTime,
                  lastSignInTime: res.user.metadata.lastSignInTime,
                  isNewUser: res.additionalUserInfo.isNewUser,
                  admin: admin,
                  permissions: permissions,
                  appSettings: appSettings,
                  eid: eid,
                  providers: res.user.providerData,
                };
                // load all user data into database (overWrite and update existing info OR add new instance of user)
                db.ref(`users/${user.uid}`).set(user, function (e) {
                  e && console.log(e);
                });
                props.loginComplete();
                // load some user data into state & localStorage
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            setButtonsHidden([false, false, false]);
            setIsLoading([false, false, false]);
            console.log(e.message);
            Auth.activateSnackBar(e.message, "alert");
          });
      })
      .catch(function (e) {
        setButtonsHidden([false, false, false]);
        setIsLoading([false, false, false]);
        Auth.activateSnackBar(e.message, "alert");
      });
  };
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.isLoggingIn}
      onClose={() => props.loginComplete()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.isLoggingIn} disableStrictModeCompat>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">
            <span>LOGIN</span>
            <CloseIcon fontSize="large" className={classes.closeIcon} onClick={props.loginComplete} />
          </h2>
          <div className="modal-content"></div>
          <div className="login-buttons">
            {!buttonsHidden[0] && (
              <button className="login-button" type="submit" onClick={(e) => handleLogIn(e, "Google")}>
                <div className="logo-container">
                  <svg className="google-logo" viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
                      id="Shape1"
                      fill="#EA4335"
                    />
                    <path
                      d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
                      id="Shape2"
                      fill="#FBBC05"
                    />
                    <path
                      d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
                      id="Shape3"
                      fill="#4285F4"
                    />
                    <path
                      d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
                      fill="#34A853"
                    />
                    <image src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" width="96" height="96" />
                  </svg>
                </div>
                {isLoading[0] ? (
                  <div>
                    Loading...
                    <LinearProgress />
                  </div>
                ) : (
                  <div className="login-text">Sign in with Google</div>
                )}
              </button>
            )}
            {!buttonsHidden[1] && (
              <button className="login-button" type="submit" onClick={(e) => handleLogIn(e, "Facebook")}>
                <div className="fb-logo">
                  <svg x="0px" y="0px" viewBox="0 -7 16 30">
                    <path
                      style={{ fill: "#3b5998" }}
                      d="M12 3.303h-2.285c-0.27 0-0.572 0.355-0.572 0.831v1.65h2.857v2.352h-2.857v7.064h-2.698v-7.063h-2.446v-2.353h2.446v-1.384c0-1.985 1.378-3.6 3.269-3.6h2.286v2.503z"
                    />
                  </svg>
                </div>
                {isLoading[1] ? (
                  <div>
                    Loading...
                    <LinearProgress />
                  </div>
                ) : (
                  <div className="login-text">Sign in with Facebook</div>
                )}
              </button>
            )}
            {!buttonsHidden[2] && (
              <button className="login-button" type="submit" onClick={(e) => handleLogIn(e, "O", "microsoft.com")}>
                <div className="logo-container">
                  <svg x="0px" y="0px" viewBox="0 0 512 512">
                    <path style={{ fill: "#4CAF50" }} d="M272,240h240V16c0-8.832-7.168-16-16-16H272V240z" />
                    <path style={{ fill: "#F44336" }} d="M240,240V0H16C7.168,0,0,7.168,0,16v224H240z" />
                    <path style={{ fill: "#2196F3" }} d="M240,272H0v224c0,8.832,7.168,16,16,16h224V272z" />
                    <path style={{ fill: "#FFC107" }} d="M272,272v240h224c8.832,0,16-7.168,16-16V272H272z" />
                  </svg>
                </div>
                {isLoading[2] ? (
                  <div>
                    Loading...
                    <LinearProgress />
                  </div>
                ) : (
                  <div className="login-text">Sign in with Microsoft</div>
                )}
              </button>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
export default LoginModal;
LoginModal.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  loginComplete: PropTypes.func.isRequired,
};
