import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
const Spinner = () => {
  return (
    <div className="flex-horizontal-container">
      <div className="spinner-container" aria-busy={true} aria-describedby="loading data">
        <CircularProgress />
        <div>
          <img src="logo512.png" alt="loading spinner" loading="lazy"/>
        </div>
      </div>
    </div>
  );
};
export default Spinner;
