import React, { useState, useEffect } from "react";
import MapViewer from "./MapViewer";

const Location = () => {
  const guardShackCoords = { lat: 39.78830983428153, lng: -104.91774391948361 };
  const [distance, setDistance] = useState({});
  const location = useLocation();
  function useLocation() {
    const [position, setPosition] = useState({});
    const [error, setError] = useState(null);
    const OnChange = ({ coords }) => {
      setPosition({ lat: coords.latitude, lng: coords.longitude });
    };
    const OnError = (error) => {
      setError(error.message);
    };
    useEffect(() => {
      if (!navigator.geolocation) {
        setError("Geolocation not supported");
        return;
      }
      const watcher = navigator.geolocation.watchPosition(OnChange, OnError);
      return () => navigator.geolocation.clearWatch(watcher);
    }, []);
    return { ...position, error };
  }
  return (
    <>
      {location.error === null ? (
        <>
          {distance && (
            <div className="map-distance-overlay">
              <div>Distance: {distance?.distance?.text}</div>
              <div>Duration: {distance?.duration_in_traffic?.text}</div>
            </div>
          )}
          <MapViewer setDistance={setDistance} coords={location} guardShackCoords={guardShackCoords} />
        </>
      ) : (
        <div>{location.error}</div>
      )}
    </>
  );
};
export default Location;
