import React from "react";
import "./Hamburger.css";
const Hamburger = (props) => {
  function handleHamburgerClick() {
    props.toggleDrawer();
  }
  return (
    <svg
      id="hamburger"
      tabIndex={0}
      aria-label="open/close menu button"
      className={props.isDrawerOpen ? "drawer-open" : "drawer-close"}
      viewBox="0 0 60 40"
      onClick={handleHamburgerClick}
      onKeyDown={handleHamburgerClick}
    >
      <g className={props.isDrawerOpen ? "drawer-open" : "drawer-close"} stroke="var(--ups-gold)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round">
        <path id="top-line" d="M10,10 L50,10 Z"></path>
        <path id="middle-line" d="M10,20 L50,20 Z"></path>
        <path id="bottom-line" d="M10,30 L50,30 Z"></path>
      </g>
    </svg>
  );
};

export default Hamburger;
