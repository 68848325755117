import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Input, TextField, Button, FormControlLabel, MenuItem, FormControl, Select, InputLabel, Checkbox, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import { AccountBox, ExpandLess, ExpandMore, Search } from "@material-ui/icons";
import { AuthContext } from "./App.js";
import firebase from "firebase/app";
import "firebase/database";
import Spinner from "./Spinner.js";
import { isAfter } from "date-fns";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: ".5rem",
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  users: {
    position: "relative",
  },
  usersList: {
    listStyleType: "none",
    display: "grid",
    gridAutoRows: "min-content",
    gap: ".74rem",
    padding: "0 .74rem",
    margin: ".74rem 0",
    overflowY: "auto",
    height: `calc(100vh - 12.5rem)`,
    borderRadius: ".35rem",
    scrollbarWidth: "thin",
    scrollbarColor: "var(--ups-gold) var(--ups-dark-brown)",
  },
  usersListItem: {
    backgroundColor: "var(--ups-gold)",
    borderRadius: ".35rem",
    color: "var(--ups-dark-brown)",
    padding: ".5rem",
  },
  usersListName: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  userPermissions: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  },
  photo: {
    height: "4rem",
    width: "4rem",
  },
  img: {
    width: "100%",
    borderRadius: ".7rem",
  },
  accountBox: {
    fontSize: "5rem",
    marginLeft: "-.74rem",
    marginTop: "-.74rem",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));
const MyButton = styled(Button)({
  background: "var(--ups-brown)",
  border: 0,
  borderRadius: 3,
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  color: "var(--ups-gold)",
  padding: ".25rem .5rem",
  marginLeft: ".5rem",
  marginTop: ".5rem",
  "&:hover": {
    background: "var(--ups-dark-brown)",
  },
});
const areas = ["All", "PD5", "PD6", "PD7", "PD8", "PD9", "None"];
const shifts = ["All", "Day", "Twilight", "Midnight", "Preload", "None"];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
const UserList = () => {
  const history = useHistory();
  const Auth = useContext(AuthContext);
  const db = firebase.database();
  const [users, setUsers] = useState({});
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [sortMethod, setSortMethod] = useState(3);
  const [searchField, setSearchField] = useState("");
  const [showSortBy, setShowSortBy] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  useEffect(() => {
    fetchUsers();
    checkMyPermission();
    verifyUser();
    setPageScrollable();
    return () => {
      db.ref(`users/`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function setPageScrollable() {
    const root = document.querySelector("html");
    // set root of page to be able to scroll
    root.style.overflowY = `auto`;
  }
  const changePermissions = (event, id, type) => {
    const oldUsers = { ...users };
    const myPermissions = [...Object.values(oldUsers[id].permissions[type])];
    if (event.target.value.includes("All")) {
      Object.entries(oldUsers[id].permissions[type]).forEach((element) => {
        oldUsers[id].permissions[type][element[0]] = !myPermissions.every((item) => item);
      });
      setUsers(oldUsers);
    } else if (event.target.value.includes("None") && myPermissions.some((item) => item)) {
      Object.entries(oldUsers[id].permissions[type]).forEach((element) => {
        oldUsers[id].permissions[type][element[0]] = false;
      });
      setUsers(oldUsers);
    } else {
      Object.entries(oldUsers[id].permissions[type]).forEach((element) => {
        oldUsers[id].permissions[type][element[0]] = event.target.value.includes(element[0]);
      });
      setUsers(oldUsers);
    }
    db.ref(`users/${id}/permissions/${type}/`).set(oldUsers[id].permissions[type], function (e) {
      e && console.log(e);
    });
    if (
      oldUsers[id].permissions.editPermissions ||
      Object.values(oldUsers[id].permissions.editStartTimes).some((item) => item) ||
      Object.values(oldUsers[id].permissions.editEmployees).some((item) => item)
    ) {
      db.ref(`users/${id}/admin/`).set(true, function (e) {
        e && console.log(e);
      });
    } else if (Object.values(oldUsers[id].permissions.editStartTimes).every((item) => !item) && Object.values(oldUsers[id].permissions.editEmployees).every((item) => !item)) {
      db.ref(`users/${id}/admin/`).set(false, function (e) {
        e && console.log(e);
      });
    }
  };
  async function fetchUsers() {
    // get json object of all employees from firebase, sort, save employees state
    db.ref(`users/`).on("value", function (snapshot) {
      var fbUsers = (snapshot.val() && snapshot.val()) || [];
      setUsers(fbUsers);
      setFilteredUsers(sortByRecentlyAdded(fbUsers));
    });
    console.log("fetched users from firebase");
  }
  function sortByLastName(usersList) {
    const sorted = Object.values(usersList).sort((a, b) => {
      const [, aLast] = a.displayName.split(" ");
      const [, bLast] = b.displayName.split(" ");
      return aLast > bLast ? 1 : -1;
    });
    //console.log(sorted);
    return sorted;
  }
  function sortByFirstName(usersList) {
    const sorted = Object.values(usersList).sort((a, b) => {
      const [aFirst] = a.displayName.split(" ");
      const [bFirst] = b.displayName.split(" ");
      return aFirst > bFirst ? 1 : -1;
    });
    //console.log(sorted);
    return sorted;
  }
  function sortByRecentlyAdded(usersList) {
    const sorted = Object.values(usersList).sort((a, b) => {
      const aDate = a.creationTime.substring(5, a.creationTime.length - 4);
      const bDate = b.creationTime.substring(5, a.creationTime.length - 4);
      return isAfter(new Date(bDate), new Date(aDate)) ? 1 : -1;
    });
    //console.log(sorted);
    return sorted;
  }
  function gotoUserPage(uid) {
    if (Auth.user.admin) {
      history.push(`/user/${uid}`);
    }
  }
  function togglePermissionsEditable(id) {
    const oldUsers = { ...users };
    oldUsers[id].permissions.editPermissions = !oldUsers[id].permissions.editPermissions;
    setUsers(oldUsers);
    db.ref(`users/${id}/permissions/editPermissions/`).set(oldUsers[id].permissions.editPermissions, function (e) {
      e && console.log(e);
    });
    if (oldUsers[id].permissions.editPermissions) {
      db.ref(`users/${id}/admin/`).set(true, function (e) {
        e && console.log(e);
      });
    } else if (Object.values(oldUsers[id].permissions.editStartTimes).every((item) => !item) && Object.values(oldUsers[id].permissions.editEmployees).every((item) => !item)) {
      db.ref(`users/${id}/admin/`).set(false, function (e) {
        e && console.log(e);
      });
    }
    // TODO: fetchUsers()?
  }
  function getPermissions(id, type) {
    const areasArray = [];
    Object.entries(users[id].permissions[type]).forEach((element) => {
      element[1] && areasArray.push(element[0]);
    });
    if (areasArray.length < 1) {
      areasArray.push("None");
    }
    return areasArray;
  }
  function checkMyPermission() {
    if (Auth.user.uid) {
      db.ref(`users/${Auth.user.uid}/permissions/editPermissions`)
        .once("value")
        .then(function (snapshot) {
          const res = (snapshot.val() && snapshot.val()) || [];
          if (Auth.user.permissions.editPermissions && res) {
            setDisabled(false);
            return true;
          } else {
            setDisabled(true);
            return false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setDisabled(true);
      return false;
    }
  }
  function showModal() {
    console.log(`opening modal`);
    const title = "Denied!";
    const content = `<p>You don't have access to make changes here.</p><p>Try clicking on the users name to gain access.</p><p></p>`;
    Auth.openModal(true, title, content);
  }
  function promote(uid) {
    db.ref(`users/${uid}/admin/`).set(true, function (e) {
      e && console.log(e);
    });
  }
  function verifyUser() {
    if (!Auth.user.admin) {
      // if current user is not and admin
      // set error message
      Auth.setToastMessage({
        hidden: false,
        message: `You don't have the credentials to access that.`,
        color: "red",
      });
      // reroute user
      if (Auth.user.uid) {
        // user is logged in then show their user info page
        history.replace(`/user/${Auth.user.uid}`);
      } else {
        history.replace("/");
      }
    }
  }
  function toggleSortMethod(e, type) {
    e.stopPropagation();
    if (type === 3) {
      setFilteredUsers(sortByRecentlyAdded(users));
    } else if (type === 1) {
      setFilteredUsers(sortByFirstName(users));
    } else if (type === 2) {
      setFilteredUsers(sortByLastName(users));
    }
    if (type !== sortMethod) {
      setShowSortBy(false);
    }
    setSortMethod(type);
  }
  function filter(event, phrase = "") {
    event.stopPropagation();
    setSearchField(phrase);
    const filtered = Object.values(users).filter((x) => x.displayName.toLowerCase().includes(phrase.toLowerCase()));
    setFilteredUsers(filtered);
  }
  function toggleShowDetails(e, key) {
    e.stopPropagation();
    if (!showDetails) {
      setShowDetails(key);
      setShowSortBy(false);
    } else {
      setShowDetails(false);
    }
  }
  function toggleShowSortBy() {
    setShowSortBy(!showSortBy);
  }
  return (
    <>
      {Object.keys(users).length > 0 ? (
        <div className={classes.users}>
          <Accordion className={classes.usersListItem} style={{ margin: ".74rem .74rem 0 .74rem", padding: 0 }} expanded={showSortBy}>
            <AccordionSummary expandIcon={<ExpandMore />} onClick={() => toggleShowSortBy()}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <TextField
                  id="standard-search"
                  label={
                    <div style={{ display: "flex", alignItems: "center", fontSize: "1.25rem" }}>
                      <Search style={{ fontsize: "1.5rem" }} />
                      <span style={{ marginLeft: ".5rem" }}>Search...</span>
                    </div>
                  }
                  value={searchField}
                  onChange={(e) => filter(e, e.target.value)}
                  onFocus={(event) => event.stopPropagation()}
                  onTouchStart={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  type="search"
                  variant="outlined"
                  style={{}}
                />
                <span>Sort By</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <FormControlLabel label="First Name" labelPlacement="top" control={<Checkbox checked={sortMethod === 1} onClick={(e) => toggleSortMethod(e, 1)} />} />
              <FormControlLabel label="Last Name" labelPlacement="top" control={<Checkbox checked={sortMethod === 2} onClick={(e) => toggleSortMethod(e, 2)} />} />
              <FormControlLabel label="Recently Added" labelPlacement="top" control={<Checkbox checked={sortMethod === 3} onClick={(e) => toggleSortMethod(e, 3)} />} />
            </AccordionDetails>
          </Accordion>
          <ul className={classes.usersList}>
            {filteredUsers.length > 0 &&
              Object.keys(filteredUsers).map((key) => (
                <li key={key} className={classes.usersListItem}>
                  <div className={classes.usersListName}>
                    <div className={classes.photo} style={{ cursor: "pointer" }} onClick={() => gotoUserPage(filteredUsers[key].uid)}>
                      {filteredUsers[key].photoURL ? <img src={filteredUsers[key].photoURL} alt="profile" className={classes.img} /> : <AccountBox className={classes.accountBox} />}
                    </div>
                    <div className={classes.flexCol} style={{ userSelect: "none" }} onClick={(event) => toggleShowDetails(event, key)}>
                      <span style={{ cursor: "pointer" }} onClick={() => gotoUserPage(filteredUsers[key].uid)}>
                        {filteredUsers[key].displayName}
                      </span>
                      {!filteredUsers[key].admin ? <span>No Permissions</span> : <span>Supervisor</span>}
                      {showDetails === key ? (
                        <ExpandLess style={{ cursor: "pointer" }} onClick={(event) => toggleShowDetails(event, 0)} />
                      ) : (
                        <ExpandMore style={{ cursor: "pointer" }} onClick={(event) => toggleShowDetails(event, key)} />
                      )}
                    </div>
                    {/* <span class={classes.overflowEllipsis}>({users[key].email})</span> */}
                  </div>
                  {showDetails === key &&
                    (filteredUsers[key].admin ? (
                      <div className={classes.userPermissions}>
                        <FormControl className={classes.formControl + " start-time-permissions"} disabled={disabled} /* onClick={disabled ? showModal : undefined} */>
                          <InputLabel id="demo-multiple-name-label">Allow Edit Start Times For:</InputLabel>
                          <Select
                            id="edit-shift-permissions"
                            multiple
                            value={getPermissions(filteredUsers[key].uid, "editStartTimes")}
                            /* onChange={(e) => changePermissions(e, users[key].uid, "editStartTimes")} */
                            input={<Input />}
                            MenuProps={MenuProps}
                          >
                            {shifts.map((name) => (
                              <MenuItem key={name} value={name} style={getStyles(name, shifts, theme)}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* <FormControl className={classes.formControl} disabled={disabled} onClick={disabled ? showModal : undefined}>
                      <InputLabel id="demo-multiple-name-label2">Edit Employees</InputLabel>
                      <Select
                        id="edit-areas-permissions"
                        multiple
                        value={getPermissions(users[key].uid, "editEmployees")}
                        onChange={(e) => changePermissions(e, users[key].uid, "editEmployees")}
                        input={<Input />}
                        MenuProps={MenuProps}
                      >
                        {areas.map((name) => (
                          <MenuItem key={name} value={name} style={getStyles(name, areas, theme)}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                        <FormControlLabel
                          value="top"
                          control={
                            <Checkbox
                              checked={filteredUsers[key].permissions.editPermissions}
                              onChange={() => togglePermissionsEditable(filteredUsers[key].uid)}
                              value={true}
                              inputProps={{
                                "aria-label": "toggle edit permissions",
                              }}
                              disabled={disabled}
                              onClick={disabled ? showModal : undefined}
                            />
                            /* TODO: show modal needs to be updated  */
                          }
                          label="Edit Other Users Permissions:"
                          labelPlacement="start"
                        />
                      </div>
                    ) : (
                      <MyButton onClick={() => promote(filteredUsers[key].uid)}>Promote</MyButton>
                    ))}
                </li>
              ))}
          </ul>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};
export default UserList;
