import React, { useEffect } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

export const MapViewer = (props) => {
  const mapStyles = { width: "100%", height: "Calc(100% - var(--header-height))" };
  const { google } = props;
  const service = new google.maps.DistanceMatrixService();
  function getDistance() {
    const now = new Date();
    service.getDistanceMatrix(
      {
        origins: [props.coords],
        destinations: [props.guardShackCoords],
        travelMode: "DRIVING",
        drivingOptions: {
          departureTime: now, // for the time N milliseconds from now.
          trafficModel: "bestguess",
        },
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      },
      (response, status) => {
        console.log("response", response);
        console.log(response.rows[0].elements[0].distance.text); // {text,value}
        props.setDistance(response.rows[0].elements[0]); // {text,value}
        console.log(response.rows[0].elements[0].duration.text);
        console.log(response.rows[0].elements[0].duration_in_traffic.text);
        //setDuration(response.rows[0].elements[0].duration_in_traffic.text);
        console.log("status", status);
      }
    );
  }
  useEffect(() => {
    console.log("distance method called..");
    getDistance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Map google={props.google} style={mapStyles} zoom={10} initialCenter={props.guardShackCoords}>
      <Marker position={props.coords} />
      <Marker position={props.guardShackCoords} />
      <InfoWindow marker={props.guardShackCoords} visible={false}>
        <div>
          <h1>UPS Guard Shack</h1>
        </div>
      </InfoWindow>
    </Map>
  );
};
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_gMap_key,
})(MapViewer);
