import React, { useContext, useState, useEffect } from "react";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { ArrowBack, Close, Undo, VisibilityOff } from "@material-ui/icons";
import { Button, Fade, Modal, Backdrop } from "@material-ui/core";
import { endOfWeek } from "date-fns/esm";
import { getDay, lightFormat } from "date-fns";
import { AuthContext } from "./App";

function RecentEdits() {
  const db = firebase.database();
  const history = useHistory();
  const Auth = useContext(AuthContext);
  const [editItems, setEditItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  useEffect(() => {
    fetchEdits();
    return () => {
      db.ref(`edits/`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function fetchEdits() {
    // get json object of all employees from firebase, sort, save employees state
    db.ref(`edits/`).on("value", function (snapshot) {
      const result = snapshot?.val() || {};
      const arr = Object.keys(result).map((key) => {
        return { date: new Date(key).getTime(), ...result[key] };
      });
      setEditItems(arr.sort((a, b) => b.date - a.date).filter((item) => !item?.hidden));
      console.log("fetched users from firebase");
    });
  }
  function goToUserPage(uid) {
    history.push(`/user/${uid}`);
  }
  function shiftLetterToString(ltr) {
    if (ltr === "d") {
      return "Day";
    } else if (ltr === "t") {
      return "Twilight";
    } else if (ltr === "n") {
      return "Night";
    } else if (ltr === "p") {
      return "Preload";
    }
  }
  function revert(index) {
    if (!Auth.user.admin) {
      // if user isn't an admin don't perform action, alert them and take them back home
      Auth.activateSnackBar("You don't have permission to perform this action", "alert", 2000);
      history.replace("/");
    }
    //todo: undo the action specified in the state array for the given index...
    // show a modal to confirm....
    // make the change
    console.log(editItems[index].type);
    // calculate out the day of the week index and the weekending date in format of "yyyy-MM-dd"
    let date;
    if (editItems[index]?.day) {
      date = new Date(`${editItems[index].day} 12:00`);
    } else {
      date = new Date(editItems[index].prevValue);
    }
    const we = lightFormat(endOfWeek(date), "yyyy-MM-dd");
    // calculate the day of the week index, 0 being sunday, 6 being saturday
    const weekIndex = getDay(date);
    if (editItems[index].type === "Edit StartTime" || editItems[index].type === "Undo Shift") {
      console.log("making the change");
      // restore the previous "edit start time" action or the previous "undo scheduled shift" action to the previously scheduled times.
      // create time string in format of 5:30 PM or 10:30 AM
      const time = editItems[index].prevValue
        .slice(-11, -6)
        .trim()
        .concat(" " + editItems[index].prevValue.substr(-2, 2));
      // make the change in the database
      db.ref(`weeks/${we}/${editItems[index].shift}/${weekIndex}/time`).set(time, function (e) {
        e && console.log(e);
      });
      // alert user that change has been made
      Auth.activateSnackBar(`${shiftLetterToString(editItems[index].shift)} Start Time changed to ${time} for ${editItems[index].prevValue.split(",")[0]}`, "success", 2000);
    } else if (editItems[index].type === "Cancel Scheduled Shift") {
      // undo the "canceled shift" action aka snow day and restore to the previous "OFF state"
      db.ref(`weeks/${we}/${editItems[index].shift}/${weekIndex}/time`).set("OFF", function (e) {
        e && console.log(e);
      });
      // alert user that change has been made
      Auth.activateSnackBar(`${shiftLetterToString(editItems[index].shift)} sort for ${date.toLocaleString().split(",")[0]} changed to Unscheduled!`, "success", 2000);
      console.log("revert off2");
    }
    // get time now string to log into db
    const timeNow = new Date();
    const nowStr = timeNow.toString().split("(")[0];
    // log record of change in database
    db.ref(`edits/${nowStr}`).set(
      {
        prevValue: editItems[index].newValue,
        newValue: editItems[index].prevValue,
        shift: editItems[index].shift,
        type: "Revert Back",
        userInfo: { displayName: Auth?.user?.displayName, uid: Auth?.user?.uid },
      },
      function (e) {
        e && Auth.activateSnackBar(e, "alert", 2000);
      }
    );
    // update previous log entry to show that it has been reverted, this will in turn update the view to not allow it to be reverted multiple times.
    const prevLogDate = new Date(editItems[index].date).toString().split("(")[0];
    db.ref(`edits/${prevLogDate}/reverted`).set(true, function (e) {
      e && Auth.activateSnackBar(e, "alert", 2000);
    });
  }
  function remove(index) {
    if (Auth.user.admin) {
      setIsModalOpen(true);
      setModalItem(index);
    }
  }
  function handleModalClose() {
    setIsModalOpen(false);
    setModalItem(null);
  }
  function HideItemFromEdits() {
    if (modalItem !== null) {
      console.log(editItems[modalItem]);
      const prevLogDate = new Date(editItems[modalItem].date).toString().split("(")[0];
      db.ref(`edits/${prevLogDate}/hidden`)
        .set(true)
        .then(function () {
          Auth.activateSnackBar(`${shiftLetterToString(editItems[modalItem].shift)} start time of ${editItems[modalItem].prevValue} removed.`, "success", 2000);
        })
        .catch(function (e) {
          e && console.log(e);
        });
      setModalItem(null);
      setIsModalOpen(false);
    } else {
      console.log("not found");
    }
  }
  return (
    <ul className={isModalOpen ? "list noScroll" : "list"}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal-position"
        open={isModalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <div className="modal-box">
            <Button className="standard-button small circle absolute-top-right" onClick={handleModalClose}>
              <Close />
            </Button>
            <h2 id="transition-modal-title">Are You Sure?</h2>
            <ul>
              <li id="transition-modal-description">This Action Can NOT be undone and will NOT remove the change!</li>
              <li>This will Hide the previous time from all users including yourself.</li>
              <li className="sub-text">ie. Click "Hide" if you have put in the wrong time by mistake and have already changed it.</li>
              <li className="sub-text">
                <span>ie. To Undo the change, go back and click the revert button </span>
                <span>
                  <Button className="standard-button small">
                    <Undo />
                  </Button>
                </span>
              </li>
            </ul>
            <div className="btn-row">
              <Button className="standard-button" style={{ color: "var(--ups-gold)" }} onClick={handleModalClose}>
                <ArrowBack />
                NO, Go Back
              </Button>
              <Button className="standard-button red" onClick={HideItemFromEdits}>
                <VisibilityOff /> <span>Hide</span>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      {editItems.length > 0 &&
        editItems.map((item, index) => (
          <li key={item.date} className="card">
            <h1 className="card-title">{new Date(item.date).toDateString()}</h1>
            <span>{item?.type}</span>
            <span>
              {shiftLetterToString(item?.shift)} {item?.day && new Date(`${item.day} 12:00`).toLocaleString().split(",")[0]}
            </span>
            <span className="prevValue">
              {item?.prevValue === "OFF" ? "Scheduled OFF" : item?.prevValue === "OFF2" ? "Shift Canceled" : item?.prevValue.slice(0, -6).concat(item?.prevValue.substr(-2, 2))}
            </span>
            <span className="newValue">
              {item?.newValue === "OFF" ? "Scheduled OFF" : item?.newValue === "OFF2" ? "Shift Canceled" : item?.newValue.slice(0, -6).concat(item?.newValue.substr(-2, 2))}
            </span>
            <span className="card-userName" onClick={() => goToUserPage(item?.userInfo?.uid)}>
              By: {item?.userInfo?.displayName}
            </span>
            <div className="abs-bottom-right">
              <Button className="standard-button small red" onClick={() => remove(index)}>
                <VisibilityOff />
              </Button>
              {item?.type !== "Revert Back" && item?.reverted !== true && (
                <Button className="standard-button small" onClick={() => revert(index)}>
                  <Undo />
                </Button>
              )}
            </div>
          </li>
        ))}
    </ul>
  );
}

export default RecentEdits;
