import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { nextSaturday, isAfter, isFriday, isMonday, isSaturday, isThursday, lightFormat, setMonth } from "date-fns";
import { makeStyles, styled } from "@material-ui/core/styles";
import { FormControl, Select, InputLabel, MenuItem, Button, Modal, Backdrop, Fade, FormHelperText, Checkbox } from "@material-ui/core";
import { AddCircle, ArrowBack, ArrowBackIos, ArrowForwardIos, CheckBoxOutlineBlank, DeleteForever, Undo } from "@material-ui/icons";
import { AuthContext } from "./App.js";
import Spinner from "./Spinner";
import firebase from "firebase/app";
import "firebase/database";
import GModal from "./GModal";

//TODO: delete pto days and vacation weeks button is broken!!
const MyArrowButtonRight = styled(Button)({
  background: "var(--ups-gold)",
  border: 0,
  borderRadius: ".4rem",
  color: "white",
  boxShadow: "5px 0 rgb(40, 21, 16,.7)",
  minWidth: "1.5rem",
  width: "1.5rem",
  "&:hover": {
    background: "var(--ups-yellow)",
  },
  "&:active": {
    background: "var(--ups-yellow)",
    boxShadow: "1px 0 rgb(40, 21, 16,.7)",
    transform: "translateX(5px)",
  },
});
const MyArrowButtonLeft = styled(Button)({
  background: "var(--ups-gold)",
  border: 0,
  borderRadius: ".4rem",
  boxShadow: "-5px 0 rgb(40, 21, 16)",
  color: "white",
  minWidth: "1.5rem",
  width: "1.5rem",
  "&:hover": {
    background: "var(--ups-yellow)",
  },
  "&:active": {
    background: "var(--ups-yellow)",
    boxShadow: "-1px 0 rgb(40, 21, 16)",
    transform: "translateX(-5px)",
  },
});
const MyButton = styled(Button)({
  background: "var(--ups-brown)",
  border: 0,
  borderRadius: ".4rem",
  boxShadow: "0 3px 5px 2px rgba(100,65,23, .3)",
  color: "var(--ups-gold)",
  width: "max-content",
  "&:hover": {
    background: "var(--ups-dark-brown)",
    boxShadow: "0 3px 5px 4px rgba(100,65,23, .7)",
    transform: "scale(1.15)",
  },
});
const useStyles = makeStyles((theme) => ({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "calc(100% - 3rem)",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "12rem",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  card: {
    background: "var(--warning)",
    listStyleType: "none",
    display: "flex",
    flexDirection: "column",
    padding: ".5rem 0",
    borderRadius: ".5rem",
    color: "var(--ups-dark-brown)",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  noCard: {
    background: "var(--ups-gold)",
    listStyleType: "none",
    display: "flex",
    flexDirection: "column",
    padding: ".5rem",
    borderRadius: ".5rem",
    color: "var(--ups-dark-brown)",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  past: {
    opacity: "70%",
    textDecoration: "line-through",
    filter: "blur(1px)",
  },
  ptoAndVacation: {
    background: "var(--ups-yellow)",
  },
  list: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(8.5rem, 1fr))",
    padding: 0,
    gap: ".5rem",
    margin: "0 .5rem",
    width: "calc(100% - 1rem)",
    overflow: "auto",
  },
  noList: {
    display: "grid",
    justifyContent: "center",
  },
  h1: {
    margin: ".25rem 0",
    fontSize: "1.75rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  h2: {
    margin: "0 0 .5rem 0",
  },
  form: {
    background: "var(--ups-gold)",
    display: "flex",
    flexDirection: "column",
    padding: ".5rem",
    margin: "0 .5rem",
    borderRadius: ".5rem",
    color: "var(--ups-dark-brown)",
    justifyContent: "center",
    alignItems: "center",
    width: "max-content",
    position: "relative",
  },
  paper: {
    backgroundColor: "var(--ups-gold)",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.4)",
    padding: ".75rem",
    borderRadius: ".4rem",
    color: "var(--ups-dark-brown)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexRowAround: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "center",
    width: "100%",
  },
  absoluteTR: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: ".25rem .25rem 0 .25rem",
    background: "var(--ups-brown)",
    color: "var(--ups-gold)",
    borderBottomLeftRadius: ".4rem",
    borderTopRightRadius: ".4rem",
    cursor: "pointer",
    "&:hover": {
      background: "var(--ups-dark-brown)",
      boxShadow: "0 3px 5px 4px rgba(100,65,23, .7)",
      transform: "scale(1.15) translate(-7%,7%)",
    },
  },
  absoluteBR: {
    position: "absolute",
    right: 0,
    bottom: 0,
    padding: 0,
    display: "flex",
    background: "var(--ups-brown)",
    color: "var(--ups-gold)",
    borderBottomRightRadius: ".4rem",
    borderTopLeftRadius: ".4rem",
    cursor: "pointer",
    "&:hover": {
      background: "var(--ups-dark-brown)",
      boxShadow: "0 3px 5px 4px rgba(100,65,23, .7)",
      transform: "scale(1.25) translate(-9%,-10%)",
    },
  },
}));
const Holidays = () => {
  const Auth = useContext(AuthContext);
  const db = firebase.database();
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHoliday, setSelectedHoliday] = useState("");
  const [holidays, setHolidays] = useState([]);
  const [today, setToday] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [showAddHolidayForm, setShowAddHolidayForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmDelModalContent, setConfirmDelModalContent] = useState([[], new Date(), ""]);
  const [vacationWeeks, setVacationWeeks] = useState([]);
  const [ptoDays, setPtoDays] = useState([]);
  const history = useHistory();
  const hash = useLocation().hash;
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    heading: "",
    mainContent: <div></div>,
    buttons: [{ title: "", function: () => console.log("modal did a thing!"), color: "", icon: <DeleteForever /> }],
  });
  const [modalDate, setDate] = useState(new Date());
  const [timeOffType, setTimeOffType] = useState("PTO");
  const holidaysFirmDates = [
    { holiday: "New Years Day", month: 1, day: 1 },
    { holiday: "Independence Day", month: 7, day: 4 },
    { holiday: "Christmas Eve", month: 12, day: 24 },
    { holiday: "Christmas Day", month: 12, day: 25 },
    { holiday: "New Years Eve", month: 12, day: 31 },
  ];
  const defaultHolidays = {
    1: { 1: "New Years Day" },
    7: { 4: "Independence Day" },
    12: { 24: "Christmas Eve", 25: "Christmas Day", 31: "New Years Eve" },
  };
  if (!Auth.user?.uid) {
    history.push("/");
  }

  useEffect(() => {
    fetchHolidays();
    fetchVacPTO();
    return () => {
      db.ref("holidays/").off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);
  function fetchVacPTO() {
    const year = hash ? hash.substr(1, 4) : new Date().getFullYear();
    // these firebase queries limit results to all vacation & pto entries ever entered for the user, could be up to 15 per year for 35+ years = over 500 results.
    //.startAt(4)
    db.ref(`hours/${Auth.user.uid}`)
      .orderByChild(`PTO`)
      .startAt(0)
      .endAt(8)
      .once("value")
      .then(function (snapshot) {
        if (snapshot.val() && Object.keys(snapshot.val()).length > 0) {
          const ptoArr = Object.entries(snapshot.val())
            .filter(([key, _]) => key.includes(`${year}`))
            .map(([key, value]) => [
              new Date(key).getMonth() + 1,
              new Date(key).getDate(),
              {
                ...value,
              },
            ]);
          setPtoDays(ptoArr);
        }
      });
    // query firebase database for hours/#uid/#date/Vacation w/ value in range of 20-50, will return json @ 3#date object
    db.ref(`hours/${Auth.user.uid}`)
      .orderByChild(`Vacation`)
      .startAt(0)
      .endAt(50)
      .once("value")
      .then(function (snapshot) {
        if (snapshot.val() && Object.keys(snapshot.val()).length > 0) {
          const vacArr = Object.entries(snapshot.val())
            .filter(([key, _]) => key.includes(`${year}`))
            .map(([key, value]) => [
              new Date(key).getMonth() + 1,
              new Date(key).getDate(),
              {
                ...value,
              },
            ]);
          setVacationWeeks(vacArr);
        }
      });
  }
  function fetchHolidays(dateShown = new Date()) {
    // get date year from url hash or default to todays date
    if (hash && hash.length === 5) {
      dateShown.setFullYear(hash.substr(1, 4));
      setToday(dateShown);
      setSelectedDate(dateShown);
    }
    console.log(`fetched holidays from firebase`);
    db.ref(`holidays/${dateShown.getFullYear()}`).on("value", function (snapshot) {
      const res = (snapshot.val() && snapshot.val()) || {};
      const myHolidays = [];
      Object.entries(res).forEach(([month, value]) => {
        Object.entries(value).forEach(([day, description]) => {
          myHolidays.push([month, day, description]);
        });
      });
      if (myHolidays.length === 0) {
        setShowAddHolidayForm(false);
      }
      if (myHolidays.length < 9 && Auth.user?.admin) {
        Auth.activateSnackBar(`Holidays need to be added`, "warning", 2000);
      } else if (myHolidays.length > 9) {
        Auth.activateSnackBar(`Too many holidays have been added, Please review.`, "error");
      } /*  else {
        Auth.activateSnackBar(``, "warning", 50);
      } */
      setHolidays(myHolidays);
      setIsLoading(false);
    });
  }
  function addHoliday() {
    // if no holidays have been added yet, then add the defaults with dates that don't change
    if (holidays.length === 0) {
      db.ref(`holidays/${today.getFullYear()}`).set(defaultHolidays, function (e) {
        e && console.log(e);
      });
    }
    // add holiday to database when provided with new holiday and date from form, so long as the holiday doesn't already exist
    if (
      selectedHoliday &&
      selectedDate &&
      !holidaysFirmDates.some((holiday) => holiday.holiday.includes(selectedHoliday)) &&
      !holidays.some((holiday) => holiday[2].includes(selectedHoliday)) &&
      (((selectedHoliday === "Labor Day" || selectedHoliday === "Memorial Day") && isMonday(selectedDate)) ||
        (selectedHoliday === "Thanksgiving Day" && isThursday(selectedDate)) ||
        (selectedHoliday === "Black Friday" && isFriday(selectedDate)))
    ) {
      const month = selectedDate.getMonth() + 1;
      const day = selectedDate.getDate();
      db.ref(`holidays/${today.getFullYear()}/${month}/${day}`).set(selectedHoliday, function (e) {
        e && console.log(e);
      });
      Auth.activateSnackBar(`${selectedHoliday} Added.`, "success");
    } else if (!selectedHoliday) {
      Auth.activateSnackBar(`Select a holiday from the dropdown to add.`, "warning");
    } else if ((selectedHoliday === "Labor Day" || selectedHoliday === "Memorial Day") && !isMonday(selectedDate)) {
      Auth.activateSnackBar(`${selectedHoliday} isn't on ${selectedDate.toDateString()}. Try again.`, "warning");
    } else if (selectedHoliday === "Thanksgiving Day" && !isThursday(selectedDate)) {
      Auth.activateSnackBar(`${selectedHoliday} isn't on ${selectedDate.toDateString()}. Try again.`, "warning");
    } else if (selectedHoliday === "Black Friday" && !isFriday(selectedDate)) {
      Auth.activateSnackBar(`${selectedHoliday} isn't on ${selectedDate.toDateString()}. Try again.`, "warning");
    } else if (selectedHoliday && selectedDate) {
      Auth.activateSnackBar(`${selectedHoliday} is already listed in holidays.`, "warning");
    }
    // return view back
    setSelectedHoliday("");
    setShowAddHolidayForm(false);
  }
  function toggleYear(amount) {
    const date = new Date();
    date.setFullYear(today.getFullYear() + amount);
    history.replace(`/holidays#${lightFormat(date, "yyyy")}`);
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  function handleHolidaySelect(value) {
    const newDate = new Date(selectedDate);
    if (value === "Memorial Day") {
      newDate.setMonth(4);
    } else if (value === "Labor Day") {
      newDate.setMonth(8);
    } else if (value === "Thanksgiving Day") {
      newDate.setMonth(10);
    } else if (value === "Black Friday") {
      newDate.setMonth(10);
    }
    setSelectedDate(newDate);
    setSelectedHoliday(value);
  }
  const handleOpen = (holidayInfo, e) => {
    e.stopPropagation();
    const holDate = setMonth(today, holidayInfo[0] - 1);
    holDate.setDate(holidayInfo[1]);
    if (typeof holidayInfo[2] === "string") {
      // is a holiday
      if (Auth.user?.admin) {
        // holidays require auth to be deleted
        setIsModalOpen(true);
        setConfirmDelModalContent([holidayInfo, holDate, "Holiday"]);
      } else {
        Auth.activateSnackBar(`You don't have permission to do this action.`, "warning", 3000);
      }
    } else if (holidayInfo[2].hasOwnProperty("Vacation") || holidayInfo[2].hasOwnProperty("PTO")) {
      // is a vacation week or pto day for the person logged in
      setIsModalOpen(true);
      setConfirmDelModalContent([holidayInfo, holDate, Object.keys(holidayInfo[2])[0], holidayInfo[2][Object.keys(holidayInfo[2])[0]]]);
    }
  };
  function sortByDate(a, b) {
    // sort 2d array in which 1st element in array is month and 2nd day. ie [[2,31],[4,28]]
    if (parseInt(a[0]) === parseInt(b[0])) {
      if (parseInt(a[1]) === parseInt(b[1])) {
        return 0;
      } else {
        return parseInt(a[1]) < parseInt(b[1]) ? -1 : 1;
      }
    } else {
      return parseInt(a[0]) < parseInt(b[0]) ? -1 : 1;
    }
  }
  const handleClose = () => {
    setIsModalOpen(false);
  };
  function deleteHoliday() {
    //confirmDelModalContent[holidayInfo,dateObj,typeString,value{optional}]
    if (confirmDelModalContent[2] === "Holiday" && Auth.user?.admin) {
      const index = holidays.findIndex((holiday) => holiday[0] === confirmDelModalContent[0][0] && holiday[1] === confirmDelModalContent[0][1]);
      const holidayName = holidays[index][2];
      const month = parseInt(holidays[index][0]);
      const day = parseInt(holidays[index][1]);
      // delete holiday in app
      holidays.splice(index, 1);
      //delete holiday in database
      db.ref(`holidays/${today.getFullYear()}/${month}/${day}`).set(null, function (e) {
        e && console.log(e);
      });
      Auth.activateSnackBar(`${holidayName} Removed.`, "success");
      // return view back
      setIsModalOpen(false);
    } else if (confirmDelModalContent[2] === "PTO") {
      const index = ptoDays.findIndex((pto) => pto[0] === confirmDelModalContent[0][0] && pto[1] === confirmDelModalContent[0][1]);
      ptoDays.splice(index, 1);
      db.ref(`hours/${Auth.user.uid}/${confirmDelModalContent[1].toDateString()}`).set(null, function (e) {
        e && console.log(e);
      });
      Auth.activateSnackBar(`Day Off on ${confirmDelModalContent[1].toDateString()} Removed.`, "success");
      setIsModalOpen(false);
    } else if (confirmDelModalContent[2] === "Vacation") {
      const index = vacationWeeks.findIndex((vacation) => vacation[0] === confirmDelModalContent[0][0] && vacation[1] === confirmDelModalContent[0][1]);
      vacationWeeks.splice(index, 1);
      db.ref(`hours/${Auth.user.uid}/${confirmDelModalContent[1].toDateString()}`).set(null, function (e) {
        e && console.log(e);
      });
      Auth.activateSnackBar(`Week Off ending ${confirmDelModalContent[1].toDateString()} Removed.`, "success");
      setIsModalOpen(false);
    } else {
      Auth.activateSnackBar(`You don't have permission to do this action.`, "warning", 3000);
    }
  }
  function handleClick(dateObj, e) {
    e.stopPropagation();
    // user not an admin then goto their hours tracker for that day
    if (!Auth.user?.admin) {
      const todayStr = lightFormat(dateObj, "yyyy-MM-dd");
      history.push(`/hours/day#${todayStr}`);
    }
  }
  function holidayDate() {
    const myDate = new Date();
    const month = holidaysFirmDates[holidaysFirmDates.findIndex((holidayObj) => selectedHoliday.includes(holidayObj?.holiday))]?.month;
    const date = holidaysFirmDates[holidaysFirmDates.findIndex((holidayObj) => selectedHoliday.includes(holidayObj?.holiday))]?.day;
    myDate.setMonth(month - 1);
    myDate.setDate(date);
    return myDate.toDateString();
  }
  function handleTimeOffTypeChange(event, val = modalDate) {
    setTimeOffType(event.target.value);
    setModalContent({
      heading: "Add Time Off",
      mainContent: (
        <div>
          <FormControl variant="outlined">
            <FormHelperText>Select the type of time off you want to add.</FormHelperText>
            <Select value={event.target.value} onChange={(event) => handleTimeOffTypeChange(event, val)}>
              <MenuItem value="Vacation">Vacation Week</MenuItem>
              <MenuItem value="PTO">Paid Time Off</MenuItem>
              <MenuItem value="UTO">Unpaid Time Off</MenuItem>
              <MenuItem value="UWO">Unpaid Week Off</MenuItem>
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider disableStrictModeCompat utils={DateFnsUtils}>
            <FormHelperText>Select the date.</FormHelperText>
            <DatePicker
              label="Date"
              openTo="month"
              inputVariant="outlined"
              format="E MMM d yyyy"
              views={["month", "date"]}
              value={val}
              onChange={(newValue) => {
                handleDatePickerChange(newValue, event.target.value);
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      ),
      buttons: [{ title: "Add", function: () => addTimeOff(val, event.target.value), color: "action", icon: <AddCircle /> }],
    });
  }
  function handleDatePickerChange(value, type = timeOffType) {
    setDate(value);
    setModalContent({
      heading: "Add Time Off",
      mainContent: (
        <div>
          <FormControl variant="outlined">
            <FormHelperText>Select the type of time off you want to add.</FormHelperText>
            <Select value={type} onChange={(event) => handleTimeOffTypeChange(event, value)}>
              <MenuItem value="Vacation">Vacation Week</MenuItem>
              <MenuItem value="PTO">Paid Time Off</MenuItem>
              <MenuItem value="UTO">Unpaid Time Off</MenuItem>
              <MenuItem value="UWO">Unpaid Week Off</MenuItem>
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider disableStrictModeCompat utils={DateFnsUtils}>
            <FormHelperText>Select the date.</FormHelperText>
            <DatePicker
              label="Date"
              openTo="month"
              inputVariant="outlined"
              format="E MMM d yyyy"
              views={["month", "date"]}
              value={value}
              onChange={(newValue) => {
                handleDatePickerChange(newValue, type);
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      ),
      buttons: [{ title: "Add", function: () => addTimeOff(value, type), color: "action", icon: <AddCircle /> }],
    });
  }
  function addTimeOff(date, type) {
    const ftMultiplier = Auth.user?.appSettings?.ft ? 2 : 1;
    let myDate = new Date(date);
    // add the thing to database from info in state
    // change date to weekEnding if type of week is selected
    let amountInHours = 0;
    if (type === "Vacation" || type === "UWO") {
      if (!isSaturday(date)) {
        myDate = new Date(nextSaturday(date));
      }
      if (vacationWeeks && type === "Vacation") {
        amountInHours = 20 * ftMultiplier;
      } else if (type === "Vacation") {
        amountInHours = 25 * ftMultiplier;
      }
    } else if (type === "PTO") {
      amountInHours = 4 * ftMultiplier;
    }
    if (
      // check if date already exists as off and alert user
      (vacationWeeks && vacationWeeks.some((week) => week[0] === myDate.getMonth() + 1 && week[1] === myDate.getDate())) ||
      (ptoDays && ptoDays.some((day) => day[0] === myDate.getMonth() + 1 && day[1] === myDate.getDate()))
    ) {
      Auth.activateSnackBar(`${date.toDateString()} is already in the list!`, "warning");
    } else {
      // otherwise add new date to database
      db.ref(`hours/${Auth.user.uid}/${myDate.toDateString()}/${type.includes("TO") ? "PTO" : "Vacation"}`).set(amountInHours, function (e) {
        e && console.log(e);
      });
      // update view with new data
      if (type === "PTO" || type === "UTO") {
        ptoDays.push([myDate.getMonth() + 1, myDate.getDate(), { PTO: amountInHours }]);
      } else {
        vacationWeeks.push([myDate.getMonth() + 1, myDate.getDate(), { Vacation: amountInHours }]);
      }

      // alert user that action has been completed
      Auth.activateSnackBar(`Time Off on ${date.toDateString()} added.`, "success");
    }
    // reset to defaults
    setTimeOffType("PTO");
    setDate(new Date());
    setShowModal(false);
  }
  function addTimeOffBtn() {
    // create modal content to show on screen
    setDate(new Date(today));
    setModalContent({
      heading: "Add Time Off",
      mainContent: (
        <div>
          <FormControl variant="outlined">
            <FormHelperText>Select the type of time off you want to add.</FormHelperText>
            <Select value={timeOffType} onChange={(event) => handleTimeOffTypeChange(event)}>
              <MenuItem value="Vacation">Vacation Week</MenuItem>
              <MenuItem value="PTO">Paid Time Off</MenuItem>
              <MenuItem value="UTO">Unpaid Time Off</MenuItem>
              <MenuItem value="UWO">Unpaid Week Off</MenuItem>
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider disableStrictModeCompat utils={DateFnsUtils}>
            <FormHelperText>Select the date.</FormHelperText>
            <DatePicker
              label="Date"
              openTo="month"
              inputVariant="outlined"
              format="E MMM d yyyy"
              views={["month", "date"]}
              value={modalDate}
              onChange={(newValue) => {
                handleDatePickerChange(newValue, timeOffType);
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      ),
      buttons: [{ title: "Add", function: () => addTimeOff(modalDate, timeOffType), color: "action", icon: <AddCircle /> }],
    });
    setShowModal(true);
  }
  return (
    <div className={classes.page}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">{confirmDelModalContent[1].toDateString()}</h2>
            <p id="transition-modal-description">
              Are you sure you want to delete this {confirmDelModalContent[3] ? "Holiday" : confirmDelModalContent[2] === "PTO" ? "Day Off" : "Week Off"}?
            </p>
            <div className={classes.flexRowAround}>
              <MyButton className={classes.button} onClick={handleClose}>
                <ArrowBack />
                Go Back
              </MyButton>
              <MyButton className={classes.button} style={{ background: "var(--alert)", color: "var(--ups-dark-brown)" }} onClick={deleteHoliday}>
                Delete <DeleteForever />
              </MyButton>
            </div>
          </div>
        </Fade>
      </Modal>
      <h1 className={classes.h1}>
        <span style={{ padding: "0 .75rem" }}>Holidays</span>
        <MyArrowButtonLeft onClick={() => toggleYear(-1)} aria-label="previous year button">
          <ArrowBackIos style={{ marginLeft: ".5rem" }} />
        </MyArrowButtonLeft>
        <span style={{ padding: "0 .5rem" }}>{today.getFullYear()}</span>
        <MyArrowButtonRight onClick={() => toggleYear(1)} aria-label="previous year button">
          <ArrowForwardIos />
        </MyArrowButtonRight>
      </h1>
      {!showModal && (
        <h2 className={classes.h2}>
          <MyButton onClick={addTimeOffBtn}>
            <span>Add Time Off</span>
          </MyButton>
        </h2>
      )}
      <GModal content={modalContent} state={[modalDate, timeOffType]} show={showModal} setShow={setShowModal} />
      <ul className={holidays.length > 0 ? classes.list : classes.noList}>
        {isLoading && <Spinner />}
        {!showAddHolidayForm &&
          holidays &&
          holidays
            .concat(vacationWeeks)
            .concat(ptoDays)
            .sort(sortByDate)
            .map((value) => {
              //holidayDate.setMonth(parseInt(value[0]));
              //! ** setMonth() when to date in current month is greater than date allowed in month to be set to causes bug so date-fns must be used. ex: 8/31/2021.setMonth(1)=>march 3rd because feb 31 doesn't exist
              const holidayDate = setMonth(selectedDate, value[0] - 1);
              holidayDate.setDate(value[1]);
              return (
                <li
                  className={`${isAfter(new Date(), holidayDate) && classes.past} ${
                    (Object.keys(value[2]).includes("Vacation") || Object.keys(value[2]).includes("PTO")) && classes.ptoAndVacation
                  } ${classes.card}`}
                  key={`${value[0]}/${value[1]}`}
                  onClick={(event) => handleClick(holidayDate, event)}
                >
                  {(Auth.user?.admin || (typeof value[2] === "object" && value[2] !== null)) && (
                    <div className={classes.absoluteBR} onClick={(event) => handleOpen(value, event)}>
                      <DeleteForever style={{ color: "var(--alert)" }} />
                    </div>
                  )}
                  <div>{holidayDate.toDateString()} </div>
                  {Object.keys(value[2]).includes("Vacation") ? (
                    <>
                      <div style={{ fontWeight: 600 }}>{value[2].Vacation === 0 ? "Week Off" : "Vacation Week"}</div>
                      {value[2].Vacation === 0 ? <div style={{ color: "var(--info)" }}>Unpaid</div> : <span style={{ color: "var(--success)" }}>Paid {value[2].Vacation} Hours</span>}
                    </>
                  ) : Object.keys(value[2]).includes("PTO") ? (
                    <>
                      <div style={{ fontWeight: 600 }}>Day Off</div>
                      {value[2].PTO === 0 ? <div style={{ color: "var(--info)" }}>Unpaid</div> : <span style={{ color: "var(--success)" }}>Paid {value[2].PTO} Hours</span>}
                    </>
                  ) : (
                    <>
                      <div style={{ fontWeight: 600 }}>{value[2]}</div>
                      {Auth.size.height > 800 ? (
                        <>
                          {value[2].includes("Christmas Eve") ? <div style={{ color: "var(--info)" }}>Unpaid Holiday</div> : <div style={{ color: "var(--success)" }}>Paid Holiday</div>}
                          {value[2].includes("Black Friday") ? (
                            <div style={{ color: "var(--info)" }}>Scheduled Shift</div>
                          ) : (
                            <div style={{ color: "var(--success)" }}>Scheduled Off</div>
                          )}
                        </>
                      ) : (
                        <div>
                          {value[2].includes("Christmas Eve") ? <span style={{ color: "var(--info)" }}>Unpaid </span> : <span style={{ color: "var(--success)" }}>Paid </span>}
                          {value[2].includes("Black Friday") ? <span style={{ color: "var(--info)" }}>Scheduled</span> : <span style={{ color: "var(--success)" }}>Off</span>}
                        </div>
                      )}
                    </>
                  )}
                </li>
              );
            })}
        {!showAddHolidayForm && holidays.length < 9 && (
          <li className={holidays.length > 0 ? classes.card : classes.noCard}>
            {!isLoading && holidays.length === 0 && <p>No Holidays Loaded</p>}
            {Auth.user?.admin && <MyButton onClick={() => setShowAddHolidayForm(true)}>Add Holiday</MyButton>}
          </li>
        )}
      </ul>
      {Auth.user?.admin && showAddHolidayForm && (
        <div className={classes.form}>
          <div className={classes.absoluteTR} onClick={() => setShowAddHolidayForm(false)}>
            <Undo />
          </div>
          <FormHelperText style={{ paddingBottom: ".5rem", marginRight: "2rem" }}>Select a holiday to add to this years calendar.</FormHelperText>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="holiday-select-label">Pick a Holiday</InputLabel>
            <Select labelId="holiday-select-label" id="holiday-select" label="Pick a Holiday" value={selectedHoliday} onChange={(e) => handleHolidaySelect(e.target.value)}>
              {["New Years Day", "Memorial Day", "Independence Day", "Labor Day", "Thanksgiving Day", "Black Friday", "Christmas Eve", "Christmas Day", "New Years Eve"].map(
                (holidayLabel) => (
                  <MenuItem value={holidayLabel} key={holidayLabel}>
                    {holidayLabel}
                    {holidays && holidays.some((holiday) => holiday[2].includes(holidayLabel)) ? (
                      <Checkbox checked={true} style={{ color: "var(--success)" }} />
                    ) : (
                      <CheckBoxOutlineBlank style={{ color: "red" }} />
                    )}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          {selectedHoliday.length > 0 ? (
            selectedHoliday.includes("Christmas Eve") ? (
              <div style={{ color: "var(--info)", padding: ".25rem" }}>Unpaid Holiday</div>
            ) : (
              <div style={{ color: "var(--success)", padding: ".25rem" }}>Paid Holiday</div>
            )
          ) : null}
          {selectedHoliday.length > 0 ? (
            selectedHoliday.includes("Black Friday") ? (
              <div style={{ color: "var(--info)", padding: ".25rem" }}>Scheduled Shift</div>
            ) : (
              <div style={{ color: "var(--success)", padding: ".25rem" }}>Scheduled Off</div>
            )
          ) : null}
          {["Black Friday", "Memorial Day", "Thanksgiving Day", "Labor Day"].some((i) => selectedHoliday.includes(i)) && (
            <MuiPickersUtilsProvider disableStrictModeCompat utils={DateFnsUtils}>
              <FormHelperText>Select the Date for {selectedHoliday}.</FormHelperText>
              {selectedHoliday === "Memorial Day" && <FormHelperText>Last Monday in May</FormHelperText>}
              {selectedHoliday === "Labor Day" && <FormHelperText>First Monday in September</FormHelperText>}
              {selectedHoliday === "Thanksgiving Day" && <FormHelperText>Last Thursday of November</FormHelperText>}
              {selectedHoliday === "Black Friday" && <FormHelperText>Last Friday of November"</FormHelperText>}
              <DatePicker
                inputVariant="outlined"
                margin="normal"
                id="date picker"
                label="Date"
                format="E MMM d yyyy"
                views={["date"]}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          )}
          {holidaysFirmDates.some((holidayObj) => selectedHoliday.includes(holidayObj?.holiday)) ? <div style={{ padding: ".25rem 0 .5rem" }}>{holidayDate()}</div> : null}{" "}
          <MyButton style={{ margin: ".5rem", padding: ".5rem 1rem" }} onClick={addHoliday}>
            add holiday
          </MyButton>
        </div>
      )}
    </div>
  );
};
export default Holidays;
