import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LinearProgress, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./App";
import { makeStyles } from "@material-ui/core/styles";
import Hamburger from "./Hamburger";
const useStyles = makeStyles(() => ({
  profileBox: {
    position: "absolute",
    right: 0,
    marginRight: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  container: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: ".5rem",
    color: "var(--ups-gold)",
    fontSize: "20px",
  },
  breadcrumb: {
    background: "var(--ups-brown)",
    border: 0,
    borderRadius: ".3rem",
    color: "var(--ups-yellow)",
    fontWeight: "bolder",
    padding: "0 .25rem",
    maxWidth: "12rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "clip",
  },
  logo: {
    height: "2.5rem",
    cursor: "pointer",
    margin: ".25rem 0",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "fill",
  },
  profilePic: {
    height: "2.25rem",
    width: "2.25rem",
    cursor: "pointer",
  },
  userName: {
    fontSize: ".5rem",
    maxWidth: "5rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginRight: ".25rem",
  },
  accountCircle: {
    background: "var(--ups-brown)",
    borderRadius: ".3rem",
    padding: ".3rem",
    cursor: "pointer",
  },
  flexRightColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  progressBar: {
    backgroundColor: "var(--ups-brown)",
    color: "var(--ups-yellow)",
    barColorPrimary: "red",
  },
}));
const Header = (props) => {
  const Auth = useContext(AuthContext);
  const classes = useStyles();
  const [hourTrackerActive, SetHourTrackerActive] = useState(false);
  const [breadCrumb, setBreadCrumb] = useState("");
  const history = useHistory();
  //const profilePicTEST = "https://picsum.photos/200";
  useEffect(() => {
    determineBreadCrumb(history.location);
    console.log("useEffect called in header...");
    return history.listen((location) => {
      determineBreadCrumb(location);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourTrackerActive, history]);
  function loginClicked() {
    // onclick trigger open login modal if user is not logged
    if (!Auth.user?.uid && !props.isLoggingIn) {
      props.triggerLoginModal();
    }
  }
  function hashToDateString(hash) {
    if (hash) {
      const date = new Date(`${hash.substring(1, hash.length)} 12:00`);
      return date.toDateString().substr(4, 6);
    }
  }
  function determineBreadCrumb(location) {
    const title = document.getElementsByTagName("title")[0];
    const url = location.pathname.toLowerCase();
    const hash = location.hash;
    if (url.includes("/d") && !url.includes("/hours")) {
      title.innerText = "Start Times | Day";
      setBreadCrumb("Start Times - Day");
    } else if (url.includes("/t") && !url.includes("/hours")) {
      title.innerText = "Start Times | Twilight";
      setBreadCrumb("Start Times - Twilight");
    } else if (url.includes("/n") || url.includes("/m")) {
      title.innerText = "Start Times | Night";
      setBreadCrumb("Start Times - Night");
    } else if (url.includes("/p")) {
      title.innerText = "Start Times | Preload";
      setBreadCrumb("Start Times - Preload");
    } else if (url.includes("/hours/day")) {
      title.innerText = `Hour Tracker | ${hashToDateString(hash)}`;
      setBreadCrumb(`Hour Tracker - ${hashToDateString(hash)}`);
    } else if (url.includes("/hours/week")) {
      title.innerText = `Hour Tracker | ${hashToDateString(hash)}`;
      setBreadCrumb(`Hour Tracker - ${hashToDateString(hash)}`);
    } else if (url.includes("/hours/totals")) {
      title.innerText = `Hour Totals | ${hashToDateString(hash)}`;
      setBreadCrumb(`Hour Totals - ${hashToDateString(hash)}`);
    } else if (url.includes("/hours")) {
      title.innerText = `UPS | Hour Tracker`;
      setBreadCrumb(`Hour Tracker`);
    } else if (url.includes("/settings")) {
      title.innerText = `UPS | User Settings`;
      setBreadCrumb(`User Settings`);
    } else if (url === "/all" && hash) {
      title.innerText = `Start Times | ${hashToDateString(hash)}`;
      setBreadCrumb(`Start Times - ${hashToDateString(hash)}`);
    } else if (url === "/all") {
      title.innerText = `UPS Start Times`;
      setBreadCrumb(`Start Times`);
    } else if (url === "/user") {
      title.innerText = `Admin | UserName`;
      setBreadCrumb(`Admin - UserName`);
    } else if (url === "/users") {
      title.innerText = `Admin | Users`;
      setBreadCrumb(`Admin - Users`);
    } else if (url === "/edits") {
      title.innerText = `Admin | Recent Edits`;
      setBreadCrumb(`Admin - Recent Edits`);
    } else if (url === "/") {
      title.innerText = `UPS Start Times`;
      setBreadCrumb(`Start Times`);
    }
    if (url === "/hours") {
      SetHourTrackerActive(true);
    }
  }
  function goToApp() {
    const currentVal = hourTrackerActive;
    SetHourTrackerActive(!currentVal);
    if (!currentVal) {
      history.push("/hours");
    } else {
      history.push("/");
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.logo} onClick={goToApp}>
        <img className={classes.img} src="/logo192.png" alt="logo" height="50px" width="50px" />
      </div>
      <div className={classes.breadcrumb}>{breadCrumb}</div>
      {Auth.user?.uid ? (
        <div className={classes.profileBox}>
          <Hamburger toggleDrawer={props.toggleDrawer} isDrawerOpen={props.isDrawerOpen} />
          <div className={classes.userName}>{Auth.user?.displayName}</div>
        </div>
      ) : (
        <Button className={`${classes.profileBox} ${classes.breadcrumb}`} style={{ marginTop: 0, fontSize: "16px" }} onClick={() => loginClicked()} disabled={props.isLoggingIn}>
          {props.isLoggingIn ? (
            <div aria-busy="true">
              Waiting...
              <LinearProgress className={classes.progressBar} color="secondary" />
            </div>
          ) : (
            <div>Login</div>
          )}
        </Button>
      )}
    </div>
  );
};

export default Header;
Header.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  triggerLoginModal: PropTypes.func.isRequired,
};
