import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Button, ButtonGroup, Tooltip } from "@material-ui/core";
import { ChevronLeft, ChevronRight, CalendarToday, Undo, Delete, AddAlarm, AlarmAdd, DeleteForever } from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";
import { isValid, addDays, getDay, endOfWeek, formatDistanceStrict, isToday, parseISO, isAfter, addHours, addMinutes, isSameDay, isFriday, isSaturday, startOfWeek } from "date-fns";
import { hoursToAmPmFormat, timeStringToMilitaryHours, toISOLocaleString } from "./Utils";
import { AuthContext } from "./App";
import firebase from "firebase/app";
import "firebase/database";
import GModal from "./GModal";
//TODO: unify button styles: currently 3 different button styles shown on page
const MyButton = styled(Button)({
  background: "#ffb500",
  border: 0,
  borderRadius: ".6rem",
  color: "var(--ups-dark-brown)",
  fontWeight: "bolder",
  "&:hover": {
    background: "var(--ups-gold)",
  },
});
const NavButton = styled(Button)({
  background: "#ffb500",
  border: 0,
  borderRadius: ".6rem",
  color: "var(--ups-dark-brown)",
  fontWeight: "bolder",
  padding: ".25rem .5rem",
  width: "max-content",
  "&:hover": {
    background: "var(--ups-gold)",
  },
});
const SimpleButton = styled(Button)({
  color: "var(--ups-brown)",
  padding: 0,
  marginTop: "-.25rem",
  "font-size": "1rem",
  fontWeight: "700",
});
const HoursDaily = () => {
  const db = firebase.database();
  const Auth = useContext(AuthContext);
  const history = useHistory();
  const hash = useLocation().hash;
  const [dateShown, setDateShown] = useState(checkDateHash() || new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [totalTime, setTotalTime] = useState(0);
  const [timePickerActive, setTimePickerActive] = useState(false);
  const [shiftState, setShiftState] = useState(0);
  const [breakTime, setBreakTime] = useState(false);
  const [allClockTimes, setAllClockTimes] = useState({});
  const [startTime, setStartTime] = useState("N/A");
  const [isEditTime, setIsEditTime] = useState(false);
  const [editType, setEditType] = useState("");
  const [swipe, setSwipe] = useState(false);
  const [swipePositionStart, setSwipePositionStart] = useState(0);
  const [swipePositionEnd, setSwipePositionEnd] = useState(0);
  const [showAsterisk, setShowAsterisk] = useState(false);
  const [pto, setPto] = useState(0);
  const [holiday, setHoliday] = useState(false);
  const [weekClockTimes, setWeekClockTimes] = useState({});
  const [st, setST] = useState(0);
  const [ot, setOT] = useState(0);
  const [dt, setDT] = useState(0);
  const [edit, setEdit] = useState("");
  const [payRate, setPayRate] = useState(Auth?.user?.appSettings?.payRate || 15);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    heading: "",
    mainContent: <div></div>,
    buttons: [{ title: "", function: () => console.log("modal did a thing!"), color: "", icon: <DeleteForever /> }],
  });

  useEffect(() => {
    // check if url is valid date before fetching data, otherwise goto calendar picker.
    if (dateShown && isValid(checkDateHash())) {
      fetchClockTimes(checkDateHash());
      fetchStartTime(checkDateHash());
      fetchHolidays(checkDateHash());
    } else {
      history.replace("/hours");
    }
    return () => {
      db.ref(`hours/`).off();
      db.ref(`weeks/`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);
  useEffect(() => {
    if (Object.keys(allClockTimes).length > 0) {
      calculateTotalTime(allClockTimes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClockTimes]);
  useEffect(() => {
    if (allClockTimes?.ClockIn !== undefined && startTime !== "N/A") {
      checkIsLate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, allClockTimes, edit]);
  function fetchPreviousStartTimes(date) {
    if (isValid(date)) {
      // example query "3/25/2021, 11:30:00 AM"
      const timeString = hoursToAmPmFormat(date).split(" ");
      const query = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}, ${timeString[0]}:00 ${timeString[1]}`;
      db.ref(`edits/`)
        .orderByChild("newValue")
        .equalTo(query)
        .once("value")
        .then(function (snapshot) {
          const result = snapshot?.val() || {};
          if (Object.keys(result).length === 0) {
            // no edits found for the given start time
            setEdit("");
          } else {
            Object.keys(result).forEach((key) => {
              if (result[key]?.prevValue && !result[key]?.hidden) {
                const editStr = result[key]?.prevValue.split(",")[1].trim().replace(":00", "");
                // convert to date object
                let newDate = new Date(date);
                newDate.setHours(timeStringToMilitaryHours(editStr));
                newDate.setMinutes(editStr.substr(-5, 2));
                // check that previous start time is later than new start, otherwise ignore it and set as ""
                if (isAfter(newDate, date)) {
                  // apply offset //todo this code segment is repeated.
                  if (Auth.user?.appSettings?.offset) {
                    newDate = addMinutes(
                      newDate,
                      isFriday(date) && Auth.user.appSettings.offset === -15 && Auth.user.appSettings?.shift === "t"
                        ? parseInt(Auth.user.appSettings.offset) * 2
                        : parseInt(Auth.user.appSettings.offset)
                    );
                  }
                  // convert back to string before vale is set to state
                  setEdit(hoursToAmPmFormat(newDate));
                } else {
                  setEdit("");
                }
              }
            });
          }
        });
    }
  }
  function checkDateHash() {
    const dateStr = hash.slice(1, hash.length);
    if (
      typeof dateStr === "string" &&
      dateStr.length === 10 &&
      dateStr.split("-").length === 3 &&
      dateStr.split("-")[0].length === 4 &&
      dateStr.split("-")[1].length === 2 &&
      dateStr.split("-")[2].length === 2
    ) {
      let timeStr = "T12:30";
      if (Auth.user?.appSettings?.shift) {
        if (Auth.user?.appSettings?.shift === "t") {
          timeStr = "T17:30";
        } else if (Auth.user?.appSettings?.shift === "n") {
          timeStr = "T22:40";
        } else if (Auth.user?.appSettings?.shift === "p") {
          timeStr = "T04:00";
        }
      }
      const date = parseISO(dateStr + timeStr);
      if (isValid(date)) {
        // sunday shift change default start time
        if (date.getDay() === 0 && Auth.user?.appSettings?.shift === "d") {
          date.setHours(15);
          date.setMinutes(30);
        } else if (date.getDay() === 0 && (Auth.user?.appSettings?.shift === "t" || Auth.user?.appSettings?.shift === "n")) {
          date.setHours(8);
          date.setMinutes(0);
        } else if (date.getDay() === 1 && Auth.user?.appSettings?.shift === "p") {
          date.setHours(2);
          date.setMinutes(0);
        }
        return date;
      } else {
        return;
      }
    } else {
      console.log("date not valid, going to calendar picker...");
      return;
    }
  }
  function fetchStartTime(date) {
    // NEED week ending date, shift, day of the week index
    console.log(`fetchingStartTimes: ${date}`);
    if (!isValid(date)) {
      history.replace("/hours");
    } else {
      const we = endOfWeek(date);
      const weConverted = convertDateFormat(we);
      const shift = Auth.user?.appSettings?.shift || "t";
      const dayIndex = getDay(new Date(date.toDateString()));
      db.ref(`weeks/${weConverted}/${shift}/${dayIndex}/time`).on("value", function (snapshot) {
        if (snapshot.val()) {
          const time = (snapshot.val() && snapshot.val()) || {};
          if (time.length >= 5) {
            let newDate = date || new Date();
            newDate.setHours(timeStringToMilitaryHours(time));
            newDate.setMinutes(time.substr(-5, 2));
            fetchPreviousStartTimes(newDate);
            // calc offset
            if (Auth.user?.appSettings?.offset) {
              newDate = addMinutes(
                newDate,
                isFriday(date) && Auth.user.appSettings.offset === -15 && Auth.user.appSettings?.shift === "t"
                  ? parseInt(Auth.user.appSettings.offset) * 2
                  : parseInt(Auth.user.appSettings.offset)
              );
            }
            setStartTime(hoursToAmPmFormat(newDate));
            setDateShown(newDate);
          } else {
            setStartTime("Holiday");
          }
        } else {
          setStartTime("N/A");
          setDateShown(date);
        }
      });
    }
  }
  function fetchClockTimes(date) {
    if (!isValid(date)) {
      history.replace("/hours");
    } else {
      console.log("fetching clockIn times for: " + date.toDateString());
      db.ref(`hours/${Auth.user.uid}/${date.toDateString()}`).on("value", function (snapshot) {
        var clockTimes = (snapshot.val() && snapshot.val()) || {};
        if (!snapshot.val()) {
          setShiftState(0);
          setTotalTime(0);
          console.log(`No clock in times found for today: ${date.toDateString()}`);
        }
        if (clockTimes?.ClockIn) {
          calculateTotalTime(clockTimes);
        }
        if (clockTimes?.PTO) {
          setPto(clockTimes.PTO);
        }
        setAllClockTimes(clockTimes);
        setIsLoading(false);
        if (isFriday(date) || isSaturday(date)) {
          //get count of all days worked this week
          const start = startOfWeek(new Date(date));
          for (let index = 0; index < 7; index++) {
            const indexDate = addDays(start, index);
            db.ref(`hours/${Auth.user.uid}/${indexDate.toDateString()}`).on("value", function (snapshot2) {
              if (snapshot2?.val()?.ClockOut) {
                const clockTime = snapshot2.val();
                const timesCopy = weekClockTimes;
                timesCopy[index] = clockTime;
                if (Object.keys(weekClockTimes).length === 7 && isSaturday(date)) {
                  // 7th day = all double pay
                  setST(0);
                  setOT(0);
                  dtHours(calculateTotalTime(clockTimes), true);
                } else if (Object.keys(weekClockTimes).length >= 6) {
                  // 6th day == all overTime, no ST hours
                  setST(0);
                  otHours(calculateTotalTime(clockTimes), true);
                  setDT(0);
                } else if (st === 0 && ot === 0) {
                  stHours(calculateTotalTime(clockTimes));
                  otHours(calculateTotalTime(clockTimes));
                } else {
                  // regularly worked friday or saturday
                  stHours(calculateTotalTime(clockTimes));
                  otHours(calculateTotalTime(clockTimes));
                }
                setWeekClockTimes(timesCopy);
              }
            });
          }
        } else {
          stHours(calculateTotalTime(clockTimes));
          otHours(calculateTotalTime(clockTimes));
          setDT(0);
          setWeekClockTimes({});
        }
      });
    }
  }
  function convertDateFormat(dateTimeObj) {
    //input dateTime object to return date string in form of "yyyy-mm-dd"
    /* copied from startTimes.js temporarily til better solution */
    const date = `${dateTimeObj.getFullYear()}-${("0" + (dateTimeObj.getMonth() + 1)).slice(-2)}-${("0" + dateTimeObj.getDate()).slice(-2)}`;
    return date;
  }
  function dateStrToDateObj(dateObj = dateShown, dateStr = startTime) {
    // create a new date object from the provided one. Used for the date portion of the object
    const date = new Date(dateObj);
    // check if the provided date string meets criteria to be converted into hours and minutes. otherwise just return the given date object.
    if (dateStr && dateStr !== "N/A" && dateStr !== "Holiday" && dateStr.length >= 5) {
      // get hours & minutes form the date string as Integers
      const startHour = timeStringToMilitaryHours(dateStr);
      const startMins = parseInt(dateStr.substr(-5, 2));
      // mutate the initial date object with the new hours and minutes
      date.setHours(startHour);
      date.setMinutes(startMins);
    }
    // return the date object
    return date;
  }
  function ClockIn(newTime, clockType) {
    if (!isSameDay(newTime, dateShown)) {
      db.ref(`hours/${Auth.user.uid}/${dateShown.toDateString()}/${clockType}`).set(hoursToAmPmFormat(newTime), function (e) {
        e && console.log(e);
      });
    } else {
      if (clockType === "ClockIn" && startTime !== "N/A" && isAfter(dateStrToDateObj(), newTime)) {
        // if clock in is before scheduled start time, show a modal to prevent user from accidentally starting early.
        // check if edit exists, then check if startTime or edit isBefore(), and use that value to check if its before the given clockIn time "newTime";
        // show a modal to confirm that newTime is before startTime/edit'
        setModalContent({
          heading: "Are You Sure?",
          mainContent: (
            <div>
              <p>
                This clock in time of <b>{hoursToAmPmFormat(newTime)}</b> is before your scheduled start time of <b>{startTime}</b>.
              </p>
              <p>
                <b>If you were approved to start early then click confirm.</b>
              </p>
              <p>Otherwise go back and pick a time after your scheduled start time.</p>
            </div>
          ),
          buttons: [{ title: "Confirm", function: () => confirmClockIn(newTime, clockType), color: "", icon: <AlarmAdd /> }],
        });
        setShowModal(true);
      } else {
        // add clockIn time to database.
        db.ref(`hours/${Auth.user.uid}/${newTime.toDateString()}/${clockType}`).set(hoursToAmPmFormat(newTime), function (e) {
          e && console.log(e);
        });
      }
      setDateShown(newTime);
    }
    if (clockType === "BreakIn") {
      setBreakTime(false);
    }
    setTimePickerActive(false);
    setEditType("");
    setIsEditTime(false);
  }
  async function confirmClockIn(newTime, clockType) {
    db.ref(`hours/${Auth.user.uid}/${newTime.toDateString()}/${clockType}`).set(hoursToAmPmFormat(newTime), function (e) {
      e && console.log(e);
    });
    setShowModal(false);
    setModalContent({
      heading: "",
      mainContent: <div></div>,
      buttons: [{ title: "", function: () => console.log("modal did a thing!"), color: "", icon: <DeleteForever /> }],
    });
  }
  function calculateTotalTime(times = allClockTimes) {
    let afterMidnightDate = new Date(dateShown);
    if (times?.ClockOut && timeStringToMilitaryHours(times?.ClockOut) <= 11 && times?.ClockIn && timeStringToMilitaryHours(times?.ClockIn) >= 15) {
      // if clockOut time is next day ie. 3am for pt workers on midnight, ft workers, double-shifters on preload
      afterMidnightDate = addDays(dateShown, 1);
    }
    if (times?.ClockOut && times?.BreakOut && times?.BreakIn) {
      // on ClockOUT w/Breakout: full 2 shifts ((clockOut-clockIn)-(breakOut-breakIn))    [ft]
      const diffTotal = formatDistanceStrict(
        new Date(`${afterMidnightDate.toDateString()} ${timeStringToMilitaryHours(times?.ClockOut)}:${times?.ClockOut.substr(-5, 2)}`),
        new Date(`${dateShown.toDateString()} ${timeStringToMilitaryHours(times?.ClockIn)}:${times?.ClockIn.substr(-5, 2)}`),
        { unit: "minute" }
      );
      const diffBreak = formatDistanceStrict(
        new Date(`${dateShown.toDateString()} ${timeStringToMilitaryHours(times?.BreakOut)}:${times?.BreakOut.substr(-5, 2)}`),
        new Date(`${dateShown.toDateString()} ${timeStringToMilitaryHours(times?.BreakIn)}:${times?.BreakIn.substr(-5, 2)}`),
        { unit: "minute" }
      );
      const [totalMin] = diffTotal.split(" ");
      const [breakMin] = diffBreak.split(" ");
      setShiftState(5);
      if (times?.Holiday) {
        setTotalTime(parseInt(totalMin) - parseInt(breakMin) + times.Holiday * 60);
        return parseInt(totalMin) - parseInt(breakMin) + times.Holiday * 60;
      } else {
        setTotalTime(parseInt(totalMin) - parseInt(breakMin));
        return parseInt(totalMin) - parseInt(breakMin);
      }
    } else if (times?.ClockOut && !times?.BreakOut && !times?.BreakIn) {
      // on ClockOUT : full 1 shift w/o break (clockOut-clockIn)    [pt]
      const diffMinutes = formatDistanceStrict(
        new Date(`${afterMidnightDate.toDateString()} ${timeStringToMilitaryHours(times?.ClockOut)}:${times?.ClockOut.substr(-5, 2)}`),
        new Date(`${dateShown.toDateString()} ${timeStringToMilitaryHours(times?.ClockIn)}:${times?.ClockIn.substr(-5, 2)}`),
        { unit: "minute" }
      );
      const [minutes] = diffMinutes.split(" ");
      setShiftState(4);
      if (times?.Holiday) {
        setTotalTime(parseInt(minutes) + times.Holiday * 60);
        return parseInt(minutes) + times.Holiday * 60;
      } else {
        setTotalTime(parseInt(minutes));
        return parseInt(minutes);
      }
    } else if (times?.BreakOut && times?.BreakIn && !times?.ClockOut) {
      // on BreakOut : 1 shift+current ((currentTime-clockIn)-(breakOut-breakIn))   [ft]
      const diffTotal = formatDistanceStrict(new Date(dateShown), new Date(`${dateShown.toDateString()} ${timeStringToMilitaryHours(times?.ClockIn)}:${times?.ClockIn.substr(-5, 2)}`), {
        unit: "minute",
      });
      const diffBreak = formatDistanceStrict(
        new Date(`${dateShown.toDateString()} ${timeStringToMilitaryHours(times?.BreakOut)}:${times?.BreakOut.substr(-5, 2)}`),
        new Date(`${dateShown.toDateString()} ${timeStringToMilitaryHours(times?.BreakIn)}:${times?.BreakIn.substr(-5, 2)}`),
        { unit: "minute" }
      );
      const [totalMin] = diffTotal.split(" ");
      const [breakMin] = diffBreak.split(" ");
      setShiftState(3);
      if (times?.Holiday) {
        setTotalTime(parseInt(totalMin) - parseInt(breakMin) + times.Holiday * 60);
        return parseInt(totalMin) - parseInt(breakMin) + times.Holiday * 60;
      } else {
        setTotalTime(parseInt(totalMin) - parseInt(breakMin));
        return parseInt(totalMin) - parseInt(breakMin);
      }
    } else if (!times?.BreakOut && times?.BreakIn) {
      // on BreakIN : 1 shift (breakIn-clockIn)    [ft]
      const diffMinutes = formatDistanceStrict(
        new Date(`${dateShown.toDateString()} ${timeStringToMilitaryHours(times?.BreakIn)}:${times?.BreakIn.substr(-5, 2)}`),
        new Date(`${dateShown.toDateString()} ${timeStringToMilitaryHours(times?.ClockIn)}:${times?.ClockIn.substr(-5, 2)}`),
        { unit: "minute" }
      );
      const [minutes] = diffMinutes.split(" ");
      setShiftState(2);
      setTotalTime(parseInt(minutes));
      return parseInt(minutes);
    } else if (!times?.BreakOut && !times?.BreakIn && !times?.ClockOut && times?.ClockIn) {
      // on Clock IN : current (currentTime-clockIn) [pt/ft]
      const date1 = new Date();
      const date2 = new Date(`${dateShown.toDateString()} ${timeStringToMilitaryHours(times?.ClockIn)}:${times?.ClockIn.substr(-5, 2)}`);
      const diffMinutes = formatDistanceStrict(date1, date2, { unit: "minute" });
      const [minutes] = diffMinutes.split(" ");
      setShiftState(1);
      if (isToday(new Date(dateShown))) {
        //  calculate time worked as clockIn to time now IF the day being viewed is today
        setTotalTime(parseInt(minutes));
        return parseInt(minutes);
      }
    } else if (!times?.ClockIn && times?.PTO) {
      setTotalTime(times.PTO * 60);
      return times.PTO * 60;
    } else if (times?.Holiday) {
      setTotalTime(times.Holiday * 60);
      return times.Holiday * 60;
    } else {
      setShiftState(0);
    }
  }
  function changeDay(num) {
    setEditType("");
    setIsEditTime(false);
    setBreakTime(false);
    setTotalTime(0);
    if (num > 0) {
      //goto next day in the week
      const nextDay = addDays(dateShown, 1);
      setDateShown(nextDay);
      history.push({ pathname: "/hours/day", hash: toISOLocaleString(addDays(dateShown, 1)) });
    } else if (num < 0) {
      //goto prev day
      const prevDay = addDays(dateShown, -1);
      setDateShown(prevDay);
      history.push({ pathname: "/hours/day", hash: toISOLocaleString(addDays(dateShown, -1)) });
    }
  }
  function toggleEdit(clockTypeStr) {
    setEditType(clockTypeStr);
    setIsEditTime(true);
  }
  function cancelEdit() {
    setEditType("");
    setIsEditTime(false);
  }
  function startSwipe(e) {
    setSwipe(true);
    setSwipePositionStart(e.touches[0].screenX);
  }
  function checkSwipe(e) {
    setSwipePositionEnd(e.touches[0].screenX);
  }
  function doSwipe() {
    if (swipe && swipePositionStart !== 0 && swipePositionEnd !== 0 && swipePositionStart !== swipePositionEnd) {
      const move = Math.abs(swipePositionStart) - Math.abs(swipePositionEnd);
      if (Math.abs(move) > 50 && !timePickerActive) {
        setEditType("");
        setIsEditTime(false);
        setBreakTime(false);
        setTotalTime(0);
        // minimum swipe distance to activate action
        if (move > 0) {
          // swipe right = render area[current-1]
          const nextDay = addDays(dateShown, 1);
          setDateShown(nextDay);
          history.push({ pathname: "/hours/day", hash: toISOLocaleString(nextDay) });
        } else if (move < 0) {
          // swipe left = render area[current+1]
          const prevDay = addDays(dateShown, -1);
          setDateShown(prevDay);
          history.push({ pathname: "/hours/day", hash: toISOLocaleString(prevDay) });
        }
      }
    }
    setSwipePositionStart(0);
    setSwipePositionEnd(0);
    setSwipe(false);
  }
  function dtHours(totalMinutes, is7thDay = false) {
    if (allClockTimes?.Holiday || allClockTimes?.Holiday === 0) {
      setDT(totalMinutes / 60);
    } else if (is7thDay) {
      setDT(totalMinutes / 60);
    } else {
      setDT(0);
    }
  }
  function otHours(totalMinutes, is6thDay = false) {
    if (allClockTimes?.Holiday || allClockTimes?.Holiday === 0) {
      // hours worked on a holiday is DT, (total-holiday hrs), note unpaid holidays can have 0 hours which is falsy
      setOT(0);
    } else if (is6thDay) {
      setOT(totalMinutes / 60);
    } else {
      if (Auth?.user?.appSettings?.ft) {
        if (totalMinutes > 8 * 60) {
          setOT(totalMinutes / 60 - 8);
        } else setOT(0);
      } else {
        if (totalMinutes > 5 * 60) {
          setOT(totalMinutes / 60 - 5);
        } else setOT(0);
      }
    }
  }
  function stHours(totalMinutes) {
    if (allClockTimes?.Holiday || allClockTimes?.Holiday === 0 || allClockTimes?.PTO) {
      // hours worked on holiday is DT
      setST(0);
    } else {
      if (Auth?.user?.appSettings?.ft) {
        if (totalMinutes > 8 * 60) {
          setST(8);
        } else setST(totalMinutes / 60);
      } else {
        if (totalMinutes > 5 * 60) {
          setST(5);
        } else setST(totalMinutes / 60);
      }
    }
  }
  function deleteClockTime() {
    //TODO: if editType is clockIn or breakIn & clockOut/breakOut exists => then notify that out time must be deleted first
    if ((editType === "ClockIn" && allClockTimes?.ClockOut) || (editType === "BreakIn" && allClockTimes?.BreakOut)) {
      Auth.activateSnackBar(
        `Cant Delete "${editType}" Time! Try deleting your ${editType === "ClockIn" ? '"Clock Out" Time' : '"Break Out" Time'} first, or edit your existing ${editType}`,
        "alert"
      );
    } else {
      // clock in type string and date from state to delete entry in firebase
      db.ref(`hours/${Auth.user.uid}/${dateShown.toDateString()}/${editType}`).set(null, function (e) {
        e && console.log(e);
      });
      // update allClockTimes Obj in state
      const clockTimesCopy = { ...allClockTimes };
      delete clockTimesCopy[editType];
      setAllClockTimes(clockTimesCopy);
      // reset editType and isEditTime to default
      setTotalTime(0);
    }
    setEditType("");
    setIsEditTime(false);
  }
  function todayStyle() {
    if (isToday(dateShown)) {
      return { boxShadow: "inset 0 0 1px 2px var(--alert)" };
    } else return null;
  }
  function checkIsLate(start = startTime, clockIn = allClockTimes?.ClockIn) {
    if (start && start !== "N/A" && start !== "Holiday" && clockIn) {
      const clockHour = timeStringToMilitaryHours(clockIn);
      const startHour = edit ? timeStringToMilitaryHours(edit) : timeStringToMilitaryHours(start);
      const clockMins = parseInt(clockIn.substr(-5, 2));
      const startMins = edit ? parseInt(edit.substr(-5, 2)) : parseInt(start.substr(-5, 2));
      if (
        ((clockHour > startHour || (clockHour === startHour && clockMins > startMins)) && Auth.user?.appSettings?.shift !== "p" && Auth.user?.appSettings?.shift !== "n") ||
        (Auth.user?.appSettings?.shift === "p" && clockHour <= 16 && (clockHour > startHour || (clockHour === startHour && clockMins > startMins))) ||
        (Auth.user?.appSettings?.shift === "n" && (clockHour <= 8 || clockHour > startHour || (clockHour === startHour && clockMins > startMins)))
        //night shift clock in late if after midnight (double shifting)
        //preload clock in not late if before midnight
      ) {
        setShowAsterisk(true);
      } else setShowAsterisk(false);
    } else {
      setShowAsterisk(false);
    }
  }
  function addPTO() {
    // add vacation hours into saturday aka week ending date. 20hrs=pt, 40hrs=ft ()
    // 1st week of vacation gets extra hours ie. 25hrs=pt, 48hrs=ft
    const today = dateShown || checkDateHash() || new Date();
    const amountInHours = Auth.user?.appSettings?.ft ? 8 : 4;
    setPto(amountInHours);
    db.ref(`hours/${Auth.user.uid}/${today.toDateString()}/PTO`).set(amountInHours, function (e) {
      e && console.log(e);
    });
  }
  function deletePTO(e) {
    e.stopPropagation();
    //TODO: modal are you sure to remove
    const today = dateShown || checkDateHash() || new Date();
    db.ref(`hours/${Auth.user.uid}/${today.toDateString()}/PTO`).set(null, function (e) {
      e && console.log(e);
    });
    setPto(0);
  }
  function fetchHolidays(today) {
    //TODO: only add paid holiday hours in if the user has reached seniority date of 1 year
    // check if there is a holiday for this day
    db.ref(`holidays/${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`)
      .once("value")
      .then(function (snapshot) {
        if (snapshot.val()) {
          // show holiday name
          setHoliday(snapshot.val());
          // check if holiday doesn't already exist in user clockTimes
          db.ref(`hours/${Auth.user.uid}/${today.toDateString()}/Holiday`)
            .once("value")
            .then(function (snapshot2) {
              const amountInHours = snapshot.val() === "Christmas Eve" ? 0 : Auth.user?.appSettings?.ft ? 8 : 4;
              // add holiday pay horus to user clockTimes . note christmas eve is unpaid holiday
              if (!snapshot2.val()) {
                db.ref(`hours/${Auth.user.uid}/${today.toDateString()}/Holiday`).set(amountInHours, function (e) {
                  e && console.log(e);
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          setHoliday(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  return (
    <div className="clock-times" onTouchStart={(e) => startSwipe(e)} onTouchEnd={doSwipe} onTouchMove={(e) => checkSwipe(e)}>
      <GModal content={modalContent} show={showModal} setShow={setShowModal} />
      <div className="hour-nav">
        <ButtonGroup size="small" aria-label="date navigation buttons" style={{ marginTop: ".5rem" }}>
          <NavButton style={{ paddingRight: "1rem" }} onClick={() => changeDay(-1)}>
            <div className="hour-nav-btn-txt-container">
              <ChevronLeft style={{ fontSize: "2rem", marginBottom: "-.75rem" }} />
              <div>{addDays(dateShown, -1).toDateString().substr(0, 11)}</div>
            </div>
          </NavButton>
          <NavButton id="active-shift-nav" style={todayStyle()} onClick={() => changeDay(0)}>
            <div className="hour-nav-btn-txt-container">
              <CalendarToday style={{ fontSize: "1.5rem", marginBottom: "-.5rem" }} />
              <div>{isToday(dateShown) ? "Today" : dateShown.toDateString().substr(0, 11)}</div>
            </div>
          </NavButton>
          <NavButton style={{ paddingLeft: "1rem" }} onClick={() => changeDay(1)}>
            <div className="hour-nav-btn-txt-container">
              <ChevronRight style={{ fontSize: "2rem", marginBottom: "-.75rem" }} />
              <div>{addDays(dateShown, 1).toDateString().substr(0, 11)}</div>
            </div>
          </NavButton>
        </ButtonGroup>
      </div>
      {!isLoading && (
        <div className="hours-container">
          {timePickerActive && startTime && startTime !== "N/A" && (
            <div className="add-week-overlay" style={{ right: "2rem", width: "max-content" }}>
              Todays Start Time: {startTime}
            </div>
          )}
          <div className="highlighted-text" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <span>Scheduled Start Time: {startTime}</span>
            {startTime !== "N/A" && startTime !== "Holiday" && !allClockTimes?.PTO && !allClockTimes?.ClockIn && (
              <Tooltip title="Clock In at your start time.">
                <button
                  tabIndex={0}
                  aria-label="clock in at your start time"
                  style={{ background: "var(--ups-brown)", color: "var(--ups-gold)", borderRadius: ".4rem", marginLeft: ".25rem", marginTop: "-.25rem" }}
                  onClick={() => ClockIn(dateStrToDateObj(dateShown, startTime), "ClockIn")}
                >
                  <AddAlarm />
                </button>
              </Tooltip>
            )}
          </div>
          {edit && <div className="edit-absolute-box">* Original Scheduled Time: {edit}</div>}
          {isEditTime && <div style={{ marginTop: ".5rem" }}>Change your {editType} time</div>}
          {isEditTime && (
            <div className="time-input-box">
              <MuiPickersUtilsProvider disableStrictModeCompat utils={DateFnsUtils}>
                <TimePicker
                  onClose={() => setTimePickerActive(false)}
                  onOpen={() => setTimePickerActive(true)}
                  label={editType}
                  inputVariant="outlined"
                  minutesStep={1}
                  value={new Date(`${dateShown.toDateString()} ${timeStringToMilitaryHours(allClockTimes[editType])}:${allClockTimes[editType].substr(-5, 2)}`)}
                  onChange={(value) => ClockIn(value, editType)}
                ></TimePicker>
              </MuiPickersUtilsProvider>
            </div>
          )}
          {isEditTime && (
            <div style={{ marginBottom: ".5rem", display: "flex", gap: "1rem" }}>
              <MyButton style={{ color: "var(--alert)" }} onClick={deleteClockTime}>
                Delete Time
                <Delete />
              </MyButton>
              <MyButton onClick={cancelEdit}>
                Back <Undo />
              </MyButton>
            </div>
          )}
          {!isEditTime && !allClockTimes?.ClockIn && !allClockTimes?.PTO && typeof allClockTimes?.Holiday != "number" && <div className="warning-text">Please add clock in times</div>}
          {!isEditTime && allClockTimes?.ClockIn && (
            <div className="text-container" onClick={() => toggleEdit("ClockIn")}>
              <div>Clocked In:</div>
              <SimpleButton className="hourTracker-time">
                {showAsterisk && (
                  <Tooltip title="late">
                    <span style={{ color: "red" }}>*</span>
                  </Tooltip>
                )}
                {allClockTimes?.ClockIn}
              </SimpleButton>
            </div>
          )}
          {!isEditTime && allClockTimes?.BreakIn && (
            <div className="text-container" onClick={() => toggleEdit("BreakIn")}>
              <div>Break Start: </div>
              <SimpleButton className="hourTracker-time">{allClockTimes?.BreakIn}</SimpleButton>
            </div>
          )}
          {!isEditTime && allClockTimes?.BreakOut && (
            <div className="text-container" onClick={() => toggleEdit("BreakOut")}>
              <div>Break End: </div>
              <SimpleButton className="hourTracker-time">{allClockTimes?.BreakOut}</SimpleButton>
            </div>
          )}
          {!isEditTime && !allClockTimes?.BreakIn && allClockTimes?.ClockOut && !breakTime && (
            <MyButton
              onClick={() => {
                setShiftState(1);
                setBreakTime(true);
              }}
              style={{ marginTop: "-.75rem", marginBottom: "-.5rem", justifySelf: "flex-end", marginRight: "2rem" }}
            >
              add break
            </MyButton>
          )}
          {!isEditTime && allClockTimes?.ClockOut && (
            <div className="text-container" onClick={() => toggleEdit("ClockOut")}>
              <div>Clocked Out: </div>
              <SimpleButton className="hourTracker-time">{allClockTimes?.ClockOut}</SimpleButton>
            </div>
          )}
          {!isEditTime && allClockTimes?.Holiday > 0 && holiday !== "Christmas Eve" && (
            <div className="text-container" style={{ color: "var(--success)" }}>
              <div>Holiday Pay: </div>
              <div className="hourTracker-time">{allClockTimes?.Holiday} hrs</div>
            </div>
          )}
          {!isEditTime && allClockTimes?.Holiday === 0 && holiday === "Christmas Eve" && (
            <div className="text-container">
              <div>UnPaid Holiday: </div>
              <div className="hourTracker-time">{holiday}</div>
            </div>
          )}
          {!isEditTime && shiftState <= 3 && shiftState > 0 && !allClockTimes?.ClockOut && <div className="warning-text">Please Clock Out</div>}
          {!isEditTime && shiftState < 4 && !allClockTimes?.ClockOut && (allClockTimes?.BreakOut || !allClockTimes?.BreakIn) && !allClockTimes?.PTO && (
            <div className="time-input-box">
              <MuiPickersUtilsProvider disableStrictModeCompat utils={DateFnsUtils}>
                <TimePicker
                  style={{ background: "var(--ups-gold)", borderRadius: ".4rem" }}
                  label={shiftState > 0 ? "CLOCK OUT AT:" : "CLOCK IN AT:"}
                  onClose={() => setTimePickerActive(false)}
                  onOpen={() => setTimePickerActive(true)}
                  inputVariant="filled"
                  minutesStep={1}
                  value={
                    (isToday(dateShown) || Auth.user?.appSettings?.shift === "n") && isAfter(new Date(), dateShown) && isAfter(addHours(dateShown, 8), new Date())
                      ? new Date()
                      : dateShown
                  }
                  onChange={(value) => ClockIn(value, shiftState > 0 ? "ClockOut" : "ClockIn")}
                ></TimePicker>
                <MyButton
                  style={{ position: "absolute", background: "var(--ups-dark-brown)", color: "var(--ups-gold)", marginRight: ".25rem" }}
                  onClick={() =>
                    ClockIn(
                      (isToday(dateShown) || Auth.user?.appSettings?.shift === "n") && isAfter(new Date(), dateShown) && isAfter(addHours(dateShown, 8), new Date())
                        ? new Date()
                        : dateShown,
                      shiftState > 0 ? "ClockOut" : "ClockIn"
                    )
                  }
                >
                  <AddAlarm />
                </MyButton>
              </MuiPickersUtilsProvider>
            </div>
          )}
          {!isEditTime && shiftState < 4 && !allClockTimes?.ClockIn && pto === 0 && !allClockTimes.Holiday && (
            <Tooltip title="Add Paid Time Off to today.">
              <div className="absolute-bottom-right" onClick={addPTO} onKeyDown={addPTO} tabIndex={0} aria-label="add pto button">
                <AlarmAdd />
                <span>PTO</span>
              </div>
            </Tooltip>
          )}
          {!isEditTime && shiftState < 4 && !allClockTimes?.ClockIn && pto !== 0 && (
            <div className="time-input-box" style={{ position: "relative", minHeight: "5rem", width: "100%", padding: 0, justifyContent: "center" }}>
              <div>Paid Time Off: {pto} hrs</div>
              <Tooltip title="Undo Paid Time Off from today">
                <div className="absolute-bottom-right" style={{ borderBottomLeftRadius: 0 }} onClick={deletePTO} onKeyDown={addPTO} tabIndex={0} aria-label="undo pto button">
                  <Undo />
                  <span>PTO</span>
                </div>
              </Tooltip>
            </div>
          )}
          {!isEditTime &&
            ((shiftState > 0 && shiftState < 3) || (allClockTimes?.ClockOut && allClockTimes.BreakIn && !allClockTimes.BreakOut)) &&
            (breakTime || allClockTimes?.BreakIn ? (
              <div className="time-input-box">
                <MuiPickersUtilsProvider disableStrictModeCompat utils={DateFnsUtils}>
                  <TimePicker
                    onClose={() => setTimePickerActive(false)}
                    onOpen={() => setTimePickerActive(true)}
                    style={{ background: "var(--ups-gold)", borderRadius: ".4rem" }}
                    label={shiftState > 1 ? "END BREAK AT:" : "START BREAK AT:"}
                    inputVariant="filled"
                    minutesStep={1}
                    value={isToday(dateShown) ? new Date() : dateShown}
                    onChange={(value) => ClockIn(value, shiftState > 1 ? "BreakOut" : "BreakIn")}
                  ></TimePicker>
                  <MyButton
                    style={{ position: "absolute", background: "var(--ups-dark-brown)", color: "var(--ups-gold)", marginRight: ".25rem" }}
                    onClick={() => ClockIn(isToday(dateShown) ? new Date() : dateShown, shiftState > 1 ? "BreakOut" : "BreakIn")}
                  >
                    <AddAlarm />
                  </MyButton>
                </MuiPickersUtilsProvider>
              </div>
            ) : (
              <MyButton style={{ marginBottom: ".5rem" }} onClick={() => setBreakTime(true)}>
                Go On Break
              </MyButton>
            ))}
          {parseInt(totalTime) !== 0 && (
            <div className="highlighted-text">
              {allClockTimes?.ClockOut || allClockTimes.BreakIn ? "Total: " : "Current: "}
              {Math.floor(totalTime / 60) + " hrs " + (totalTime % 60) + " mins (" + ((totalTime % 60) / 60 + Math.floor(totalTime / 60)).toFixed(2) + " hrs)"}
            </div>
          )}
          {parseInt(totalTime) !== 0 && (
            <div className="highlighted-text" style={{ display: "flex", justifyContent: "space-around", paddingTop: 0 }}>
              <span style={allClockTimes?.Holiday ? { color: "var(--success)" } : null}>ST: {st.toFixed(2)} hrs</span>
              {(allClockTimes?.Holiday || allClockTimes?.Holiday === 0 || (Object.keys(weekClockTimes).length >= 7 && isSaturday(dateShown))) && allClockTimes?.ClockOut ? (
                <span style={{ color: "var(--info)" }}>DT: {dt.toFixed(2)} hrs</span>
              ) : (
                <span style={{ color: "var(--success)" }}>OT: {ot.toFixed(2)} hrs</span>
              )}
            </div>
          )}
          {parseInt(totalTime) !== 0 && (
            <div className="highlighted-text" style={{ display: "flex", justifyContent: "space-around", paddingTop: 0 }}>
              <span style={allClockTimes?.Holiday ? { color: "var(--success)" } : null}>ST: ${(st * payRate).toFixed(2)}</span>
              {(allClockTimes?.Holiday || allClockTimes?.Holiday === 0 || (Object.keys(weekClockTimes).length >= 7 && isSaturday(dateShown))) && allClockTimes?.ClockOut ? (
                <span style={{ color: "var(--info)" }}>DT: ${(dt * payRate * 2).toFixed(2)}</span>
              ) : (
                <span style={{ color: "var(--success)" }}>OT: ${(ot * payRate * 1.5).toFixed(2)}</span>
              )}
            </div>
          )}
          {allClockTimes?.Vacation && (
            <div className="highlighted-text" style={{ display: "flex", justifyContent: "space-around", paddingTop: 0 }}>
              <span style={{ color: "var(--success)", marginTop: ".5rem" }}>Vacation Week: {allClockTimes?.Vacation} hrs</span>
            </div>
          )}
        </div>
      )}
      <MyButton onClick={() => history.push({ pathname: "/hours/week", hash: toISOLocaleString(endOfWeek(dateShown)) })}>Back to Week</MyButton>
    </div>
  );
};

export default HoursDaily;
