import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./App";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import "firebase/database";
import addMinutes from "date-fns/addMinutes";
import { hoursToAmPmFormat } from "./Utils";
import { addHours, format, isAfter, isFriday, isPast, isToday, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

const Day = (props) => {
  const Auth = useContext(AuthContext);
  const db = firebase.database();
  const [startTime, setStartTime] = useState(props.details.time);
  const [isHoliday, setIsHoliday] = useState(false);
  const [classNames, setClassNames] = useState("");
  const [day, setDay] = useState(parseISO(`${props.details.date.year}-${props.details.date.month}-${props.details.date.day}`));
  const history = useHistory();
  const [edit, setEdit] = useState("");
  const [display, setDisplay] = useState("");
  useEffect(() => {
    if (props?.details?.date) {
      setDay(parseISO(`${props.details.date.year}-${props.details.date.month}-${props.details.date.day}`));
      setStartTime(userStartTime(props.details.time));
      fetchHolidays();
      highlightToday(parseISO(`${props.details.date.year}-${props.details.date.month}-${props.details.date.day}`));
      fetchEdits();
      hideUnSetDays();
    }
    return () => {
      db.ref(`holidays/`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.details.time, props.details.date.day, Auth?.user?.uid, props.shift]);
  useEffect(() => {
    if (props.details.time) {
      highlightToday(parseISO(`${props.details.date.year}-${props.details.date.month}-${props.details.date.day}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHoliday]);
  function userStartTime(timeString) {
    if (timeString === "OFF" || timeString === "OFF2" || "") {
      return "OFF";
    } else {
      const [hourStr, minStr] = timeString.slice(0, -2).split(":");
      const tempTime = new Date();
      if (timeString.slice(-2) === "AM" && parseInt(hourStr) === 12) {
        tempTime.setHours(0);
      } else if (timeString.slice(-2) === "AM" || (timeString.slice(-2) === "PM" && parseInt(hourStr) === 12)) {
        tempTime.setHours(parseInt(hourStr));
      } else {
        tempTime.setHours(parseInt(hourStr) + 12);
      }
      tempTime.setMinutes(parseInt(minStr));
      if (Auth?.user?.appSettings?.offset && Auth?.user?.appSettings?.offset !== "" && Auth?.user?.appSettings?.shift === props.shift && timeString.length > 5) {
        // timeString.length>5 => to only parse startTimes with a time set, don't calculate offset on days "OFF"
        const result = addMinutes(
          tempTime,
          isFriday(day) && Auth.user.appSettings.offset === -15 && Auth.user.appSettings?.shift === "t"
            ? parseInt(Auth.user.appSettings.offset) * 2
            : parseInt(Auth.user.appSettings.offset)
        );
        //userStartTime=startTime-offset, unless twilight and mon-thur, then offset is half.
        return hoursToAmPmFormat(result);
      } else {
        return timeString;
      }
    }
  }
  function fetchHolidays() {
    const today = new Date();
    today.setFullYear(props.details.date.year);
    today.setMonth(props.details.date.month - 1);
    today.setDate(props.details.date.day);
    //console.log(`fetching holiday data firebase for date: ${today.toDateString()}`);
    db.ref(`holidays/${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`)
      .once("value")
      .then(function (snapshot) {
        if (snapshot.val()) {
          setIsHoliday(true);
        } else {
          setIsHoliday(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  async function fetchEdits() {
    // check database has an entry in edits that matches the current start time and return the previous time
    // example query "3/25/2021, 11:30:00 AM"
    if (props.details.time.length > 4) {
      // only check if there is a new scheduled time and not for anything set to "OFF"
      // format date of day component prop info into string to match query in db.
      const query = `${props.details.date.month.replace("0", "")}/${props.details.date.day.replace("0", "")}/${props.details.date.year}, ${props.details.time.split(" ")[0]}:00 ${
        props.details.time.split(" ")[1]
      }`;
      db.ref(`edits/`)
        .orderByChild("newValue")
        .equalTo(query)
        .once("value")
        .then(function (snapshot) {
          const result = snapshot?.val() || {};
          if (Object.keys(result).length === 0) {
            setEdit("");
          } else {
            Object.keys(result).forEach((key) => {
              if (result[key]?.prevValue && !result[key]?.hidden) {
                const editStr = result[key]?.prevValue.length > 4 ? result[key]?.prevValue.split(",")[1].trim().replace(":00", "") : result[key]?.prevValue;
                setEdit(editStr);
              }
            });
          }
        });
    } else {
      setEdit("");
    }
  }
  function hideUnSetDays() {
    if (props.displayAll) {
      // if startTimes is showing all shifts then don't hide unused days
      setDisplay("");
    } else if ((props.index === 0 || props.index === 5) && !props.details.time && !Auth.user.admin) {
      // hide sundays/fridays when no start time is set unless user is admin
      setDisplay("none");
    } else if (props.index === 6 && props.shift !== "p") {
      // hide saturday unless shift is Preload
      setDisplay("none");
    } else {
      // revert to default (show it onscreen) if other conditions are not met
      setDisplay("");
    }
  }
  function highlightToday(thisDay) {
    if (isToday(thisDay) && isAfter(addHours(thisDay, 6), new Date()) && isHoliday && props.details.time === "OFF") {
      setClassNames(`past-holiday icon`);
      return `past-holiday icon`;
    } else if (
      isToday(thisDay) &&
      isAfter(
        addHours(props.shift === "d" ? thisDay.setHours(13) : props.shift === "t" ? thisDay.setHours(17) : props.shift === "n" ? thisDay.setHours(23) : thisDay.setHours(4), 4),
        new Date()
      ) &&
      props.details.time === "OFF2"
    ) {
      setClassNames("past snow-day icon");
      return "past snow-day icon";
    } else if (isToday(thisDay) && isAfter(new Date(), addHours(thisDay, 6))) {
      setClassNames(`past icon`);
      return `past icon`;
    } else if (isToday(thisDay) && isHoliday && props.details.time === "OFF") {
      setClassNames(`today holiday icon`);
      return `today holiday icon`;
    } else if (isToday(thisDay) && props.details.time === "OFF2") {
      setClassNames("today snow-day icon");
      return "today snow-day icon";
    } else if (isToday(thisDay)) {
      setClassNames(`today icon`);
      return `today icon`;
    } else if (isPast(thisDay) && isHoliday && props.details.time === "OFF") {
      setClassNames(`past-holiday icon`);
      return `past-holiday icon`;
    } else if (isPast(thisDay)) {
      setClassNames(`past icon`);
      return `past icon`;
    } else if (isHoliday && props.details.time === "OFF") {
      setClassNames("holiday icon");
      return "holiday icon";
    } else if (props.details.time === "OFF2") {
      setClassNames("snow-day icon");
      return "snow-day icon";
    } else {
      setClassNames(`icon`);
      return `icon`;
    }
  }
  function toggleEdit() {
    // admin goto edit mode OR user goto hourTracker for that day
    if (Auth.user?.admin && Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(props.shift)]) {
      props.toggle(props.index, props.shift);
    } else if (!Auth.user?.admin && !Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(props.shift)]) {
      history.push(`/hours/day#${props.details.date.year}-${props.details.date.month}-${props.details.date.day}`);
    }
  }
  function getPermissionIndex(shiftStr) {
    let shiftIndex = "Day";
    if (shiftStr === "t") {
      shiftIndex = "Twilight";
    } else if (shiftStr === "n") {
      shiftIndex = "Midnight";
    } else if (shiftStr === "p") {
      shiftIndex = "Preload";
    }
    return shiftIndex;
  }
  return (
    <time
      data="2019-09-09"
      className={classNames || highlightToday(parseISO(`${props.details.date.year}-${props.details.date.month}-${props.details.date.day}`))}
      style={{ display: display }}
      onClick={() => toggleEdit()}
    >
      <div className="day-overlay">
        {classNames.includes("past") && (
          <>
            <div className="strikeThroughLine"></div>
            <div className="strikeThroughLine invert"></div>
          </>
        )}
        <div className="top-bar">
          <div>{format(day, "E")}</div>
          <div>{format(day, "MMM")}</div>
        </div>
        <div className="date">{day.getDate()}</div>
        {edit.length > 0 ? (
          <div id="overlay-offset">*Previously {edit}</div>
        ) : Auth?.user?.appSettings?.shift === props.shift && Auth?.user?.appSettings?.offset && props.details.time && props.details.time.length >= 4 ? (
          <div id="overlay-offset">{props.details.time} Sort</div>
        ) : null}
        <div className="time" style={startTime ? (classNames.includes("past") ? { color: "var(--ups-brown)" } : { color: "var(--success)" }) : { color: "var(--info)" }}>
          {startTime || "OFF"}
        </div>
        {isHoliday && <div className="holiday-text">Holiday</div>}
        {!isHoliday && props.details.time === "OFF2" && <div className="snow-day-text">Cancelled</div>}
        {isHoliday && props.details.time !== "OFF" && <div className="holiday-text2">Double OT $</div>}
      </div>
    </time>
  );
};
Day.propTypes = {
  shift: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  toggle: PropTypes.func.isRequired,
  details: PropTypes.shape({
    date: PropTypes.shape({
      year: PropTypes.string.isRequired,
      month: PropTypes.string.isRequired,
      day: PropTypes.string.isRequired,
    }),
    time: PropTypes.string.isRequired,
  }),
};
export default Day;
