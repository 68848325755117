import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import "firebase/database";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Modal, Fade, Backdrop, Tooltip } from "@material-ui/core";
import { ArrowBack, EventBusy, Restore } from "@material-ui/icons";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { timeStringToMilitaryHours, hoursToAmPmFormat } from "./Utils";
import { isSaturday, format, isValid, isSunday } from "date-fns";
import { AuthContext } from "./App";
const useStyles = makeStyles(() => ({
  card: {
    background: "var(--ups-gold)",
    borderRadius: ".4rem",
    color: "var(--ups-dark-brown)",
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    justifySelf: "center",
    height: "8rem",
    width: "8rem",
  },
  button: {
    background: "var(--ups-brown)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    padding: "5px 5px",
    "align-self": "center",
    "&:hover": {
      transform: "scale(1.15)",
      background: "var(--ups-dark-brown)",
    },
  },
  flexRowAround: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "center",
    width: "100%",
  },
  inputForm: {
    width: "6rem",
  },
  title: {
    fontWeight: 700,
    fontSize: "1.1rem",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "var(--ups-gold)",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.4)",
    padding: ".75rem",
    borderRadius: ".4rem",
    color: "var(--ups-dark-brown)",
  },
}));
const EditDay = (props) => {
  const classes = useStyles();
  const [startTime, setStartTime] = useState(setDefaultTime());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const db = firebase.database();
  const date = props.details.date;
  const today = setToday();
  const Auth = useContext(AuthContext);
  function setToday() {
    const today = new Date();
    today.setDate(date.day);
    today.setFullYear(date.year);
    today.setMonth(date.month - 1);
    return today;
  }
  function setDefaultTime() {
    const selectedDate = props.details;
    const myDate = new Date(`${props.details.date.year}-${props.details.date.month}-${props.details.date.day}`);
    myDate.setDate(props.details.date.day);
    if (isValid(myDate)) {
      if (props.details?.time && props.details?.time.length >= 5 && props.details?.time !== "OFF" && props.details?.time !== "OFF2") {
        myDate.setHours(timeStringToMilitaryHours(selectedDate.time));
        myDate.setMinutes(selectedDate.time.substr(-5, 2));
      } else {
        if (isSaturday(myDate) && props.shift !== "p") {
          // saturday shift
          myDate.setHours(14);
          myDate.setMinutes(0);
        } else if (isSaturday(myDate) && props.shift === "p") {
          // saturday shift for preload
          myDate.setHours(4);
          myDate.setMinutes(0);
        } else if (!isSunday(myDate) && props.shift === "t") {
          // twilight
          myDate.setHours(5);
          myDate.setMinutes(30);
        } else if (props.shift === "d") {
          // friday for day
          myDate.setHours(11);
          myDate.setMinutes(0);
        } else {
          // sunday shift for twi/mid
          myDate.setHours(8);
          myDate.setMinutes(0);
        }
      }
      return myDate;
    } else {
      return new Date();
    }
  }
  function setNewStartTime(newTime) {
    // update new time in database
    db.ref(`weeks/${props.we}/${props.shift}/${props.index}/time`).set(hoursToAmPmFormat(newTime), function (e) {
      e && console.log(e);
    });
    // log change into database
    const prevValue = props.details.time.length > 4 ? startTime.toLocaleString() : props.details.time;
    // prevValue possible values, "OFF", "OFF2", "6/4/2021, 10:30 AM"
    logChange(prevValue, newTime.toLocaleString(), "Edit StartTime", Auth?.user, props?.shift, `${props.details.date.year}-${props.details.date.month}-${props.details.date.day}`);
    // set new value in app
    setStartTime(newTime);
    //alert user that the time has been changed
    Auth.activateSnackBar(`Start time changed to ${newTime}.`, "success", 2000);
    // return back to start time view
    props.toggle(props.index, props.shift);
  }
  function logChange(prevVal, newVal, type, user = "unknown", shift = "unknown", date) {
    // log record of change in database
    const timeNow = new Date();
    const nowStr = timeNow.toString().split("(")[0];
    db.ref(`edits/${nowStr}`).set(
      { prevValue: prevVal, newValue: newVal, shift: shift, type: type, userInfo: { displayName: user.displayName, uid: user.uid }, reverted: false, hidden: false, day: date },
      function (e) {
        e && console.log(e);
      }
    );
  }
  function cancelShift() {
    db.ref(`weeks/${props.we}/${props.shift}/${props.index}/time`).set("OFF2", function (e) {
      e && console.log(e);
    });
    logChange("OFF", "OFF2", "Cancel Scheduled Shift", Auth?.user, props?.shift, `${props.details.date.year}-${props.details.date.month}-${props.details.date.day}`);
    Auth.activateSnackBar("Shift set to cancelled.", "success", 2000);
    props.toggle(props.index, props.shift);
  }
  function undoShift() {
    db.ref(`weeks/${props.we}/${props.shift}/${props.index}/time`).set("OFF", function (e) {
      e && console.log(e);
    });
    logChange(startTime.toLocaleString(), "OFF", "Undo Shift", Auth?.user, props?.shift, `${props.details.date.year}-${props.details.date.month}-${props.details.date.day}`);
    Auth.activateSnackBar("Previously scheduled shift is now set as Unscheduled!", "success", 2000);
    props.toggle(props.index, props.shift);
  }
  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    props.toggle(props.index, props.shift);
  };

  return (
    <div className={classes.card}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Are You Sure?</h2>
            <p id="transition-modal-description">Cancel the scheduled shift for the day</p>
            <Button className={classes.button} style={{ color: "var(--ups-gold)" }} onClick={handleClose}>
              <ArrowBack />
              NO, Go Back
            </Button>
            <Button className={classes.button} style={{ color: "var(--ups-gold)" }} onClick={cancelShift}>
              Yes, Cancel
            </Button>
          </div>
        </Fade>
      </Modal>
      <div className={classes.title}>{format(today, "E MMM do")}</div>
      <div className={classes.inputForm}>
        <MuiPickersUtilsProvider disableStrictModeCompat utils={DateFnsUtils}>
          <TimePicker inputVariant="outlined" label="Edit Time" minutesStep={5} value={startTime} onChange={(value) => setNewStartTime(value)}></TimePicker>
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.flexRowAround}>
        <Button className={classes.button} onClick={() => props.toggle(props.index, props.shift)}>
          <ArrowBack />
        </Button>
        {props?.details?.time.length > 5 && (
          <Button className={classes.button} onClick={undoShift} style={{ background: "var(--info)" }}>
            <Tooltip title="Restore to scheduled OFF">
              <Restore />
            </Tooltip>
          </Button>
        )}
        {props?.details?.time === "OFF" && (
          <Button className={classes.button} style={{ background: "var(--alert)" }}>
            <Tooltip title="Cancel the Scheduled Shift. ie. Snow Day">
              <EventBusy onClick={handleOpen} />
            </Tooltip>
          </Button>
        )}
      </div>
    </div>
  );
};
EditDay.propTypes = {
  toggle: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  shift: PropTypes.string.isRequired,
  we: PropTypes.string.isRequired,
  details: PropTypes.shape({
    date: PropTypes.shape({
      year: PropTypes.string.isRequired,
      month: PropTypes.string.isRequired,
      day: PropTypes.string.isRequired,
    }),
    time: PropTypes.string.isRequired,
  }),
};
export default EditDay;
