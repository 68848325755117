import React, { useState, useContext, useEffect } from "react";
import Day from "./Day.js";
import AddWeek from "./AddWeek";
import EditDay from "./EditDay.js";
import { AuthContext } from "./App";
import firebase from "firebase/app";
import "firebase/database";
import { styled } from "@material-ui/core/styles";
import { Button, ButtonGroup, Tooltip } from "@material-ui/core";
import { AddBox, Share, NavigateNext, NavigateBefore, ErrorOutline } from "@material-ui/icons";
import Spinner from "./Spinner";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { isThisWeek, format, addWeeks, differenceInWeeks, endOfWeek, parseISO, isValid, formatDistanceStrict } from "date-fns";
const MyButton = styled(Button)({
  background: "var(--ups-gold)",
  border: 0,
  borderRadius: 3,
  padding: ".25rem .6rem",
  fontSize: ".75rem",
  color: "Black",
  fontWeight: "bolder",
  "&:hover": {
    background: "var(--ups-yellow)",
  },
});
const MyButton2 = styled(Button)({
  background: "var(--ups-gold)",
  width: "8rem",
  height: "8rem",
  border: 0,
  borderRadius: ".4rem",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  color: "var(--ups-dark-brown)",
  fontWeight: "bolder",
  "&:hover": {
    background: "var(--ups-gold)",
  },
});
const StartTimes = (props) => {
  const [currentWeek, setCurrentWeek] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showAddWeek, setShowAddWeek] = useState(false);
  const [weeksFromNow, setWeeksFromNow] = useState(0);
  const [showAddWeekOverlay, setShowAddWeekOverlay] = useState(false);
  const [showAddWeekOverlayShifts, setShowAddWeekOverlayShifts] = useState([false, false, false, false]);
  const [updateOldStartTimes, setUpdateOldStartTimes] = useState(false);
  const [editDay, setEditDay] = useState([
    [false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false],
  ]);
  const [nextWeek, setNextWeek] = useState("");
  const [prevWeek, setPrevWeek] = useState("");
  const [transitionShift, setTransitionShift] = useState(false);
  const [swipe, setSwipe] = useState(false);
  const [swipePositionStart, setSwipePositionStart] = useState(0);
  const [swipePositionEnd, setSwipePositionEnd] = useState(0);
  const history = useHistory();
  const Auth = useContext(AuthContext);
  const db = firebase.database();
  const hash = useLocation().hash;
  useEffect(() => {
    fetchCurrentWeek(hash.substr(1, hash.length) || getCurrentWeekDateEnding(0), props.shift);
    return () => {
      db.ref(`weeks/${hash.substr(1, hash.length) || getCurrentWeekDateEnding(0)}`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shift, hash, Auth?.user?.uid]);
  useEffect(() => {
    if (Object.keys(currentWeek).length > 0 && currentWeek?.[props.shift]) {
      fetchNextPrevWeek(`${currentWeek[props.shift][6].date.year}-${currentWeek[props.shift][6].date.month}-${currentWeek[props.shift][6].date.day}`, props.shift);
    } else if (Object.keys(currentWeek).length > 0) {
      fetchNextPrevWeek(
        `${currentWeek[Object.keys(currentWeek)[0]][6].date.year}-${currentWeek[Object.keys(currentWeek)[0]][6].date.month}-${currentWeek[Object.keys(currentWeek)[0]][6].date.day}`,
        props.shift
      );
    } else if (Object.keys(currentWeek).length === 0 && hash) {
      fetchNextPrevWeek(hash.substr(1, hash.length), props.shift);
    }
    return () => {
      db.ref(`weeks/`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeek]);
  useEffect(() => {
    if (props.shift === "all" && Auth.size.width < Auth.size.height) {
      // force twilight shift to show if screen is portrait orientation and all shifts was selected
      history.push("/t");
    }
    if (Auth?.size?.width && props.shift === "all") {
      scalePage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shift, Auth.size.width]);
  function getCurrentWeekDateEnding(AdditionalWeeks) {
    // calculate date on saturday of the current week based on the date today
    /*  *** using date-fns ***
    const sat = nextSaturday(new Date());
    const newSat = addWeeks(sat, AdditionalWeeks ? AdditionalWeeks : 0);
    return lightFormat(newSat, "yyyy-MM-dd"); */
    const today = new Date();
    const saturday = today.getDate() - (today.getDay() + 1) + 7 + 7 * AdditionalWeeks;
    // edge case when saturday is in the next month
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (today.getFullYear() % 4 === 0) {
      // if leap year, add leap day to feb
      daysInMonth[1] = 29;
    }
    if (saturday > daysInMonth[today.getMonth()]) {
      // if its the last week of the month, then increment month & calculate days into the next month saturday will fall on.
      if (today.getMonth() >= 11) {
        // if its the last week of december, then increment year
        return `${today.getFullYear() + 1}-01-${("0" + (saturday - daysInMonth[today.getMonth()])).slice(-2)}`;
      }
      return `${today.getFullYear()}-${("0" + (today.getMonth() + 2)).slice(-2)}-${("0" + (saturday - daysInMonth[today.getMonth()])).slice(-2)}`;
    } else {
      return `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(-2)}-${("0" + saturday).slice(-2)}`;
    }
  }
  function checkDate(input) {
    if (
      typeof input === "string" &&
      input.length === 10 &&
      input.split("-").length === 3 &&
      input.split("-")[0].length === 4 &&
      input.split("-")[1].length === 2 &&
      input.split("-")[2].length === 2
    ) {
      const date = parseISO(input);
      if (isValid(date)) {
        const saturday = endOfWeek(date);
        return saturday;
      } else {
        return;
      }
    } else {
      return;
    }
  }
  function fetchCurrentWeek(date, shift) {
    const today = new Date();
    const dateObj = checkDate(date, shift);
    if (typeof dateObj === "undefined") {
      Auth.activateSnackBar(`Date not valid, pick a new date.`, "warning");
      history.replace(`/picker#st-${shift}`);
      return;
    }
    const dateStr = format(dateObj, "yyyy-MM-dd");
    const recursionDepth = differenceInWeeks(dateObj, endOfWeek(today));
    setWeeksFromNow(recursionDepth);
    const prevWeek = endOfWeek(addWeeks(dateObj, -1));
    const prevWeekString = format(prevWeek, "yyyy-MM-dd");
    // if today is friday after 7 pm or saturday, fetch next weeks start times
    //if (today.getDay() === 6 || (today.getDay() === 5 && today.getHours() >= 19))
    // show next weeks times if time now is after last shift of the the week for the given shift type
    console.log(`fetching current week...${dateStr} ${shift}`);
    db.ref(`weeks/${dateStr}`).on("value", function (snapshot) {
      var week = (snapshot.val() && snapshot.val()) || {};
      // check if start times are found for the given shift on the specified week, or any start times found for the specified week if all shifts selected
      if (shift === "all" ? !snapshot.val() : !snapshot.val()?.[shift]) {
        // no start times found
        if (!Auth?.user?.admin && !Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(shift)] && Math.abs(recursionDepth) < 8) {
          // goto start times for the previous week
          history.replace(`/${shift}#${prevWeekString}`);
        } else {
          // no start times found for date inputted which must be over 2 months ago
          console.log(`No data found for this week: ${dateStr}`);
          if (Auth?.user?.admin && Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(shift)]) {
            fetchNextPrevWeek(dateStr, shift);
            setUpdateOldStartTimes(true);
            if (recursionDepth >= 1) {
              // weeks in the future shown w/o start times aka AddWeek shown
              Auth.activateSnackBar(`Set Start Times for ${Math.abs(recursionDepth)} Week${Math.abs(recursionDepth) > 1 ? "s" : ""} from Now.`, "warning");
            } else if (recursionDepth <= 1) {
              // weeks in the past shown w/o start times aka AddWeek shown
              Auth.activateSnackBar(`Set Start Times for ${Math.abs(recursionDepth)} Week${Math.abs(recursionDepth) > 1 ? "s" : ""} Ago.`, "warning");
            } else {
              Auth.activateSnackBar(`Start Times Need to be Updated!`, "alert");
            }
          } else {
            Auth.activateSnackBar(`Sorry, Start Times haven't Been Updated in a While.`, "warning");
          }
          setCurrentWeek(week);
          setShowAddWeekOverlay(false);
          setShowAddWeek(true);
          setTransitionShift(false);
          setIsLoading(false);
        }
      } else {
        // found start times for the given shift or found at least one shifts start times for a date with shift set to 'all'
        if (history?.location?.state) {
          setShowAddWeek(history?.location?.state?.showAddWeek);
          if (history?.location?.state?.showAddWeekOverlay) {
            setShowAddWeekOverlay(history?.location?.state?.showAddWeekOverlay);
          }
        } else {
          setShowAddWeek(false);
        }
        setCurrentWeek(week);
        setTransitionShift(false);
        setIsLoading(false);
        if (isThisWeek(dateObj)) {
          // Best Result - start Times have been set
          //clear snackbar w/ time set to 10ms
          Auth.activateSnackBar(``, "warning", 10);
          const now = new Date();
          if (
            ((shift === "d" && now.getDay() >= 4) || now.getDay() >= 5) &&
            isThisWeek(now) &&
            Auth?.user?.admin &&
            Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(shift)]
          ) {
            // friday or later in the current week allow adding next weeks time
            setShowAddWeekOverlay(true);
          } else {
            setShowAddWeekOverlay(false);
          }
        } else if (recursionDepth === 1) {
          // next week's start times shown
          Auth.activateSnackBar(`This is Next Week's Start Times.`, "warning");
        } else {
          // start times found but its not the current week
          // disabled - this error message would show even if there is a current week but your browsing old weeks while logged in as admin
          /* if (Auth?.user?.admin && Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(shift)]) {
            setShowAddWeekOverlay(false);
            Auth.activateSnackBar(`${Math.abs(recursionDepth)} Week${Math.abs(recursionDepth) > 1 ? "s" : ""} Ago. Please Update!`, "alert");
          } else { */
          Auth.activateSnackBar(`${Math.abs(recursionDepth)} Week${Math.abs(recursionDepth) > 1 ? "s" : ""} Ago`, "warning", 2000);
          //}
        }
        if (
          shift === "all" &&
          Auth.user.admin &&
          (!Object.keys(week).includes("d") || !Object.keys(week).includes("t") || !Object.keys(week).includes("n") || !Object.keys(week).includes("p"))
        ) {
          Auth.activateSnackBar(`Start Times have not been updated for All Shifts.`, "warning");
        }
        return () => {
          db.ref(`weeks/${dateStr}`).off();
        };
      }
    });
  }
  function fetchNextPrevWeek(dateStr, shift) {
    const dateObj = checkDate(dateStr);
    if (typeof dateObj === "undefined") {
      Auth.activateSnackBar(`Date not valid, pick a new date.`, "warning");
      history.replace(`/picker#st-${shift}`);
      return;
    }
    const dateObjNext = endOfWeek(addWeeks(dateObj, 1));
    const dateObjPrev = endOfWeek(addWeeks(dateObj, -1));
    const dateStrNext = format(dateObjNext, "yyyy-MM-dd");
    const dateStrPrev = format(dateObjPrev, "yyyy-MM-dd");
    const now = new Date();
    db.ref(`weeks/${dateStrNext}`).on("value", function (snapshot) {
      if (snapshot.val()?.[shift]) {
        // the specified shift has next week info assigned
        setNextWeek(dateStrNext);
        setShowAddWeekOverlay(false);
      } else if (shift === "all" && snapshot.val()) {
        // a shift already has next week info assigned
        setNextWeek(dateStrNext);
        if (
          ((shift === "d" && now.getDay() >= 4) || now.getDay() >= 5) &&
          isThisWeek(now) &&
          Auth?.user?.admin &&
          Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(shift)]
        ) {
          setShowAddWeekOverlay(true);
        } else {
          setShowAddWeekOverlay(false);
        }
        const shiftsWithNextWeek = Array.from(Object.keys(snapshot.val()));
        const tempArray = [false, false, false, false];
        shiftsWithNextWeek.forEach((shiftStr) => {
          tempArray[getShiftIndex(shiftStr)] = true;
        });
        setShowAddWeekOverlayShifts(tempArray);
      } else {
        // no shifts next week assigned
        if (
          ((shift === "d" && now.getDay() >= 4) || now.getDay() >= 5) &&
          isThisWeek(now) &&
          parseInt(formatDistanceStrict(new Date(), dateObjNext, { unit: "day" }).split(" ")[0]) <= 7 &&
          Auth?.user?.admin &&
          Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(shift)]
        ) {
          setShowAddWeekOverlay(true);
        } else {
          setShowAddWeekOverlay(false);
        }
        setShowAddWeekOverlayShifts([false, false, false, false]);
        if (Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(shift)]) {
          setNextWeek(dateStrNext);
        } else {
          setNextWeek("");
        }
      }
    });
    db.ref(`weeks/${dateStrPrev}`).on("value", function (snapshot) {
      if (shift === "all" ? snapshot.val() : snapshot.val()?.[shift]) {
        setPrevWeek(dateStrPrev);
      } else {
        if (Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(shift)]) {
          setPrevWeek(dateStrPrev);
        } else {
          setPrevWeek("");
        }
      }
    });
  }
  function getShiftIndex(shiftStr) {
    let shiftIndex = 0;
    if (shiftStr === "t") {
      shiftIndex = 1;
    } else if (shiftStr === "n") {
      shiftIndex = 2;
    } else if (shiftStr === "p") {
      shiftIndex = 3;
    }
    return shiftIndex;
  }
  function getPermissionIndex(shiftStr) {
    let shiftIndex = "Day";
    if (shiftStr === "t") {
      shiftIndex = "Twilight";
    } else if (shiftStr === "n") {
      shiftIndex = "Midnight";
    } else if (shiftStr === "p") {
      shiftIndex = "Preload";
    }
    return shiftIndex;
  }
  function toggleEdit(index, shift) {
    const editCopy = Array.from(editDay);
    editCopy[getShiftIndex(shift)][index] = !editCopy[getShiftIndex(shift)][index];
    setEditDay(editCopy);
  }
  function displayAddWeek(value) {
    setShowAddWeek(value);
  }
  function changeShift(nextShift) {
    if (props.shift !== nextShift) {
      setTransitionShift(true);
      //setNextWeek("");
      //setPrevWeek("");
      //setShowAddWeekOverlayShifts([false, false, false, false]);
      if (hash) {
        history.push(`/${nextShift}${hash}`);
      } else {
        history.push("/" + nextShift);
      }
    } else {
      history.push("/" + nextShift);
    }
  }
  function share() {
    /* 
    EMAIL example from twilio signal event
    https://calendar.google.com/calendar/render?action=TEMPLATE&text=SIGNAL%202021&dates=20211020T160000Z%2F20211022T000000Z&details=&location=San%20Francisco%2C%20Moscone%20West%2C%20San%20Francisco%2C%20CA%2094107%2C%20United%20States
    https://outlook.live.com/owa/?path=%2Fcalendar%2Faction%2Fcompose&startdt=2021-10-20T16%3A00%3A00Z&enddt=2021-10-22T00%3A00%3A00Z&subject=SIGNAL%202021&location=San%20Francisco%2C%20Moscone%20West%2C%20San%20Francisco%2C%20CA%2094107%2C%20United%20States&body=
    */
    if (navigator.canShare && props.shift !== "all") {
      // get week ending date for last element in array
      const shift = props.shift || "t";
      const weekEnding = currentWeek["t"][currentWeek["t"].length - 1]?.date;
      const satDate = new Date(`${weekEnding.year}-${weekEnding.month}-${weekEnding.day}T00:00:00`);
      let satStr = "";
      if (isValid(satDate)) {
        satStr = `${getPermissionIndex(shift)} Week Ending: `.concat(format(satDate, "MMM do"));
      }
      //filter out the days this week that don't have a scheduled shift
      const filtered = currentWeek?.[shift]
        .filter((dayObj) => {
          if (dayObj?.time && dayObj?.time.length > 4) {
            return dayObj;
          } else return null;
        })
        .map((dayObj) => {
          //transform object data to readable date string
          if (dayObj?.date?.year && dayObj?.date?.year && dayObj?.date?.year) {
            const date = new Date(`${dayObj?.date?.year}-${dayObj?.date?.month}-${dayObj?.date?.day} ${dayObj?.time}`);
            if (isValid(date)) {
              const formatted = format(date, "E h:mm aa");
              return formatted;
            } else return null;
          } else return null;
        });
      filtered.push(satStr);
      // modify url so the shift is always spelled out. ie /t => /Twilight or / => /$shift
      let url = history.location.pathname;
      if (!history.location.pathname.includes(getPermissionIndex(shift)) && !history.location.pathname.includes(`/${shift}`)) {
        url = history.location.pathname.concat(getPermissionIndex(shift));
      } else if (!history.location.pathname.includes(getPermissionIndex(shift)) && history.location.pathname.includes(`/${shift}`)) {
        url = url.substr(0, url.length - 1);
        url = url.concat(getPermissionIndex(shift));
      }
      navigator.share({ title: "Start Times for ".concat(satStr), url: url, text: filtered.join(",") });
    }
  }
  function changeStartTimes(next) {
    setEditDay([
      [false, false, false, false, false, false, false],
      [false, false, false, false, false, false, false],
      [false, false, false, false, false, false, false],
      [false, false, false, false, false, false, false],
    ]);
    if (next) {
      history.push(`/${props.shift}#${nextWeek}`);
    } else {
      history.push(`/${props.shift}#${prevWeek}`);
    }
  }
  function startSwipe(e) {
    setSwipe(true);
    setSwipePositionStart(e.touches[0].screenX);
  }
  function checkSwipe(e) {
    setSwipePositionEnd(e.touches[0].screenX);
  }
  function doSwipe() {
    if (swipe && swipePositionStart !== 0 && swipePositionEnd !== 0 && swipePositionStart !== swipePositionEnd) {
      const move = Math.abs(swipePositionStart) - Math.abs(swipePositionEnd);
      if (Math.abs(move) > 50) {
        setNextWeek("");
        setPrevWeek("");
        setEditDay([
          [false, false, false, false, false, false, false],
          [false, false, false, false, false, false, false],
          [false, false, false, false, false, false, false],
          [false, false, false, false, false, false, false],
        ]);
        // minimum swipe distance to activate action
        if (move > 0) {
          // swipe right = render area[current-1]
          history.push(`/${props.shift}#${nextWeek}`);
        } else if (move < 0) {
          // swipe left = render area[current+1]
          history.push(`/${props.shift}#${prevWeek}`);
        }
      }
    }
    setSwipePositionStart(0);
    setSwipePositionEnd(0);
    setSwipe(false);
  }
  function addWeekClicked(shift) {
    setShowAddWeek(true);
    setTransitionShift(false);
    if (shift) {
      history.push({ pathname: `/${shift}`, state: { showAddWeek: true, showAddWeekOverlay: true } });
    }
  }
  function createWeek(dateIsoString) {
    setShowAddWeek(false);
    setShowAddWeekOverlay(false);
    setUpdateOldStartTimes(false);
    // OR goto next week that was just entered automatically
    history.push(`/${props.shift}#${dateIsoString}`);
  }
  function scalePage() {
    const root = document.querySelector("html");
    const rows = Array.from(document.getElementsByClassName("week-one-shift"));
    // set root font size to scale whole page based on vertical height
    root.style.fontSize = `${Math.floor(Auth.size.height / 40)}px`;
    if (Auth.size.width > 750 && Auth.size.width < 900 && rows) {
      console.log("widen lanes");
      rows.forEach((row) => {
        row.style.gap = "2rem";
      });
    }
  }
  function addThisWeekForShift(shiftStr) {
    if (hash) {
      history.push({ pathname: `/${shiftStr}`, hash: hash, state: { showAddWeek: true } });
    } else {
      history.push({ pathname: `/${shiftStr}`, state: { showAddWeek: true } });
    }
  }
  return (
    <div className="start-times" onTouchStart={(e) => startSwipe(e)} onTouchEnd={doSwipe} onTouchMove={(e) => checkSwipe(e)}>
      {((props.shift === "all" && Auth.size.width < Auth.size.height) || props.shift !== "all") && (
        <div className="shift-changer">
          <ButtonGroup size="large" variant="contained" aria-label="shift navigation buttons">
            {["d", "t", "n", "p"].map((shiftLetter) => (
              <MyButton id={props.shift === shiftLetter ? "active-shift-nav" : ""} key={shiftLetter} onClick={() => changeShift(shiftLetter)}>
                {getPermissionIndex(shiftLetter)}
                {Auth.user.admin && !Object.keys(currentWeek).includes(shiftLetter) && (
                  <Tooltip title="Start Times need to be updated!">
                    <ErrorOutline style={{ marginTop: "-.25rem", color: "var(--alert)", fontSize: ".75rem" }} />
                  </Tooltip>
                )}
              </MyButton>
            ))}
            {Auth?.size?.width > 700 && (
              <MyButton id={props.shift === "all" ? "active-shift-nav" : ""} onClick={() => changeShift("all")}>
                All
              </MyButton>
            )}
          </ButtonGroup>
        </div>
      )}
      {nextWeek && (
        <div className="tab-right" onClick={() => changeStartTimes(true)}>
          <NavigateNext style={{ fontSize: "5rem", zIndex: 1, position: "absolute", top: "-.7rem", right: "-1.15rem" }} />
          <span className={weeksFromNow + 1 >= 10 || weeksFromNow + 1 <= -10 ? "smallFont" : "padRight"}>{weeksFromNow + 1}</span>
        </div>
      )}
      {prevWeek && (
        <div className="tab-left" onClick={() => changeStartTimes(false)}>
          <NavigateBefore style={{ fontSize: "5rem", zIndex: 1, position: "absolute", top: "-.7rem", left: "-1.15rem" }} />
          <span className={weeksFromNow - 1 >= 10 || weeksFromNow - 1 <= -10 ? "smallFont" : "padLeft"}>{weeksFromNow - 1}</span>
        </div>
      )}
      {isLoading && <Spinner />}
      {!isLoading && props.shift !== "all" && !Object.keys(currentWeek).includes(props.shift) && !Auth.user.admin && (
        /* no data for current singular shift */
        <div className="start-times-message-container">
          <ErrorOutline />
          <p>Start Times have not been updated.</p>
        </div>
      )}
      {!isLoading && props.shift !== "all" && !transitionShift && showAddWeek && Auth.user.admin && Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(props.shift)] && (
        <AddWeek
          weekEnding={
            Object.keys(currentWeek).includes(props.shift) && showAddWeekOverlay
              ? getCurrentWeekDateEnding(1)
              : updateOldStartTimes
              ? hash
                ? hash.substr(1, hash.length)
                : getCurrentWeekDateEnding(0)
              : getCurrentWeekDateEnding(0)
          }
          previousWeekEnding={prevWeek}
          next={Object.keys(currentWeek).includes(props.shift) && showAddWeekOverlay ? true : false}
          goBack={displayAddWeek}
          update={createWeek}
          shift={props.shift}
        />
      )}
      {!isLoading && !Object.keys(currentWeek).includes(props.shift) && Auth.user.admin && !Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(props.shift)] && (
        <div className="start-times-message-container">
          <ErrorOutline />
          <p> You don't have permission to update start times for this shift. Please request to have your permissions elevated if you need to be able to do this.</p>
        </div>
      )}
      <div className="week">
        {props.shift !== "all" && showAddWeekOverlay && !showAddWeek && (
          <div className="add-week-overlay">
            <Tooltip disableFocusListener title="Add Next Weeks Start Times">
              <Button onClick={() => addWeekClicked(false)}>
                <AddBox fontSize="large" style={{ color: "var(--ups-gold)" }} />
              </Button>
            </Tooltip>
          </div>
        )}
        {!isLoading &&
          props.shift !== "all" &&
          Object.keys(currentWeek).includes(props.shift) &&
          !showAddWeek &&
          Object.keys(currentWeek[props.shift]).map((key) =>
            Auth.user.admin && Auth.user.permissions.editStartTimes?.[getPermissionIndex(props.shift)] && editDay[getShiftIndex(props.shift)][parseInt(key)] ? (
              <EditDay
                key={key}
                shift={props.shift}
                details={currentWeek[props.shift][key]}
                we={format(endOfWeek(addWeeks(new Date(nextWeek), -1)), "yyyy-MM-dd")}
                toggle={toggleEdit}
                index={parseInt(key)}
              />
            ) : (
              <Day key={key} shift={props.shift} index={parseInt(key)} details={currentWeek[props.shift][key]} toggle={toggleEdit} displayAll={false} />
            )
          )}
        {navigator.canShare && props.shift !== "all" && Object.keys(currentWeek).includes(props.shift) && (
          <div className="share-overlay" onClick={share}>
            <Share />
          </div>
        )}
      </div>
      {!isLoading && props.shift === "all" && Auth.size.width < Auth.size.height && <p>Select Shift Above to View Start Times.</p>}
      {!isLoading && props.shift === "all" && Auth.size.width > Auth.size.height && (
        <div className="week-allShifts">
          {["d", "t", "n", "p"].map((shiftLetter) => (
            <React.Fragment key={"row" + shiftLetter}>
              <MyButton2 key={shiftLetter + "btn"} onClick={() => changeShift(shiftLetter)}>
                <div className="btn-container">
                  <div className="big-btn-text">{shiftLetter.toUpperCase()}</div>
                  <div className="btn-allShift-text">{getPermissionIndex(shiftLetter)}</div>
                </div>
              </MyButton2>
              <div key={shiftLetter + "week"} className="week-one-shift">
                {Object.keys(currentWeek).includes(shiftLetter) && !showAddWeek ? (
                  Object.keys(currentWeek[shiftLetter]).map((key) =>
                    Auth.user.admin && Auth.user.permissions.editStartTimes?.[getPermissionIndex(shiftLetter)] && editDay[getShiftIndex(shiftLetter)][key] ? (
                      <EditDay
                        key={shiftLetter + key}
                        shift={shiftLetter}
                        details={currentWeek[shiftLetter][key]}
                        we={format(endOfWeek(addWeeks(new Date(nextWeek), -1)), "yyyy-MM-dd")}
                        toggle={toggleEdit}
                        index={parseInt(key)}
                      />
                    ) : (
                      <Day key={shiftLetter + key} shift={shiftLetter} displayAll={true} index={parseInt(key)} details={currentWeek[shiftLetter][key]} toggle={toggleEdit} />
                    )
                  )
                ) : Auth?.user?.admin && Auth?.user?.permissions?.editStartTimes?.[getPermissionIndex(shiftLetter)] ? (
                  <div key={"addNext" + shiftLetter} className="add-next-week-container">
                    <MyButton2 onClick={() => addThisWeekForShift(shiftLetter)}>
                      <div className="btn-container">
                        <AddBox style={{ fontSize: "5rem" }} />
                        <div className="btn-text">Add This Week</div>
                      </div>
                    </MyButton2>
                  </div>
                ) : null}
                {!showAddWeekOverlayShifts[0] && showAddWeekOverlay && !showAddWeek && (
                  <div key={"addNextOverlay" + shiftLetter} className="add-week-overlay" style={{ right: "-1.5rem" }}>
                    <Tooltip disableFocusListener title={`Add Next Week for ${getPermissionIndex(shiftLetter)}.`}>
                      <Button onClick={() => addWeekClicked(shiftLetter)}>
                        <AddBox fontSize="large" style={{ color: "var(--ups-gold)" }} />
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
StartTimes.propTypes = {
  shift: PropTypes.string.isRequired,
};
export default StartTimes;
