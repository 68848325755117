import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { endOfWeek, format } from "date-fns";

const useStyles = makeStyles(() => ({
  card: {
    background: "var(--ups-gold)",
    borderRadius: ".4rem",
    color: "var(--ups-dark-brown)",
    padding: "2rem",
    display: "grid",
    justifyContent: "center",
    gap: "2rem",
  },
  container: {
    display: "grid",
    justifyContent: "center",
    margin: "1rem",
  },
  button: {
    background: "var(--ups-brown)",
    border: 0,
    borderRadius: ".6rem",
    color: "var(--ups-yellow)",
    fontWeight: "bolder",
    "&:hover": {
      transform: "scale(1.25)",
      background: "var(--ups-dark-brown)",
    },
  },
  span: {
    maxWidth: "20rem",
  },
}));
const CalendarPicker = () => {
  const classes = useStyles();
  const [date, setDate] = useState(new Date());
  const history = useHistory();
  const hash = useLocation().hash;
  function goToDate(value) {
    setDate(value);
    const dateString = format(value, "yyyy-MM-dd");
    if (hash.includes("st")) {
      const saturday = format(endOfWeek(value), "yyyy-MM-dd");
      if (hash.includes("-t")) {
        history.push(`/t/#${saturday}`);
      } else if (hash.includes("-d")) {
        history.push(`/d/#${saturday}`);
      } else if (hash.includes("-n")) {
        history.push(`/n/#${saturday}`);
      } else if (hash.includes("-p")) {
        history.push(`/p/#${saturday}`);
      } else {
        history.push(`/#${saturday}`);
      }
    } else {
      history.push(`/hours/day/#${dateString}`);
    }
  }
  function goToToday() {
    const today = new Date();
    const todayString = format(today, "yyyy-MM-dd");
    history.push(`/hours/day#${todayString}`);
  }
  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <span className={classes.span}>To access your hours, use the date picker below for any past dates or select "go to today".</span>
        <MuiPickersUtilsProvider disableStrictModeCompat utils={DateFnsUtils}>
          <DatePicker
            /* disableFuture */
            label="Date"
            openTo="year"
            inputVariant="outlined"
            format="E MMM d yyyy"
            views={["year", "month", "date"]}
            value={date}
            onChange={(newValue) => {
              goToDate(newValue);
            }}
          />
        </MuiPickersUtilsProvider>
        {/* TODO: add week picker that goto /hours/week# */}
        {hash.includes("st") ? (
          <Button className={classes.button} onClick={() => goToDate(new Date())}>
            Go To Newest Start Time
          </Button>
        ) : (
          <Button className={classes.button} onClick={goToToday}>
            Go To Today
          </Button>
        )}
      </div>
    </div>
  );
};

export default CalendarPicker;
