import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { isValid, formatDistanceStrict, addDays, startOfWeek, isToday, endOfWeek, addWeeks, format } from "date-fns";
import { NavigateNext, NavigateBefore, Warning, AttachMoney, QueryBuilder, Share } from "@material-ui/icons";
import { timeStringToMilitaryHours, toISOLocaleString } from "./Utils";
import firebase from "firebase/app";
import "firebase/database";
import { AuthContext } from "./App";
import Spinner from "./Spinner";
import { Button } from "@material-ui/core";
const HoursWeekly = () => {
  const Auth = useContext(AuthContext);
  const [clockTimes, setClockTimes] = useState({});
  const [weekDates, setWeekDates] = useState({});
  const [totalHours, setTotalHours] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [payRate, setPayRate] = useState(Auth.user?.appSettings?.payRate || 15.0);
  const [nextWeek, setNextWeek] = useState("");
  const [prevWeek, setPrevWeek] = useState("");
  const [swipe, setSwipe] = useState(false);
  const [swipePositionStart, setSwipePositionStart] = useState(0);
  const [swipePositionEnd, setSwipePositionEnd] = useState(0);
  const [st, setST] = useState({ hours: 0, monies: 0 });
  const [ot, setOT] = useState({ hours: 0, monies: 0 });
  const [dt, setDT] = useState({ hours: 0, monies: 0 });
  const [holidayTime, setHolidayTime] = useState({ hours: 0, monies: 0 });
  const [pto, setPTO] = useState({ hours: 0, monies: 0 });
  const [vacationTime, setVacationTime] = useState({ hours: 0, monies: 0 });
  const db = firebase.database();
  const hash = useLocation().hash;
  const history = useHistory();

  useEffect(() => {
    updatePayRate();
    fetchWeeklyClockTimes(checkDateHash());
    setNextPrevWeeks();
    return () => {
      db.ref(`hours/`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);
  function fetchWeeklyClockTimes(date) {
    const start = startOfWeek(new Date(date));
    console.log("fetching clock In Hours for the week of: " + date.toDateString() + " ...");
    for (let index = 0; index < 7; index++) {
      const date = addDays(start, index);
      db.ref(`hours/${Auth.user.uid}/${date.toDateString()}`).on("value", function (snapshot) {
        var clockTime = (snapshot.val() && snapshot.val()) || {};
        const timesCopy = clockTimes;
        timesCopy[index] = clockTime;
        setClockTimes(timesCopy);
        const datesCopy = weekDates;
        datesCopy[index] = date;
        setWeekDates(datesCopy);
        const totalHoursCopy = totalHours;
        totalHoursCopy[index] = calculateTotalTime(clockTime);
        setTotalHours(totalHoursCopy);
        if (Object.keys(clockTimes).length === 7) {
          setAllTimes();
          setIsLoading(false);
        }
      });
    }
  }
  function setAllTimes() {
    calcOT();
    calcST();
    calcDT();
    calcPTO();
    calcHoliday();
    calcVacationTime();
  }
  //TODO: details pane dropdown on week totals when clicked hides details in each day, toggles
  function setNextPrevWeeks() {
    const saturday = endOfWeek(checkDateHash());
    setNextWeek(format(addWeeks(saturday, 1), "yyyy-MM-dd"));
    setPrevWeek(format(addWeeks(saturday, -1), "yyyy-MM-dd"));
  }
  function checkDateHash() {
    const date = new Date(hash.slice(1, hash.length) + "T10:00:00");
    if (isValid(date)) {
      return date;
    } else {
      console.log("not found");
      history.replace("/");
    }
  }
  function updatePayRate() {
    if (Auth.user?.appSettings?.payRate) {
      setPayRate(Auth.user?.appSettings?.payRate);
    }
    /* TODO: FUTURE: from start date calculate payRate from union contract and notify user if calculated payRate does not match user.settings.payRate*/
  }
  function calculateTotalTime(myClockTimes) {
    let afterMidnightDate = new Date(hash.slice(1, hash.length) + "T10:00:00");
    if (myClockTimes?.ClockOut && timeStringToMilitaryHours(myClockTimes?.ClockOut) <= 11 && myClockTimes?.ClockIn && timeStringToMilitaryHours(myClockTimes?.ClockIn) >= 15) {
      // if clockOut time is next day ie. 3am for pt workers on midnight, ft workers, double-shifters on preload

      afterMidnightDate = addDays(new Date(hash.slice(1, hash.length) + "T10:00:00"), 1);
    }
    if (myClockTimes?.BreakOut && myClockTimes?.ClockOut) {
      const diffTotal = formatDistanceStrict(
        new Date(`${afterMidnightDate.toDateString()} ${timeStringToMilitaryHours(myClockTimes?.ClockOut)}:${myClockTimes?.ClockOut.slice(-5, -3)}`),
        new Date(`${hash.slice(1, hash.length)} ${timeStringToMilitaryHours(myClockTimes?.ClockIn)}:${myClockTimes?.ClockIn.slice(-5, -3)}`),
        { unit: "minute" }
      );

      const diffBreak = formatDistanceStrict(
        new Date(`${hash.slice(1, hash.length)} ${timeStringToMilitaryHours(myClockTimes?.BreakOut)}:${myClockTimes?.BreakOut.slice(-5, -3)}`),
        new Date(`${hash.slice(1, hash.length)} ${timeStringToMilitaryHours(myClockTimes?.BreakIn)}:${myClockTimes?.BreakIn.slice(-5, -3)}`),
        { unit: "minute" }
      );
      const [totalMin] = diffTotal.split(" ");
      const [breakMin] = diffBreak.split(" ");
      if (myClockTimes?.Holiday) {
        return parseInt(totalMin) - parseInt(breakMin) + myClockTimes.Holiday * 60;
      }
      return parseInt(totalMin) - parseInt(breakMin);
    } else if (myClockTimes?.ClockOut) {
      const date1 = new Date(`${afterMidnightDate.toDateString()} ${timeStringToMilitaryHours(myClockTimes?.ClockOut)}:${myClockTimes?.ClockOut.slice(-5, -3)}`);
      const date2 = new Date(`${hash.slice(1, hash.length)} ${timeStringToMilitaryHours(myClockTimes?.ClockIn)}:${myClockTimes?.ClockIn.slice(-5, -3)}`);
      const diffTotal = formatDistanceStrict(date1, date2, { unit: "minute" });
      const [totalMin] = diffTotal.split(" ");

      if (myClockTimes?.Holiday) {
        return parseInt(totalMin) + myClockTimes.Holiday * 60;
      }
      return parseInt(totalMin);
    } else if (!myClockTimes?.ClockIn && myClockTimes?.PTO) {
      return myClockTimes.PTO * 60;
    } else if (myClockTimes?.Holiday) {
      return myClockTimes.Holiday * 60;
    } else return 0;
  }
  function calcVacationTime() {
    Object.values(clockTimes).forEach((day) => {
      if (Object.keys(day).includes("Vacation")) {
        setVacationTime({ hours: day?.Vacation, monies: day.Vacation * payRate });
      } else {
        setVacationTime({ hours: 0, monies: 0 });
      }
    });
  }
  function calcWeekTotals() {
    const totHours = st.hours + ot.hours + dt.hours + vacationTime.hours + pto.hours + holidayTime.hours;
    const totMonies = st.monies + ot.monies + dt.monies + vacationTime.monies + pto.monies + holidayTime.monies;
    return { hours: totHours, monies: totMonies };
  }
  function calcDT() {
    let subTotalHrs = 0;
    let index = 1;
    Object.values(clockTimes).forEach((day) => {
      if (Object.keys(day).includes("ClockOut")) {
        if ((Object.keys(day).includes("Holiday") || index === 7) && Object.keys(day).includes("ClockOut")) {
          let subHrs = 0;
          if (day?.Holiday) {
            subHrs = calculateTotalTime(day) - day.Holiday * 60;
          } else {
            subHrs = calculateTotalTime(day);
          }
          subTotalHrs = subTotalHrs + subHrs / 60;
        }
        index++;
      }
    });
    setDT({ hours: subTotalHrs, monies: subTotalHrs * (Auth.user?.appSettings?.payRate || 15) * 2 });
  }
  function calcOT() {
    let cutOff = 5;
    if (Auth?.user?.appSettings?.ft) {
      cutOff = 8;
    }
    let subTotalHrs = 0;
    let index = 1;
    Object.values(clockTimes).forEach((day) => {
      // count each day worked this week
      if (Object.keys(day).includes("ClockOut")) {
        // don't add hours for hours worked on a holiday, those would be 2x pay
        if (!Object.keys(day).includes("Holiday") && Object.keys(day).includes("ClockOut")) {
          const subHrs = calculateTotalTime(day);
          if (index <= 5) {
            // overtime only for hours over the cutoff value of overtime
            if (subHrs / 60 > cutOff) {
              subTotalHrs = subTotalHrs + subHrs / 60 - cutOff;
            }
          } else if (index === 6) {
            //6th day pay is all overtime all day.
            subTotalHrs = subTotalHrs + subHrs / 60;
          }
        }
        index++;
      }
    });
    setOT({ hours: subTotalHrs, monies: subTotalHrs * (Auth.user?.appSettings?.payRate || 15) * 1.5 });
  }
  function calcPTO() {
    const totalPTO = Object.values(clockTimes).reduce((acc, day) => {
      if (Object.keys(day).includes("PTO")) {
        return acc + day.PTO;
      } else return parseInt(acc) + 0;
    }, 0);
    setPTO({ hours: totalPTO, monies: totalPTO * payRate });
  }
  function calcHoliday() {
    const totalHoliday = Object.values(clockTimes).reduce((acc, day) => {
      if (Object.keys(day).includes("Holiday")) {
        return acc + day.Holiday;
      } else return parseInt(acc) + 0;
    }, 0);
    setHolidayTime({ hours: totalHoliday, monies: totalHoliday * payRate });
  }
  function calcST() {
    let cutOff = 5;
    if (Auth?.user?.appSettings?.ft) {
      cutOff = 8;
    }
    let subTotalHrs = 0;
    let index = 1;
    Object.values(clockTimes).forEach((day) => {
      if (Object.keys(day).includes("ClockOut")) {
        // count each day worked this week
        if (index <= 5) {
          // only add in the first 5 days into standard pay
          if (!Object.keys(day).includes("Holiday") && Object.keys(day).includes("ClockOut")) {
            const subHrs = calculateTotalTime(day);
            if (subHrs / 60 > cutOff) {
              subTotalHrs = subTotalHrs + cutOff;
            } else {
              subTotalHrs = subTotalHrs + subHrs / 60;
            }
          }
        }
        index++;
      }
    });
    setST({ hours: subTotalHrs, monies: subTotalHrs * (Auth.user?.appSettings?.payRate || 15) });
  }
  function highlightToday(thisDay) {
    if (isToday(thisDay)) {
      return `today day-container`;
    } else {
      return `day-container`;
    }
  }
  function changeStartTimes(next) {
    if (next) {
      history.push(`/hours/week#${nextWeek}`);
    } else {
      history.push(`/hours/week#${prevWeek}`);
    }
  }
  function startSwipe(e) {
    setSwipe(true);
    setSwipePositionStart(e.touches[0].screenX);
  }
  function checkSwipe(e) {
    setSwipePositionEnd(e.touches[0].screenX);
  }
  function doSwipe() {
    if (swipe && swipePositionStart !== 0 && swipePositionEnd !== 0 && swipePositionStart !== swipePositionEnd) {
      const move = Math.abs(swipePositionStart) - Math.abs(swipePositionEnd);
      if (Math.abs(move) > 50) {
        setNextWeek("");
        setPrevWeek("");
        // minimum swipe distance to activate action
        if (move > 0) {
          // swipe right = render area[current-1]
          history.push(`/hours/week#${nextWeek}`);
        } else if (move < 0) {
          // swipe left = render area[current+1]
          history.push(`/hours/week#${prevWeek}`);
        }
      }
    }
    setSwipePositionStart(0);
    setSwipePositionEnd(0);
    setSwipe(false);
  }
  function addVacationWeek() {
    // add vacation hours into saturday aka week ending date. 20hrs=pt, 40hrs=ft ()
    // 1st week of vacation gets extra hours ie. 25hrs=pt, 48hrs=ft
    const weekendingDate = endOfWeek(checkDateHash() || endOfWeek(new Date()));
    const amountInHours = Auth.user?.appSettings?.ft ? 40 : 20;
    const option = Auth.user?.appSettings?.ft ? 8 : 5;
    db.ref(`hours/${Auth.user.uid}`)
      .once("value")
      .then(function (snapshot) {
        const res = (snapshot.val() && snapshot.val()) || {};
        if (res) {
          const now = new Date();
          const thisYearStr = now.getFullYear();
          const filtered = Object.keys(res).filter((dateStr) => {
            if (dateStr.includes(thisYearStr.toString()) && Object.keys(res?.[dateStr]).includes("Vacation")) {
              return dateStr;
            } else return null;
          });
          if (filtered.length === 0 || !filtered.includes(weekendingDate.toDateString())) {
            //prevent overwriting existing vacation
            db.ref(`hours/${Auth.user.uid}/${weekendingDate.toDateString()}/Vacation`).set(filtered.length === 0 ? amountInHours + option : amountInHours, function (e) {
              e && console.log(e);
            });
          }
        }
      });
  }
  function removeVacation(e) {
    e.stopPropagation();
    //TODO: modal are you sure to remove
    const weekendingDate = endOfWeek(checkDateHash() || endOfWeek(new Date()));
    db.ref(`hours/${Auth.user.uid}/${weekendingDate.toDateString()}/Vacation`).set(null, function (e) {
      e && console.log(e);
    });
    setVacationTime({ hours: 0, monies: 0 });
    fetchWeeklyClockTimes(weekendingDate);
  }
  function share() {
    if (navigator.canShare && Object.keys(clockTimes).length > 0 && Object.keys(weekDates).length > 0) {
      // create an array for each day of the week and add object properties of clockTimes and weekDates
      const combinedArray = new Array(7);
      for (let index = 0; index < 7; index++) {
        const element = { ...clockTimes[index], date: weekDates[index], totalMin: totalHours[index] };
        combinedArray[index] = element;
      }
      // filter out newly created array to only have values for days of the week with a clockIn time
      // also map out the data to be readable
      const filtered = combinedArray
        .filter((dayObj) => {
          if (dayObj?.ClockIn && dayObj?.ClockIn.length > 4) {
            return dayObj;
          } else return null;
        })
        .map((dayObj) =>
          dayObj.date
            .toLocaleString()
            .substr(0, 11)
            .concat(
              `In: ${dayObj.ClockIn}${dayObj?.BreakIn ? ", BreakIn: " + dayObj.BreakIn : ""}${dayObj?.BreakOut ? ", BreakOut: " + dayObj.BreakOut : ""}, Out: ${
                dayObj?.ClockOut ? dayObj.ClockOut : "N/A"
              }, Total: ${((dayObj.totalMin % 60) / 60 + Math.floor(dayObj.totalMin / 60)).toFixed(2)}Hrs`
            )
        );
      let url = history.location.pathname.concat(history.location.hash);
      navigator.share({ title: (Auth.user?.displayName + "'s" || "My") + " hours worked for week ending ".concat(hash), url: url, text: filtered.join("; ") });
    }
  }
  return (
    <div className="hoursWeekly-container" onTouchStart={(e) => startSwipe(e)} onTouchEnd={doSwipe} onTouchMove={(e) => checkSwipe(e)}>
      {nextWeek && (
        <div className="tab-right" onClick={() => changeStartTimes(true)}>
          <NavigateNext style={{ fontSize: "4rem", zIndex: 2, position: "absolute", top: "-.7rem", right: "-1.15rem" }} />
        </div>
      )}
      {prevWeek && (
        <div className="tab-left" onClick={() => changeStartTimes(false)}>
          <NavigateBefore style={{ fontSize: "4rem", zIndex: 2, position: "absolute", left: "-1.15rem", top: "-.7rem" }} />
        </div>
      )}
      <div className="week-container">
        {isLoading && <Spinner />}
        {!isLoading &&
          Object.keys(clockTimes).map((key) => (
            <div key={key} className={highlightToday(weekDates[key])} onClick={() => history.push({ pathname: "/hours/day", hash: toISOLocaleString(weekDates[key]) })}>
              <div className="top-date-text">{weekDates[key].toDateString().slice(0, -4)}</div>
              {clockTimes[key]?.ClockIn && (
                <div className="text-container">
                  <div>Start:</div>
                  <div className="hourTracker-time">{clockTimes[key]?.ClockIn || "OFF"}</div>
                </div>
              )}
              {clockTimes[key]?.ClockIn && (
                <div className="text-container">
                  <div>End:</div>
                  <div className="hourTracker-time">
                    {clockTimes[key]?.ClockOut ? (
                      clockTimes[key]?.ClockOut
                    ) : (
                      <div className="not-set-warning">
                        <span>Not Set</span>
                        <Warning />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {clockTimes[key]?.Vacation && (
                <div className="text-container" style={{ marginBottom: ".85rem", cursor: "pointer", height: "1rem" }} onClick={(e) => removeVacation(e)}>
                  <div>Vacation:</div>
                  <div className="hourTracker-time">{clockTimes[key]?.Vacation} hrs</div>
                </div>
              )}
              {clockTimes[key]?.PTO && (
                <div className="text-container" style={{ marginBottom: ".85rem", cursor: "pointer", height: "1rem" }} onClick={(e) => removeVacation(e)}>
                  <div>PTO:</div>
                  <div className="hourTracker-time">{clockTimes[key]?.PTO} hrs</div>
                </div>
              )}
              {clockTimes[key]?.Holiday && (
                <div className="text-container" style={{ marginBottom: ".85rem", cursor: "pointer", height: "1rem" }} onClick={(e) => removeVacation(e)}>
                  <div>Holiday:</div>
                  <div className="hourTracker-time">{clockTimes[key]?.Holiday} hrs</div>
                </div>
              )}
              {clockTimes[key]?.ClockIn && (
                <div className="text-container-total highlighted-text">
                  {!Auth?.isMobile && <div>Total:</div>}
                  <div className="hourTracker-time">{((totalHours[key] % 60) / 60 + Math.floor(totalHours[key] / 60)).toFixed(2) + " Hours"}</div>
                </div>
              )}
              {!clockTimes[key]?.ClockIn && !clockTimes[key]?.Vacation && !clockTimes[key]?.PTO && !clockTimes[key]?.Holiday && !clockTimes[6]?.Vacation && (
                <div className="big-text">OFF</div>
              )}
              {clockTimes[6]?.Vacation && parseInt(key) !== 6 && <div className="big-text">VAC</div>}
            </div>
          ))}
        {Auth.size.width < Auth.size.height && (
          <div className="day-container" onClick={() => history.push(`/hours/totals${hash}`)}>
            <div className="top-date-text">Weekly Total</div>
            {dt.hours !== 0 && (
              <div className="hourTracker-time-total">
                <div>DT:</div>
                <span style={{ color: "var(--info)" }}>{dt.hours.toFixed(2)}</span>
                <span style={{ color: "var(--success)" }}>${dt.monies.toFixed(2)}</span>
              </div>
            )}
            {ot.hours !== 0 && (
              <div className="hourTracker-time-total">
                <div>OT:</div>
                <span style={{ color: "var(--info)" }}>{ot.hours.toFixed(2)}</span>
                <span style={{ color: "var(--success)" }}>${ot.monies.toFixed(2)}</span>
              </div>
            )}
            <div className="hourTracker-time-total">
              <div>ST:</div>
              <span style={{ color: "var(--info)" }}>{st.hours.toFixed(2)}</span>
              <span style={{ color: "var(--success)" }}>${st.monies.toFixed(2)}</span>
            </div>
            {holidayTime.hours !== 0 && (
              <div className="hourTracker-time-total">
                <div>HOL:</div>
                <span style={{ color: "var(--info)" }}>{holidayTime.hours.toFixed(2)}</span>
                <span style={{ color: "var(--success)" }}>${holidayTime.monies.toFixed(2)}</span>
              </div>
            )}
            {pto.hours !== 0 && (
              <div className="hourTracker-time-total">
                <div>PTO:</div>
                <span style={{ color: "var(--info)" }}>{pto.hours.toFixed(2)}</span>
                <span style={{ color: "var(--success)" }}>${pto.monies.toFixed(2)}</span>
              </div>
            )}
            <div className="text-container-total highlighted-text">
              <div className="flexRowCenter">
                <QueryBuilder fontSize="small" style={{ color: "var(--info)" }} />
                <span style={{ color: "var(--info)" }}>{calcWeekTotals().hours.toFixed(2)}</span>
              </div>
              <div className="flexRowCenter">
                <AttachMoney fontSize="small" style={{ color: "var(--success)" }} />
                <span style={{ color: "var(--success)" }}>{calcWeekTotals().monies.toFixed(2)}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      {navigator.canShare && (
        <div className="share-box">
          <div className="share-overlay" onClick={share}>
            <Share />
          </div>
        </div>
      )}
      {Auth.size.width > Auth.size.height && (
        <div className="day-container-desktop" onClick={() => history.push(`/hours/totals${hash}`)}>
          <div className="title">Weekly Totals</div>
          <div>OverTime:</div>
          <div className="hourTracker-time-total">
            <div className="flexRowCenter">
              <QueryBuilder fontSize="small" style={{ color: "var(--info)" }} />
              <span style={{ color: "var(--info)" }}>{ot.hours.toFixed(2)}hrs</span>
            </div>
            <div className="flexRowCenter">
              <AttachMoney fontSize="small" style={{ color: "var(--success)" }} />
              <span style={{ color: "var(--success)" }}>${ot.monies.toFixed(2)}</span>
            </div>
          </div>
          <div>StandardTime:</div>
          <div className="hourTracker-time-total">
            <div className="flexRowCenter">
              <QueryBuilder fontSize="small" style={{ color: "var(--info)" }} />
              <span style={{ color: "var(--info)" }}>{st.hours.toFixed(2)}hrs</span>
            </div>
            <div className="flexRowCenter">
              <AttachMoney fontSize="small" style={{ color: "var(--success)" }} />
              <span style={{ color: "var(--success)" }}>${st.monies.toFixed(2)}</span>
            </div>
          </div>
          <div className="title">
            <div className="flexRowCenter">
              <QueryBuilder fontSize="small" style={{ color: "var(--info)" }} />
              <span style={{ color: "var(--info)" }}>{((calcWeekTotals().hours % 60) / 60 + Math.floor(calcWeekTotals().hours / 60)).toFixed(2)}</span>
            </div>
            <div className="flexRowCenter">
              <AttachMoney fontSize="small" style={{ color: "var(--success)" }} />
              <span style={{ color: "var(--success)" }}>{calcWeekTotals().monies.toFixed(2)}</span>
            </div>
          </div>
        </div>
      )}
      {vacationTime.hours < 20 && (
        <Button style={{ background: "var(--ups-gold)", margin: ".5rem" }} onClick={addVacationWeek}>
          add Vacation
        </Button>
      )}
    </div>
  );
};

export default HoursWeekly;
