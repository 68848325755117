import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./App";
import firebase from "firebase";
import { timeStringToMilitaryHours } from "./Utils";
import { addDays, formatDistanceStrict, isToday, isSameWeek, isSameMonth, isSameYear } from "date-fns";

const Totals = () => {
  const db = firebase.database();
  const Auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [allClockTimes, setAllClockTimes] = useState({});
  const [date, setDate] = useState(new Date());
  //TODO: setDate based on hash in url or from an included datePicker interface
  const [total, setTotal] = useState({ st: 0, ot: 0 });
  const [avg, setAvg] = useState({ st: 0, ot: 0 });
  useEffect(() => {
    // check if url is valid date before fetching data, otherwise goto calendar picker.
    fetchHours();
    return () => {
      db.ref(`hours/${Auth.user.uid}`).off();
      db.ref(`weeks/`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function fetchHours() {
    db.ref(`hours/${Auth.user.uid}/`).on("value", function (snapshot) {
      var clockTimes = (snapshot.val() && snapshot.val()) || {};
      if (!snapshot.val()) {
        console.log(`No clock in times found for user: ${Auth.user.uid}`);
      }
      if (clockTimes) {
        console.log(JSON.stringify(clockTimes));
        console.log(calcShiftTotalMins(Object.keys(clockTimes)[0], Object.values(clockTimes)[0]));
      } else {
        console.log("not found");
      }
      setAllClockTimes(clockTimes);
      setIsLoading(false);
    });
  }
  function otMins(totalMinutes) {
    if (Auth?.user?.appSettings?.ft) {
      if (totalMinutes > 8 * 60) {
        return totalMinutes - 8 * 60;
      } else return 0;
    } else {
      if (totalMinutes > 5 * 60) {
        return totalMinutes - 5 * 60;
      } else return 0;
    }
  }
  function stMins(totalMinutes) {
    if (Auth?.user?.appSettings?.ft) {
      if (totalMinutes > 8 * 60) {
        return 8 * 60;
      } else return totalMinutes;
    } else {
      if (totalMinutes > 5 * 60) {
        return 5 * 60;
      } else return totalMinutes;
    }
  }
  function calcShiftTotalMins(dateStr, data) {
    const times = data;
    const dateObj = new Date(dateStr);
    let afterMidnightDate = new Date(dateObj);
    // returns number of minutes worked for the inputted date
    if (times?.ClockOut && timeStringToMilitaryHours(times?.ClockOut) < 8 && times?.ClockIn && timeStringToMilitaryHours(times?.ClockIn) >= 8) {
      // if clockOut time is next day ie. 3am for pt workers on midnight, ft workers, double-shifters on preload
      afterMidnightDate = addDays(dateObj, 1);
      console.log(afterMidnightDate);
    }
    if (times?.ClockOut && times?.BreakOut && times?.BreakIn) {
      // on ClockOUT w/Breakout: full 2 shifts ((clockOut-clockIn)-(breakOut-breakIn))    [ft]
      const diffTotal = formatDistanceStrict(
        new Date(`${afterMidnightDate.toDateString()} ${timeStringToMilitaryHours(times?.ClockOut)}:${times?.ClockOut.substr(-5, 2)}`),
        new Date(`${dateStr} ${timeStringToMilitaryHours(times?.ClockIn)}:${times?.ClockIn.substr(-5, 2)}`),
        { unit: "minute" }
      );
      const diffBreak = formatDistanceStrict(
        new Date(`${dateStr} ${timeStringToMilitaryHours(times?.BreakOut)}:${times?.BreakOut.substr(-5, 2)}`),
        new Date(`${dateStr} ${timeStringToMilitaryHours(times?.BreakIn)}:${times?.BreakIn.substr(-5, 2)}`),
        { unit: "minute" }
      );
      const [totalMin] = diffTotal.split(" ");
      const [breakMin] = diffBreak.split(" ");
      return totalMin - breakMin;
    } else if (times?.ClockOut && !times?.BreakOut && !times?.BreakIn) {
      // on ClockOUT : full 1 shift w/o break (clockOut-clockIn)    [pt]
      const diffMinutes = formatDistanceStrict(
        new Date(`${afterMidnightDate.toDateString()} ${timeStringToMilitaryHours(times?.ClockOut)}:${times?.ClockOut.substr(-5, 2)}`),
        new Date(`${dateStr} ${timeStringToMilitaryHours(times?.ClockIn)}:${times?.ClockIn.substr(-5, 2)}`),
        { unit: "minute" }
      );
      const [minutes] = diffMinutes.split(" ");
      return minutes;
    } else if (times?.BreakOut && times?.BreakIn && !times?.ClockOut) {
      // on BreakOut : 1 shift+current ((currentTime-clockIn)-(breakOut-breakIn))   [ft]
      const diffTotal = formatDistanceStrict(new Date(dateObj), new Date(`${dateStr} ${timeStringToMilitaryHours(times?.ClockIn)}:${times?.ClockIn.substr(-5, 2)}`), {
        unit: "minute",
      });
      const diffBreak = formatDistanceStrict(
        new Date(`${dateStr} ${timeStringToMilitaryHours(times?.BreakOut)}:${times?.BreakOut.substr(-5, 2)}`),
        new Date(`${dateStr} ${timeStringToMilitaryHours(times?.BreakIn)}:${times?.BreakIn.substr(-5, 2)}`),
        { unit: "minute" }
      );
      const [totalMin] = diffTotal.split(" ");
      const [breakMin] = diffBreak.split(" ");
      return totalMin - breakMin;
    } else if (!times?.BreakOut && times?.BreakIn) {
      // on BreakIN : 1 shift (breakIn-clockIn)    [ft]
      const diffMinutes = formatDistanceStrict(
        new Date(`${dateStr} ${timeStringToMilitaryHours(times?.BreakIn)}:${times?.BreakIn.substr(-5, 2)}`),
        new Date(`${dateStr} ${timeStringToMilitaryHours(times?.ClockIn)}:${times?.ClockIn.substr(-5, 2)}`),
        { unit: "minute" }
      );
      const [minutes] = diffMinutes.split(" ");
      return minutes;
    } else if (!times?.BreakOut && !times?.BreakIn && !times?.ClockOut) {
      // on Clock IN : current (currentTime-clockIn) [pt/ft]
      const diffMinutes = formatDistanceStrict(new Date(dateObj), new Date(`${dateStr} ${timeStringToMilitaryHours(times?.ClockIn)}:${times?.ClockIn.substr(-5, 2)}`), {
        unit: "minute",
      });
      const [minutes] = diffMinutes.split(" ");
      if (isToday(new Date(dateObj))) {
        //  calculate time worked as clockIn to time now IF the day being viewed is today
        return minutes;
      }
    }
  }
  function thisWeeksTotal(type) {
    if (Object.keys(allClockTimes).length > 0) {
      const dateToCompare = date; //new Date("2020-12-15");
      //setDate(dateToCompare);
      const stMinutes = [];
      const otMinutes = [];
      Object.keys(allClockTimes).forEach((key) => {
        const dateObj = new Date(key);
        if (type === "weekly" && isSameWeek(dateObj, dateToCompare)) {
          const subTotal = parseInt(calcShiftTotalMins(key, allClockTimes[key]));
          const st = parseInt(stMins(subTotal));
          const ot = parseInt(otMins(subTotal));
          stMinutes.push(st);
          otMinutes.push(ot);
        } else if (type === "monthly" && isSameMonth(dateObj, dateToCompare)) {
          const subTotal = parseInt(calcShiftTotalMins(key, allClockTimes[key]));
          const st = parseInt(stMins(subTotal));
          const ot = parseInt(otMins(subTotal));
          stMinutes.push(st);
          otMinutes.push(ot);
        } else if (type === "yearly" && isSameYear(dateObj, dateToCompare)) {
          const subTotal = parseInt(calcShiftTotalMins(key, allClockTimes[key]));
          const st = parseInt(stMins(subTotal));
          const ot = parseInt(otMins(subTotal));
          stMinutes.push(st);
          otMinutes.push(ot);
        }
      });
      const stTotal = stMinutes.reduce((a, b) => a + b, 0);
      const otTotal = otMinutes.reduce((a, b) => a + b, 0);
      const stAvg = stMinutes.reduce((a, b) => a + b, 0) / stMinutes.length;
      const otAvg = otMinutes.reduce((a, b) => a + b, 0) / otMinutes.length;
      setTotal({ st: stTotal, ot: otTotal });
      setAvg({ st: stAvg, ot: otAvg });
      setAvg({ st: stAvg, ot: otAvg });
    } else {
      console.log("no data found to calculate total");
    }
  }
  return (
    <div>
      <p>totals page in construction</p>
      <p>{date.toDateString()}</p>
      {!isLoading && (
        <div>
          <button onClick={() => thisWeeksTotal("weekly")}>calc this weeks total</button>
          <button onClick={() => thisWeeksTotal("monthly")}>calc this Months total</button>
          <button onClick={() => thisWeeksTotal("yearly")}>calc this Years total</button>
        </div>
      )}
      <div>this week total: {((total.st + total.ot) / 60).toFixed(2)} hrs</div>
      <div>this week total (st): {(total.st / 60).toFixed(2)} hrs</div>
      <div>this week total (ot): {(total.ot / 60).toFixed(2)} hrs</div>
      <div>avg daily: {((avg.st + avg.ot) / 60).toFixed(2)} hrs</div>
      <div>avg daily (st): {(avg.st / 60).toFixed(2)} </div>
      <div>avg daily (ot): {(avg.ot / 60).toFixed(2)} </div>
    </div>
  );
};

export default Totals;
