import React from "react";
import { ArrowBack, Close } from "@material-ui/icons";
import { Button, Fade, Modal, Backdrop } from "@material-ui/core";
import PropTypes from "prop-types";

const GModal = (props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal-position"
      open={props.show}
      onClose={() => props.setShow(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.show}>
        <div className="modal-box">
          <Button className="standard-button small circle absolute-top-right" onClick={() => props.setShow(false)}>
            <Close />
          </Button>
          <h2 id="transition-modal-title">{props.content.heading}</h2>
          {props.content.mainContent}
          <div className="btn-row">
            <Button className="standard-button" style={{ color: "var(--ups-gold)" }} onClick={() => props.setShow(false)}>
              <ArrowBack />
              Back
            </Button>
            {props.content?.buttons &&
              props.content.buttons.map((button) => (
                <Button key={button.title} className={`standard-button ${button?.color || ""}`} onClick={() => button.function()}>
                  {button.icon} <span>{button.title}</span>
                </Button>
              ))}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
GModal.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    mainContent: PropTypes.node.isRequired,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        function: PropTypes.func.isRequired,
        color: PropTypes.string,
        icon: PropTypes.object,
      })
    ),
  }),
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
export default GModal;
