import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase/app";
import "firebase/database";
import { styled, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { AddBox, Undo } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import { addDays, isThisWeek, parseISO } from "date-fns";
import { hoursToAmPmFormat, timeStringToMilitaryHours } from "./Utils";
import { AuthContext } from "./App";

const MyButton = styled(Button)({
  background: "var(--ups-brown)",
  borderRadius: ".4rem",
  boxShadow: "/* inset 0 5px 10px var(--ups-gold), */ 0 2px 1px var(--ups-dark-brown)",
  color: "var(--ups-gold)",
  padding: ".5rem",
  "&:hover": {
    background: "var(--ups-dark-brown)",
  },
});
const useStyles = makeStyles(() => ({
  gridCol: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
    gap: ".5rem",
    justifyContent: "space-around",
  },
  buttonSlimInline: {
    padding: ".25rem .5rem .25rem .25rem",
    width: "max-content",
    /* marginTop: "-.5rem", */
    marginBottom: ".5rem",
  },
  gridRow: {
    display: "grid",
    gridTemplateRows: "auto auto",
  },
  date: {
    fontSize: "1.25rem",
    margin: "-.5rem",
  },
  extraLarge: {
    fontSize: "2.75rem",
  },
  overlay: {
    position: "absolute",
    right: ".25rem",
    top: ".25rem",
    zIndex: 1,
  },
  slim: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  holidayContainer: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    padding: ".5rem 0 0 0",
    color: "var(--ups-dark-brown)",
    width: "calc(100% - .75rem)",
  },
  holidayDate: {
    marginTop: "-1rem",
    background: "var(--ups-gold)",
    fontSize: ".75rem",
    color: "var(--ups-brown)",
    width: "max-content",
    marginLeft: ".5rem",
    padding: "0 .25rem",
    fontFamily: "sans-serif",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "var(--ups-gold)",
    borderRadius: "0.4rem",
    padding: "0.75rem 1rem",
    marginTop: "0.75rem",
    gap: "0.75rem",
    position: "relative",
  },
  todayForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "var(--ups-gold)",
    borderRadius: "0.4rem",
    padding: "0.75rem 1rem",
    marginTop: "0.75rem",
    gap: "0.75rem",
    position: "relative",
    border: "solid 3px red",
  },
  iphone6Form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "var(--ups-gold)",
    borderRadius: "0.4rem",
    padding: "0.75rem 1rem",
    marginTop: "0.75rem",
    gap: "0.5rem",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const AddWeek = (props) => {
  const classes = useStyles();
  const db = firebase.database();
  const Auth = useContext(AuthContext);
  const saturdayDate = props.weekEnding; //toISOLocaleString(props.weekEnding); // '2019-09-24'
  const [sunday, setSunday] = useState(calculateDateFromSaturday2(0));
  const [monday, setMonday] = useState(calculateDateFromSaturday2(1));
  const [tuesday, setTuesday] = useState(calculateDateFromSaturday2(2));
  const [wednesday, setWednesday] = useState(calculateDateFromSaturday2(3));
  const [thursday, setThursday] = useState(calculateDateFromSaturday2(4));
  const [friday, setFriday] = useState(calculateDateFromSaturday2(5));
  const [saturday, setSaturday] = useState(calculateDateFromSaturday2(6));
  const [addSunday, setAddSunday] = useState(false);
  const [isHoliday, setIsHoliday] = useState([false, false, false, false, false, false, false]);
  const [upsHolidays, setUpsHolidays] = useState({});
  useEffect(() => {
    fetchPrevTimes();
    fetchHolidays();
    return () => {
      db.ref(`holidays/`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shift, props.weekEnding]);
  /* TODO: isPeak() set saturday shift defaults */
  function fetchHolidays(today = new Date()) {
    // get holidays from database and filter only the ones that cover the month/months in the given week
    console.log(`fetched holidays from firebase`);
    db.ref(`holidays/${today.getFullYear()}`)
      .once("value")
      .then(function (snapshot) {
        const res = (snapshot.val() && snapshot.val()) || {};
        const myHolidays = [];
        const holidayList = [...isHoliday];
        Object.entries(res).forEach(([month, value]) => {
          Object.entries(value).forEach(([day, description]) => {
            const months = [calculateDateFromSaturday2(0).getMonth() + 1, calculateDateFromSaturday2(6).getMonth() + 1];
            if (months.includes(parseInt(month))) {
              for (let index = 0; index < 7; index++) {
                const day1 = calculateDateFromSaturday2(index);
                if (day1.getDate() === parseInt(day)) {
                  if (day1.getMonth() + 1 === parseInt(month)) {
                    const i = day1.getDay();
                    myHolidays[i] = [month, day, description];
                    holidayList[index] = true;
                    setIsHoliday(holidayList);
                  }
                }
              }
            }
          });
        });
        if (myHolidays.length === 0) {
          setIsHoliday([false, false, false, false, false, false, false]);
        }
        setUpsHolidays(myHolidays);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function createWeek(e) {
    e.preventDefault();
    const currentWeek = {};
    const hash = [sunday, monday, tuesday, wednesday, thursday, friday, saturday];
    for (let index = 0; index < 7; index++) {
      currentWeek[index] = {
        date: {
          year: hash[index].getFullYear().toString(),
          month: ("0" + (hash[index].getMonth() + 1)).slice(-2),
          day: ("0" + hash[index].getDate()).slice(-2),
        },
        /* if date is a holiday set as "OFF", shift on holiday will bypass by setting isHoliday=false, 
            if date is a saturday or a sunday w/o a scheduled shift set as ""
            Otherwise set time, but check am/pm and set accordingly */
        time: isHoliday[index]
          ? "OFF"
          : (index === 0 && !addSunday && props.shift !== "d") || (index === 6 && props.shift !== "p") || (props.shift === "d" && index === 5 && !addSunday)
          ? ""
          : hoursToAmPmFormat(hash[index]),
      };
    }
    // add current week to firebase
    db.ref(`weeks/${saturdayDate}/${props.shift}`).set(currentWeek, function (e) {
      console.log("added next week!!");
      Auth.activateSnackBar(`Start Times for week ending ${saturdayDate} has been set.`, "success", 2000);
      props.update(saturdayDate);
      e && console.log(e);
    });
  }
  function calculateDateFromSaturday2(index) {
    //TODO: reduce calls to this function, currently over 50!!
    const saturday = parseISO(props.weekEnding);
    const diff = -6 + index; //index=0 sunday, 6 on saturday => diff=0 on sat and -6 on sun
    const indexDate = addDays(saturday, diff);
    //set default hour and minutes per shift
    indexDate.setSeconds(0);
    console.log("too many times!!");
    if (props.shift === "d" && index === 0) {
      indexDate.setHours(15); // 3:30 PM Sunday
      indexDate.setMinutes(30);
    } else if (props.shift === "d") {
      indexDate.setHours(12); // noon
      indexDate.setMinutes(0);
    } else if (props.shift === "t" && index === 0) {
      indexDate.setHours(8); // 8:00 AM Sunday
      indexDate.setMinutes(0);
    } else if (props.shift === "t") {
      indexDate.setHours(17); //5:30 PM
      indexDate.setMinutes(0);
    } else if (props.shift === "n" && index === 0) {
      indexDate.setHours(8); // 8:00 AM Sunday
      indexDate.setMinutes(0);
    } else if (props.shift === "n") {
      indexDate.setHours(22); // 10:40 PM
      indexDate.setMinutes(40);
    } else if (props.shift === "p" && index === 1) {
      indexDate.setHours(2); // 2:30AM
      indexDate.setMinutes(30);
    } else if (props.shift === "p") {
      indexDate.setHours(4); // 4:30AM
      indexDate.setMinutes(30);
    }
    return indexDate;
  }
  function fetchPrevTimes() {
    // get last weeks start times for the given date and shift from the database, then update the state of each day with the values received
    if (props.previousWeekEnding) {
      console.log("fetching previous start times...");
      db.ref(`weeks/${props.previousWeekEnding}/${props.shift}`)
        .get()
        .then((snapshot) => {
          var prevWeek = (snapshot.val() && snapshot.val()) || {};
          if (prevWeek && prevWeek.length > 0) {
            // returned data should be in an object like the following: prevWeek.time[dayIndex]
            setSunday(updateStartTime(sunday, prevWeek.map((day) => day.time)[0]));
            setMonday(updateStartTime(monday, prevWeek.map((day) => day.time)[1]));
            setTuesday(updateStartTime(tuesday, prevWeek.map((day) => day.time)[2]));
            setWednesday(updateStartTime(wednesday, prevWeek.map((day) => day.time)[3]));
            setThursday(updateStartTime(thursday, prevWeek.map((day) => day.time)[4]));
            setFriday(updateStartTime(friday, prevWeek.map((day) => day.time)[5]));
            setSaturday(updateStartTime(saturday, prevWeek.map((day) => day.time)[6]));
          } else {
            // when shift is changed or week is changed recalculate date even if there are no start times to adjust
            setSunday(calculateDateFromSaturday2(0));
            setMonday(calculateDateFromSaturday2(1));
            setTuesday(calculateDateFromSaturday2(2));
            setWednesday(calculateDateFromSaturday2(3));
            setThursday(calculateDateFromSaturday2(4));
            setFriday(calculateDateFromSaturday2(5));
            setSaturday(calculateDateFromSaturday2(6));
          }
        });
    }
  }
  function updateStartTime(indexDate, newPrevTimes = "") {
    // take in old dateTime obj and startTime string and update dateTime obj with hours and minutes of startTime string.
    if (newPrevTimes.length > 4) {
      // only update if string is formatted correctly ie. "5:30 PM" and not "OFF" or empty ""
      indexDate.setHours(timeStringToMilitaryHours(newPrevTimes));
      indexDate.setMinutes(newPrevTimes.slice(-5, -3));
      return indexDate;
    } else {
      // function call requires a return value even if it doesn't change
      return indexDate;
    }
  }
  function isPeak() {
    // peak is after thanksgiving, earliest thanksgiving is 11/22, 1st sunday shift=11/25 we=12/1
    const today = calculateDateFromSaturday2(0);
    if ((today.getMonth() + 1 === 12 && today.getDate() < 29) || (today.getMonth() + 1 === 11 && today.getDate() > 22)) {
      return true;
    } else {
      return false;
    }
  }
  function toggleHoliday(index) {
    // work the holiday
    const temp = [...isHoliday];
    temp[index] = !isHoliday[index];
    setIsHoliday(temp);
  }
  return (
    <div className={classes.mainContainer}>
      <form
        className={isThisWeek(parseISO(props.weekEnding)) ? classes.todayForm : Auth.size.height < 700 ? classes.iphone6Form : classes.form}
        method="POST"
        onSubmit={(e) => createWeek(e)}
      >
        {props.next && (
          <div className={classes.overlay}>
            <MyButton className={classes.slim} onClick={() => props.goBack(false)}>
              <Undo style={{ marginRight: ".25rem", marginLeft: "-.25rem" }} />
              <span>go back</span>
            </MyButton>
          </div>
        )}
        <MuiPickersUtilsProvider disableStrictModeCompat utils={DateFnsUtils}>
          <>
            {props.shift !== "d" && isPeak() && !addSunday && (
              <MyButton className={classes.slim} style={{ marginBottom: ".5rem" }} type="button" onClick={() => setAddSunday(true)}>
                <span>add Sunday shift</span> <AddBox style={{ marginLeft: ".25rem" }} />
              </MyButton>
            )}
            {(addSunday || props.shift === "d") && <TimePicker inputVariant="outlined" label={`${sunday.toDateString()}`} value={sunday} minutesStep={5} onChange={setSunday} />}
            {isHoliday[1] ? (
              <div className={classes.holidayContainer}>
                <div className={classes.holidayDate}>{monday.toDateString()}</div>
                <div className={classes.gridCol}>
                  <div className={classes.gridRow}>
                    <span style={{ fontWeight: 600 }}>OFF</span>
                    <span style={{ fontSize: ".75rem" }}>{upsHolidays.length > 0 && upsHolidays?.[2]?.[2]}</span>
                  </div>
                  <MyButton className={classes.buttonSlimInline} onClick={() => toggleHoliday(2)}>
                    <AddBox /> Shift
                  </MyButton>
                </div>
              </div>
            ) : (
              <TimePicker inputVariant="outlined" label={monday.toDateString()} value={monday} minutesStep={5} onChange={setMonday} />
            )}
            {isHoliday[2] ? (
              <div className={classes.holidayContainer}>
                <div className={classes.holidayDate}>{tuesday.toDateString()}</div>
                <div className={classes.gridCol}>
                  <div className={classes.gridRow}>
                    <span style={{ fontWeight: 600 }}>OFF</span>
                    <span style={{ fontSize: ".75rem" }}>{upsHolidays.length > 0 && upsHolidays?.[2]?.[2]}</span>
                  </div>
                  <MyButton className={classes.buttonSlimInline} onClick={() => toggleHoliday(2)}>
                    <AddBox /> Shift
                  </MyButton>
                </div>
              </div>
            ) : (
              <TimePicker inputVariant="outlined" label={tuesday.toDateString()} value={tuesday} minutesStep={5} onChange={setTuesday} />
            )}
            {isHoliday[3] ? (
              <div className={classes.holidayContainer}>
                <div className={classes.holidayDate}>{wednesday.toDateString()}</div>
                <div className={classes.gridCol}>
                  <div className={classes.gridRow}>
                    <span style={{ fontWeight: 600 }}>OFF</span>
                    <span style={{ fontSize: ".75rem" }}>{upsHolidays.length > 0 && upsHolidays?.[3]?.[2]}</span>
                  </div>
                  <MyButton className={classes.buttonSlimInline} onClick={() => toggleHoliday(3)}>
                    <AddBox /> Shift
                  </MyButton>
                </div>
              </div>
            ) : (
              <TimePicker inputVariant="outlined" label={wednesday.toDateString()} value={wednesday} minutesStep={5} onChange={setWednesday} />
            )}
            {isHoliday[4] ? (
              <div className={classes.holidayContainer}>
                <div className={classes.holidayDate}>{thursday.toDateString()}</div>
                <div className={classes.gridCol}>
                  <div className={classes.gridRow}>
                    <span style={{ fontWeight: 600 }}>OFF</span>
                    <span style={{ fontSize: ".75rem" }}>{upsHolidays.length > 0 && upsHolidays?.[4]?.[2]}</span>
                  </div>
                  <MyButton className={classes.buttonSlimInline} onClick={() => toggleHoliday(4)}>
                    <AddBox /> Shift
                  </MyButton>
                </div>
              </div>
            ) : (
              <TimePicker inputVariant="outlined" label={thursday.toDateString()} value={thursday} minutesStep={5} onChange={setThursday} />
            )}
            {isHoliday[5] ? (
              <div className={classes.holidayContainer}>
                <div className={classes.holidayDate}>{friday.toDateString()}</div>
                <div className={classes.gridCol}>
                  <div className={classes.gridRow}>
                    <span style={{ fontWeight: 600 }}>OFF</span>
                    <span style={{ fontSize: ".75rem" }}>{upsHolidays.length > 0 && upsHolidays?.[5]?.[2]}</span>
                  </div>
                  <MyButton className={classes.buttonSlimInline} onClick={() => toggleHoliday(5)}>
                    <AddBox /> Shift
                  </MyButton>
                </div>
              </div>
            ) : (
              ((addSunday && props.shift === "d") || props.shift !== "d") && (
                <TimePicker inputVariant="outlined" label={friday.toDateString()} value={friday} minutesStep={5} onChange={setFriday} />
              )
            )}
            {props.shift === "p" && <TimePicker inputVariant="outlined" label={`${saturday.toDateString()}`} value={saturday} minutesStep={5} onChange={setSaturday} />}
            {props.shift === "d" && !addSunday && !isHoliday[5] && (
              <MyButton className={classes.slim} type="button" onClick={() => setAddSunday(true)}>
                <span>add Friday shift</span> <AddBox style={{ marginLeft: ".25rem" }} />
              </MyButton>
            )}
            <MyButton type="submit">
              <div className={classes.gridCol}>
                <div className={classes.gridRow}>
                  <span style={{ marginTop: "-.25rem" }}>ADD week Ending</span>
                  <span className={classes.date}>{parseISO(saturdayDate).toDateString().slice(3)}</span>
                </div>
                <AddBox className={classes.extraLarge} />
              </div>
            </MyButton>
          </>
        </MuiPickersUtilsProvider>
      </form>
    </div>
  );
};
AddWeek.propTypes = {
  shift: PropTypes.string.isRequired,
  weekEnding: PropTypes.string.isRequired,
  previousWeekEnding: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  next: PropTypes.bool.isRequired,
};
export default AddWeek;
