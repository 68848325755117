export function hoursToAmPmFormat(dateTime) {
  if (dateTime.getHours() === 12) {
    // it is noon = 12pm
    return `12:${("0" + dateTime.getMinutes()).slice(-2)} PM`;
  } else if (dateTime.getHours() === 0) {
    // its midnight = 12am
    return `12:${("0" + dateTime.getMinutes()).slice(-2)} AM`;
  } else if (dateTime.getHours() > 12) {
    return `${dateTime.getHours() - 12}:${("0" + dateTime.getMinutes()).slice(-2)} PM`;
  } else if (dateTime.getHours() < 12) {
    return `${dateTime.getHours()}:${("0" + dateTime.getMinutes()).slice(-2)} AM`;
  }
}
export function timeStringToMilitaryHours(timeString) {
  const hourStr = timeString.slice(0, 2);
  let hours = parseInt(hourStr);
  if (("0" + timeString).slice(-2) === "AM" && parseInt(hourStr) === 12) {
    // it is 12:00AM MIDNIGHT =0hrs
    hours = 0;
  } else if (("0" + timeString).slice(-2) === "PM" && parseInt(hourStr) !== 12) {
    // 1pm-11:59pm
    hours = parseInt(hourStr) + 12;
  } else {
    // 1AM-12:59PM (includes NOON 12:00PM)
    hours = parseInt(hourStr);
  }
  return hours;
}
export function toISOLocaleString(dateObj) {
  const year = dateObj.getFullYear();
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  const day = ("0" + dateObj.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}
// eslint-disable-next-line import/no-anonymous-default-export
export default { hoursToAmPmFormat, timeStringToMilitaryHours };
