import React, { useContext } from "react";
import { List, ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core";
import {
  Brightness3,
  Brightness6,
  Brightness7,
  Brightness4,
  Alarm,
  ViewComfy,
  AlarmAdd,
  CalendarToday,
  BarChart,
  Settings,
  People,
  Cake,
  ExitToApp,
  Language,
  Edit,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { endOfWeek, lightFormat } from "date-fns";
import { AuthContext } from "./App";
const MenuList = (props) => {
  const Auth = useContext(AuthContext);
  const history = useHistory();
  const today = new Date();
  const saturday = endOfWeek(today);
  const saturdayStr = lightFormat(saturday, "yyyy-MM-dd");
  const todayStr = lightFormat(today, "yyyy-MM-dd");
  function logout(event) {
    props.handleLogOut(event);
    history.push("/");
  }
  return (
    <div style={{ background: "var(--ups-brown)" }} role="presentation" onClick={props.toggleDrawer} onKeyDown={props.toggleDrawer}>
      <List style={{ paddingTop: 0 }}>
        <div style={{ height: "2.5rem", background: "var(--ups-brown)", padding: ".25rem" }}>
          <img style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "fill" }} src={Auth?.user?.photoURL ? Auth.user.photoURL : "/logo192.png"} alt="logo" />
          {Auth?.user?.displayName && (
            <div style={{ position: "absolute", top: 0, left: "3rem", width: "50%", overflow: "hidden", textOverflow: "ellipsis", color: "var(--ups-gold)" }}>
              {Auth.user.displayName}
            </div>
          )}
        </div>
        <ListItem button key={"Start Times"} onClick={() => history.push("/All")}>
          <ListItemIcon>
            <ViewComfy />
          </ListItemIcon>
          <ListItemText primary={"Start Times"} />
        </ListItem>
        <Divider />
        <ListItem className="menu-subItem" button key={"Day"} onClick={() => history.push("/Day")}>
          <ListItemIcon>
            <Brightness7 />
          </ListItemIcon>
          <ListItemText primary={"Day"} />
        </ListItem>
        <ListItem className="menu-subItem" button key={"Twilight"} onClick={() => history.push("/Twilight")}>
          <ListItemIcon>
            <Brightness4 />
          </ListItemIcon>
          <ListItemText primary={"Twilight"} />
        </ListItem>
        <ListItem className="menu-subItem" button key={"Midnight"} onClick={() => history.push("/Midnight")}>
          <ListItemIcon>
            <Brightness3 />
          </ListItemIcon>
          <ListItemText primary={"Midnight"} />
        </ListItem>
        <ListItem className="menu-subItem" button key={"Preload"} onClick={() => history.push("/Preload")}>
          <ListItemIcon>
            <Brightness6 />
          </ListItemIcon>
          <ListItemText primary={"Preload"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key={"HourTracker"} onClick={() => history.push("/hours")}>
          <ListItemIcon>
            <CalendarToday />
          </ListItemIcon>
          <ListItemText primary={"Hour Tracker"} />
        </ListItem>
        <Divider />
        <ListItem className="menu-subItem" button key={"Todays Hours"} onClick={() => history.push(`/hours/day#${todayStr}`)}>
          <ListItemIcon>
            <AlarmAdd />
          </ListItemIcon>
          <ListItemText primary={"Todays Hours"} />
        </ListItem>
        <ListItem className="menu-subItem" button key={"Week Hours"} onClick={() => history.push(`/hours/week#${saturdayStr}`)}>
          <ListItemIcon>
            <Alarm />
          </ListItemIcon>
          <ListItemText primary={"Week Hours"} />
        </ListItem>
        <ListItem className="menu-subItem" button key={"Total Hours"} onClick={() => history.push(`/hours/totals#${saturdayStr}`)}>
          <ListItemIcon>
            <BarChart />
          </ListItemIcon>
          <ListItemText primary={"Total Hours"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key={"Settings"} onClick={() => history.push(`/settings`)}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary={"Settings"} />
        </ListItem>
        <ListItem button key={"Holidays"} onClick={() => history.push(`/holidays`)}>
          <ListItemIcon>
            <Cake />
          </ListItemIcon>
          <ListItemText primary={"Holidays"} />
        </ListItem>
        <ListItem button key={"Location"} onClick={() => history.push(`/Location`)}>
          <ListItemIcon>
            <Language />
          </ListItemIcon>
          <ListItemText primary={"Location"} />
        </ListItem>
        {Auth?.user?.admin && (
          <>
            <Divider />
            <ListItem className="menu-subItem" button key={"Users"} onClick={() => history.push(`/users`)}>
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <ListItemText primary={"Users"} />
            </ListItem>
            <ListItem className="menu-subItem" button key={"Edits"} onClick={() => history.push(`/edits`)}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary={"Edits"} />
            </ListItem>
          </>
        )}
      </List>
      {Auth.user && (
        <List style={{ position: "absolute", bottom: 0, width: "100%" }}>
          <ListItem button key={"LogOut"} onClick={(e) => logout(e)}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary={"Log Out"} />
          </ListItem>
        </List>
      )}
    </div>
  );
};

export default MenuList;
