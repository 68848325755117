/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./App";
import firebase from "firebase/app";
import "firebase/database";
import { MenuItem, FormHelperText, FormControl, Select, Typography, Slider, Button, ButtonGroup, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExitToApp, ExpandMore } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    margin: ".75rem",
    maxWidth: "800px",
  },
  root: {
    background: "var(--ups-gold)",
    color: "var(--ups-dark-brown)",
  },
  margin: {
    height: theme.spacing(3),
  },
  thumb: {
    color: "var(--ups-brown)",
    marginBottom: "3rem",
  },
  thumb2: {
    color: "var(--ups-brown)",
  },
  Typography: {
    marginBottom: "1.5rem",
  },
  InputLabel: {
    paddingLeft: "1rem",
    paddingTop: ".5rem",
  },
  button: {
    marginTop: "-.25rem",
    "&:hover": {
      transform: "scale(1.25)",
    },
  },
  card: {
    background: "var(--ups-gold)",
    borderRadius: ".4rem",
    color: "var(--ups-dark-brown)",
    padding: ".25rem 0",
    fontWeight: "normal",
    fontSize: "1.75rem",
    marginTop: ".75rem",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: ".75rem",
    fontWeight: "600",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: ".75rem",
  },
  dropDownOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    paddingLeft: ".5rem",
  },
  description: {
    fontSize: ".75rem",
    color: theme.palette.text.secondary,
    paddingRight: ".5rem",
  },
}));

const marks = [
  {
    value: 15,
    label: "$15",
  },
  {
    value: 45,
    label: "$45",
  },
];
const marks2 = [
  {
    value: 0,
    label: "0¢",
  },
  {
    value: 99,
    label: "99¢",
  },
];
const Settings = (props) => {
  const Auth = useContext(AuthContext);
  const [shift, setShift] = useState("");
  const [payRateDollars, setPayRateDollars] = useState(15);
  const [payRateCents, setPayRateCents] = useState(0);
  const [isFullTime, setIsFullTime] = useState("");
  const [offset, setOffset] = useState(0);
  const [menuItemOpen, setMenuItemOpen] = useState([false, false, false, false]);
  const db = firebase.database();
  const classes = useStyles();
  const defaultDollars = Math.floor(Auth.user.appSettings.payRate) || 15;
  const defaultCents = (Auth.user?.appSettings?.payRate * 100) % 100 || 0;
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    fetchUserSettings();
    console.log("getting user settings");
    return () => {
      db.ref(`user/`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Auth.user?.appSettings]);
  function valueText(value) {
    return `$${value}`;
  }
  function fetchUserSettings() {
    if (Auth.user?.appSettings?.payRate) {
      setPayRateDollars(Math.floor(Auth.user.appSettings.payRate));
      setPayRateCents((Auth.user.appSettings.payRate * 100) % 100);
    }
    if (Auth.user?.appSettings?.shift) {
      //console.log(Auth.user.appSettings.shift);
      setShift(Auth.user.appSettings.shift);
    }
    if (Auth.user?.appSettings.hasOwnProperty("ft")) {
      setIsFullTime(Auth.user.appSettings.ft);
    }
    if (Auth.user?.appSettings.hasOwnProperty("offset")) {
      setOffset(Auth.user.appSettings.offset);
    }
  }
  function updateShift(myShift) {
    db.ref(`users/${Auth.user.uid}/appSettings/shift`).set(myShift, function (e) {
      e && console.log(e);
    });
  }
  function updateFT(ft) {
    db.ref(`users/${Auth.user.uid}/appSettings/ft`).set(ft, function (e) {
      e && console.log(e);
    });
  }
  function updatePayRate(myPayRate) {
    db.ref(`users/${Auth.user.uid}/appSettings/payRate`).set(myPayRate, function (e) {
      e && console.log(e);
    });
  }
  function handleShiftChange(event) {
    setShift(event.target.value);
    updateShift(event.target.value);
    const userCopy = { ...Auth.user };
    if (userCopy?.appSetting) {
      const { shift: shiftDestructured, payRate, ft } = userCopy.appSetting;
      userCopy.appSettings = { shift: event.target.value, PayRate: payRate, ft: ft };
    }
    userCopy.appSettings = { shift: event.target.value, PayRate: 15, ft: false };
    Auth.setUser(userCopy);
  }
  function handleFTChange(event) {
    setIsFullTime(event.target.value);
    updateFT(event.target.value);
    const userCopy = { ...Auth.user };
    if (userCopy?.appSetting) {
      const { shift: shiftDestructured, payRate, ft } = userCopy.appSetting;
      userCopy.appSettings = { shift: shiftDestructured, PayRate: payRate, ft: event.target.value };
    }
    userCopy.appSettings = { shift: "", PayRate: 15, ft: event.target.value };
    Auth.setUser(userCopy);
  }
  function handlePayRateChange(isDollars, value) {
    const userCopy = { ...Auth.user };
    if (isDollars) {
      setPayRateDollars(value);
      updatePayRate(value + payRateCents / 100);
      if (userCopy?.appSetting) {
        const { shift: shiftDestructured, payRate, ft } = userCopy.appSetting;
        userCopy.appSettings = { shift: shiftDestructured, PayRate: value + payRateCents / 100, ft: ft };
      }
      userCopy.appSettings = { shift: "", PayRate: value + payRateCents / 100, ft: false };
    } else {
      setPayRateCents(value);
      updatePayRate(payRateDollars + value / 100);
      if (userCopy?.appSetting) {
        const { shift: shiftDestructured, payRate, ft } = userCopy.appSetting;
        userCopy.appSettings = { shift: shiftDestructured, PayRate: payRateDollars + value / 100, ft: ft };
      }
      userCopy.appSettings = { shift: "", PayRate: payRateDollars + value / 100, ft: false };
    }
    Auth.setUser(userCopy);
  }
  function logout(event) {
    props.handleLogOut(event);
    history.push("/");
  }
  function updateOffset(bool) {
    let newOffset = offset;
    if (bool) {
      newOffset += 5;
    } else {
      newOffset -= 5;
    }
    setOffset(newOffset);
    db.ref(`users/${Auth.user.uid}/appSettings/offset`).set(newOffset, function (e) {
      e && console.log(e);
    });
  }
  function toggleOpenMenuItem(index) {
    const menuCopy = Array.from(menuItemOpen);
    menuCopy[index] = !menuItemOpen[index];
    setMenuItemOpen(menuCopy);
  }
  function shiftString(shiftLetter) {
    if (shiftLetter === "d") {
      return "Day";
    } else if (shiftLetter === "t") {
      return "Twilight";
    } else if (shiftLetter === "n") {
      return "Midnight";
    } else if (shiftLetter === "p") {
      return "Preload";
    }
  }
  return (
    <div className={classes.container}>
      <Accordion className={classes.root} expanded={expanded === "panel0"} onChange={handleChange("panel0")}>
        <AccordionSummary expandIcon={<ExitToApp style={{ color: "var(--ups-dark-brown)" }} />} aria-controls="panel0bh-content" id="panel0bh-header" onClick={(event) => logout(event)}>
          <Typography className={classes.heading}>LOGOUT:</Typography>
        </AccordionSummary>
      </Accordion>
      <Accordion className={classes.root} expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography className={classes.heading}>Shift:</Typography>
          <Typography className={classes.secondaryHeading}>{shiftString(shift)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl /* className={classes.root} */ variant="outlined">
            <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" value={shift} onChange={(event) => handleShiftChange(event)}>
              <MenuItem value="">
                <em></em>
              </MenuItem>
              <MenuItem value="d">Day</MenuItem>
              <MenuItem value="t">Twilight</MenuItem>
              <MenuItem value="n">Midnight</MenuItem>
              <MenuItem value="p">Preload</MenuItem>
            </Select>
            <FormHelperText>Select the shift you work.</FormHelperText>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.root} expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography className={classes.heading}>FullTime:</Typography>
          <Typography className={classes.secondaryHeading}>{isFullTime ? "Full Time" : "Part Time"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl className={classes.root} variant="outlined">
            <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" value={isFullTime} onChange={(event) => handleFTChange(event)}>
              <MenuItem value=""></MenuItem>
              <MenuItem value={false}>PartTime</MenuItem>
              <MenuItem value={true}>FullTime</MenuItem>
            </Select>
            <FormHelperText>Select if you are full time or part time.</FormHelperText>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.root} expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography className={classes.heading}>Start Time Offset:</Typography>
          <Typography className={classes.secondaryHeading}>{offset} Minutes</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.flexRow}>
          <Typography className={classes.description}>
            If you have a staggered start time, set this to that amount of time so the start times page will show the start time you specifically start at. ie. Sort start at 5:30PM but
            you're scheduled for 5:40PM, then set the offset to 10 Minutes."
          </Typography>
          {/* <span style={{ width: "6rem" }}>Start Time Offset: {offset} Min</span> */}
          <ButtonGroup orientation="vertical" aria-label="start time offset selector, add or subtract 5 minutes">
            <Button onClick={() => updateOffset(true)}>+5 Min</Button>
            <Button onClick={() => updateOffset(false)}>-5 Min</Button>
          </ButtonGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.root} expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel4bh-content" id="panel4bh-header">
          <Typography className={classes.heading}>Pay Rate:</Typography>
          <Typography className={classes.secondaryHeading}>
            ${payRateDollars}.{("0" + payRateCents).substr(-2)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.flexColumn}>
          <Slider
            className={classes.thumb}
            defaultValue={defaultDollars}
            getAriaValueText={valueText}
            aria-labelledby="discrete-slider-always"
            step={1}
            marks={marks}
            min={15}
            max={45}
            valueLabelDisplay="on"
            onChangeCommitted={(_, value) => handlePayRateChange(true, value)}
          />
          <Slider
            className={classes.thumb2}
            defaultValue={defaultCents}
            getAriaValueText={valueText}
            aria-labelledby="discrete-slider-always"
            step={1}
            marks={marks2}
            min={0}
            max={99}
            valueLabelDisplay="on"
            onChangeCommitted={(_, value) => handlePayRateChange(false, value)}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Settings;
Settings.propTypes = {
  handleLogOut: PropTypes.func.isRequired,
};
