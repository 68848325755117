import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./App";
const NotFound = (props) => {
  const history = useHistory();
  const Auth = useContext(AuthContext);
  // Only after mount, update snackbar in App, and ReRoute
  useEffect(() => {
    props?.message ? Auth.activateSnackBar(props.message, props.color || "warning") : Auth.activateSnackBar("Sorry, That is not available", "warning");
    props?.route ? history.replace(props.route) : history.replace("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default NotFound;
